import { useEffect } from 'react';

export function useEventListener(eventName, onEvent) {
  useEffect(() => {
    window.addEventListener(eventName, onEvent);

    return () => {
      window.removeEventListener(eventName, onEvent);
    };
  }, [eventName, onEvent]);
}
