import React from 'react';
import s from './projectCardsList.scss';
import { buildProjectsDict } from '../../helpers/buildProjectsDict';
import { Card } from '../project-card';

const PROJECTS = buildProjectsDict();

export function ProjectCardsList() {
  const cardsList = Object.entries(PROJECTS)
    .sort(([id, projObj1], [id2, projObj2]) => {
      // sort from most to least recent:
      // convert props to date objs before comparison
      return new Date(projObj1?.dateCreated) - new Date(projObj2?.dateCreated);
    })
    .reverse()
    .filter(([id, projObj]) => !projObj?.featured)
    .map(([projID, projObj], index) => {
      return (
        <Card
          key={`cards__${projID}`}
          projID={projID}
          projObj={projObj}
          // index + 1 accounts for the cardSpacer div
          // if even or 1, then not cardUp
          cardUp={index % 2 !== 0 && 'cardUp'}
        />
      );
    });
  return <div className={s.cardsCont}>{cardsList}</div>;
}
