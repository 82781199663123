import React from 'react';
import { scoped } from '../../helpers/utils';
import s from './experienceItem.scss';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTransition } from './hooks';

gsap.registerPlugin(ScrollTrigger);

export function ExperienceItem({ sec }) {
  const [setContentSecRef] = useTransition(sec);

  return (
    <div
      ref={setContentSecRef}
      className={scoped(s, 'contentSection', {
        flipped: sec.id % 2 === 0,
      })}
    >
      <div ref={setContentSecRef} className={s.title}>
        <h5>0{sec.id}</h5>
        <h3>{sec.company}</h3>
      </div>
      <div ref={setContentSecRef} className={s.divider}></div>
      <div ref={setContentSecRef} className={s.body}>
        <div className={s.desc}>
          <h4>{sec.position}</h4>
          <h5>{sec.duration}</h5>
          {sec.body}
        </div>
      </div>
    </div>
  );
}
