import React, { useState, useEffect } from 'react';
import { scoped, useArrayRef } from '../../helpers/utils';
import { Link } from 'react-location';
import s from './footer.scss';

export function Footer({ style }) {
  function returnCurrYear() {
    // Load copyright year for footer
    var d = new Date();
    return d.getFullYear();
  }

  return (
    <>
      <footer style={style} className={s.footer}>
        <div className={scoped(s, 'top')}></div>
        <div className={scoped(s, 'desc')}>
          <h3 className={scoped(s, 'head')}>
            project notes<span className="headAccent">_</span>
          </h3>
          <h5 className={scoped(s, 'subhead')}>
            this is a list of all the tech I learned by developing this site.
          </h5>
        </div>
        <div className={scoped(s, 'projInfo')}>
          <ul className={scoped(s, 'mainList')}>
            <ToolTip name={'React'}>
              <ul>
                <li>Creating Functional / Class components</li>
                <li>Raising / Managing State</li>
                <li>Using React Hooks (useRef, useState)</li>
                <li>Raising / Handling Events</li>
                <li>Conditional Rendering</li>
              </ul>
            </ToolTip>

            <ToolTip name={'React-Location'}>
              <ul>
                <li>Creating routes</li>
                <li>
                  Conditional rendering based on current page (useLocation hook)
                </li>
              </ul>
            </ToolTip>

            <ToolTip name={'Markdown-to-jsx'}>
              <ul>
                <li>
                  Plugin for importing markdown files; used for project
                  descriptions
                </li>
              </ul>
            </ToolTip>

            <ToolTip name={'SASS'}>
              <ul>
                <li>Nesting rules</li>
                <li>Dynamic theme swapping using maps / loops</li>
              </ul>
            </ToolTip>

            <li>CSS BEM</li>

            <ToolTip name={'GSAP'}>
              <ul>
                <li>Animated all site elements</li>
                <li>Pins, Scrubs, and Staggers</li>
                <li>Timelines</li>
                <li>ScrollTriggers</li>
                <li>Custom Effects</li>
              </ul>
            </ToolTip>

            <ToolTip name={'SVG'}>
              <ul>
                <li>Vector art drawn with Adobe Illustrator</li>
                <li>Paths animated with GSAP</li>
              </ul>
            </ToolTip>
          </ul>
          <ul className={scoped(s, 'mainList')}>
            <ToolTip name={'Canvas'}>
              <ul>
                <li>Used to animate sky on landing page</li>
              </ul>
            </ToolTip>

            <ToolTip name={'Markdown'}>
              <ul>
                <li>For writing project descriptions / READMEs</li>
              </ul>
            </ToolTip>

            <ToolTip name={'Email.js'}>
              <ul>
                <li>Controlling HTML form state with React</li>
                <li>Email forwarding</li>
                <li>Providing Success / Error messages</li>
              </ul>
            </ToolTip>

            <ToolTip name={'Webpack Require.Context()'}>
              <ul>
                <li>
                  For dynamically importing all project info (via .json, .md,
                  .svg, and .webm files)
                </li>
              </ul>
            </ToolTip>

            <li>GitHub</li>

            <ToolTip name={'GitHub Pages'}>
              <ul>
                <li>For hosting live project demos</li>
              </ul>
            </ToolTip>

            <ToolTip name={'Adobe Illustrator'}>
              <ul>
                <li>Designing page mockups</li>
                <li>Creating SVG assets</li>
              </ul>
            </ToolTip>
          </ul>
        </div>

        <div className={scoped(s, 'bottom')}>
          <div className={scoped(s, 'socials')}>
            <a
              id="linkedin"
              href="https://www.linkedin.com/in/anthony-medugno/"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                className={scoped(s, 'socHov')}
                role="img"
                viewBox="0 0 448 448"
              >
                <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
              </svg>
            </a>

            <div id="logoCont">
              <Link id="footLogo" to="/">
                <svg viewBox="0 0 71.94 40">
                  <polygon
                    id="logoAccent"
                    points="25.97 22.34 20.21 22.34 23.09 13.7 25.97 22.34"
                  />
                  <path
                    id="logoM"
                    d="M58.55,0,48.74,26,38.7,0H25.25V12L29.56,25H25.25V40H36.37V17.76L44,40h9.31l7.54-22.07V40H71.94V0Z"
                  />
                  <polygon
                    id="logoA"
                    points="20.93 12.03 16.68 24.97 20.93 24.97 20.93 33.47 13.84 33.47 11.69 40 0 40 14.58 0 20.93 0 20.93 12.03"
                  />
                </svg>
              </Link>
            </div>

            <a
              id="github"
              href="https://github.com/ampats2008"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                className={scoped(s, 'socHov')}
                role="img"
                viewBox="0 0 496 496"
              >
                <path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z" />
              </svg>
            </a>
          </div>
          <p>Copyright &copy; {returnCurrYear()} Anthony Medugno.</p>
        </div>
      </footer>
    </>
  );
}

const ToolTip = ({ name, children }) => {
  const [spanPos, setSpanPos] = useState({ x: 0, y: 0 });
  const [isActive, setIsActive] = useState(false);

  const toggleActiveState = (enterOrLeave) => {
    if (enterOrLeave === 'enter') {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  const onEnter = () => {
    toggleActiveState('enter');
  };

  const onLeave = () => {
    toggleActiveState('leave');
  };

  const handleMouseMove = (e) => {
    setSpanPos({
      x: e.clientX,
      y: e.clientY,
    });
  };

  return (
    <>
      <li id={`${name}__tooltip`} className={scoped(s, 'tooltip')}>
        <i
          className={scoped(s, 'tooltip-name')}
          onMouseEnter={() => onEnter()}
          onMouseLeave={() => onLeave()}
          onMouseMove={(e) => handleMouseMove(e)}
        >
          {name}
        </i>
        <TooltipSpan id={name} spanPos={spanPos} isActive={isActive}>
          {children}
        </TooltipSpan>
      </li>
    </>
  );
};

const TooltipSpan = ({ id, spanPos, isActive, children }) => {
  const [tooltipSpans, setTooltipSpans] = useArrayRef();

  //update span position when spanPos changes
  useEffect(() => {
    tooltipSpans.current.forEach((span) => {
      span.style.top = `${spanPos.y + 20}px`;
      span.style.left = `${spanPos.x + 20}px`;
    });
  }, [tooltipSpans, spanPos]);

  return (
    <>
      <span
        id={`${id}__tooltip-span`}
        className={scoped(s, 'tooltip-span', 'fadeIn', {
          'tooltip-span--active': isActive,
        })}
        ref={setTooltipSpans}
      >
        {children}
      </span>
    </>
  );
};
