import React from 'react';
import PageHead from '../../components/nonHomeHead';
import { Footer } from '../../components/footer';
import { usePageLoadAnimation } from './hooks';

export function NonHomeLayout({ pageName, rowNum, renderH1, renderChild }) {
  const [contentHeadRef, pageHeadRef, animationFinished] =
    usePageLoadAnimation(pageName);

  return (
    <>
      <PageHead pageHeadRef={pageHeadRef} />
      <div
        className="pageContent"
        style={{ marginTop: '0', overflow: 'hidden' }} // overflow hidden prevents bg decoration from extending past footer on projects page
      >
        <div
          className={`pageContent__row r${rowNum}`}
          style={{ marginTop: '7vh' }}
        >
          <div className="contentHead" ref={contentHeadRef}>
            <h1>
              {renderH1}
              <span className="headAccent">_</span>
            </h1>
          </div>
          {renderChild(animationFinished)}
        </div>
        <Footer />
      </div>
    </>
  );
}
