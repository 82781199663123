import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

// import static assets
import browser from '../assets/img/browser.svg';

function Carousel({ screenshots }) {
  // CAROUSEL SECTION
  // useState for Carousel
  const [currentSlide, setCurrentSlide] = useState(0);

  // only set slideLength if screenshots exist
  const slideLength =
    typeof screenshots !== 'undefined' ? screenshots.length : 0;

  let chevCounter = currentSlide + 1;

  let prevChev = chevCounter > 1 ? chevCounter - 1 : '..';
  let nextChev = chevCounter < screenshots.length ? chevCounter + 1 : '..';

  const crslImgRef = useRef();

  // exec whenever currentSlide changes:
  useEffect(() => {
    gsap.from(crslImgRef.current, {
      autoAlpha: 0,
      scale: 0.8,
      ease: 'back',
      duration: 1,
      clearProps: 'all',
    });
  }, [currentSlide]);

  // depending on value of 'screenshots[currentSlide]', decide whether to display file as video or img
  const videoFormat = (
    <video
      className="projPage__carouselCont__carousel__content__screenshot"
      style={{
        maxWidth: '886px',
        display: 'block',
        margin: '0 auto',
        borderRadius: '10px',
      }} // overwrites some screenshot styles so that the 'box-shadow' is aligned properly
      playsInline
      autoPlay
      muted
      loop
    >
      <source src={screenshots[currentSlide]} type="video/webm" />
    </video>
  );
  const picFormat = (
    <>
      <img
        key={currentSlide}
        className="projPage__carouselCont__carousel__content__browserSVG"
        src={browser}
        alt="web browser vector art"
      />

      <img
        key={screenshots[currentSlide]}
        className="projPage__carouselCont__carousel__content__screenshot"
        src={screenshots[currentSlide]}
        alt="project screenshot"
      />
    </>
  );
  const displayFile = screenshots[currentSlide].includes('.webm')
    ? videoFormat
    : picFormat;

  return (
    <>
      <div className="projPage__carouselCont__carousel">
        <div
          className="projPage__carouselCont__carousel__content"
          ref={crslImgRef}
        >
          {displayFile}
        </div>
      </div>

      <span
        className="projPage__spanIcons projPage__carouselCont__chev projPage__spanIcons projPage__carouselCont__chev1"
        onClick={() =>
          setCurrentSlide((prev) =>
            currentSlide > 0 ? prev - 1 : slideLength - 1
          )
        }
      >
        {prevChev < 10 ? '0' : ''}
        {prevChev}
      </span>
      <span
        className="projPage__spanIcons projPage__carouselCont__chev projPage__spanIcons projPage__carouselCont__chev2"
        onClick={() =>
          setCurrentSlide((prev) =>
            currentSlide + 1 < slideLength ? prev + 1 : 0
          )
        }
      >
        {nextChev < 10 ? '0' : ''}
        {nextChev}
      </span>
    </>
  );
}

export default Carousel;
