// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".projectCardsList__cardsCont___nLYFi{padding-bottom:50px;grid-area:pjs;display:grid;grid-template-columns:repeat(auto-fit, minmax(300px, 1fr));grid-column-gap:8.3333333333vw}@media only screen and (max-width: 809px){.projectCardsList__cardsCont___nLYFi{grid-row-gap:100px}}#dataServices-Card .projectCardsList__card__content___CUqyV{width:50%;height:50%}#HDGL-Card .projectCardsList__card__content___CUqyV{height:100%;max-width:300px}#snapface-Card .projectCardsList__card__img___Hh7gn{opacity:.5}#esports-news-api-Card .projectCardsList__card__content___CUqyV{height:60%}#esports-news-api-Card .projectCardsList__card__img___Hh7gn{opacity:1}", "",{"version":3,"sources":["webpack://./src/components/project-cards-list/projectCardsList.scss"],"names":[],"mappings":"AAAA,qCACE,mBAAA,CACA,aAAA,CACA,YAAA,CACA,0DAAA,CAEA,8BAAA,CAEA,0CARF,qCASI,kBAAA,CAAA,CAMF,4DAEE,SAAA,CACA,UAAA,CAKF,oDAEE,WAAA,CACA,eAAA,CAKF,oDAEE,UAAA,CAKF,gEAEE,UAAA,CAEF,4DAEE,SAAA","sourcesContent":[".cardsCont {\n  padding-bottom: 50px;\n  grid-area: pjs;\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n\n  grid-column-gap: calc(100vw / 12);\n\n  @media only screen and (max-width: 809px) {\n    grid-row-gap: 100px;\n  }\n}\n\n// target specific cards, if necessary\n:global(#dataServices-Card) {\n  .card__content {\n    // make logo smaller than others\n    width: 50%;\n    height: 50%;\n  }\n}\n\n:global(#HDGL-Card) {\n  .card__content {\n    // make logo bigger than others\n    height: 100%;\n    max-width: 300px;\n  }\n}\n\n:global(#snapface-Card) {\n  .card__img {\n    // make bg darker than others\n    opacity: 0.5;\n  }\n}\n\n:global(#esports-news-api-Card) {\n  .card__content {\n    // make logo bigger than others\n    height: 60%;\n  }\n  .card__img {\n    // make bg fully visible\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardsCont": "projectCardsList__cardsCont___nLYFi",
	"card__content": "projectCardsList__card__content___CUqyV",
	"card__img": "projectCardsList__card__img___Hh7gn"
};
export default ___CSS_LOADER_EXPORT___;
