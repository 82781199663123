import React from 'react';
import ReactDOM from 'react-dom';

import { Outlet, ReactLocation, Router } from 'react-location';

import { Home } from './pages/home';
import { NonHomeLayout } from './pages/page-boilerplate';
import { ExperienceSection } from './components/experience-section';
import { ContactSection } from './components/contact-section';
import ProjectsSection from './components/projectsSection';
import Project from './pages/project[projID]';

import './styles/main.scss';
import './styles/scroll.scss';

import { buildProjectsDict } from './helpers/buildProjectsDict';

function App() {
  // Fetch project data from filesystem
  const projects = React.useMemo(() => buildProjectsDict(), []);

  const reactLocation = new ReactLocation();

  const routes = [
    {
      path: '/',
      element: <Home />,
    },
    {
      path: 'work',
      element: (
        <NonHomeLayout
          pageName={'work'}
          renderH1={'experience'}
          rowNum={2}
          renderChild={(animationFinished) => (
            <ExperienceSection {...{ animationFinished }} />
          )}
        />
      ),
    },
    {
      path: 'projects',
      children: [
        {
          path: '/', // matches /projects exactly
          element: (
            <NonHomeLayout
              pageName={'projects'}
              renderH1={'projects'}
              rowNum={3}
              renderChild={(animationFinished) => (
                <ProjectsSection {...{ animationFinished }} />
              )}
            />
          ),
        },
        {
          path: ':projID',
          element: <Project />,
          loader: async ({ params: { projID } }) => {
            return projects[projID];
          },
        },
      ],
    },
    {
      path: 'contact',
      element: (
        <NonHomeLayout
          pageName={'contact'}
          renderH1={'contact me'}
          rowNum={4}
          renderChild={(animationFinished) => (
            <ContactSection {...{ animationFinished }} />
          )}
        />
      ),
    },
    // fallback (404):
    {
      element: <Home />,
    },
  ];

  return (
    <Router location={reactLocation} routes={routes}>
      <Outlet></Outlet>
    </Router>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
