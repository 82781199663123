import React from 'react';
import s from './projectSectionBackground.scss';
import { scoped } from '../../helpers/utils';

export const ProjectsSectionBackground = () => (
  <>
    <svg id="sh-ang-left" className={s.bgPiece} viewBox="0 0 960 412.77">
      <polygon
        className={scoped(s, 'polygon', 'polygon--dark')}
        points="960 226.48 960 412.77 0 412.77 0 0 200 0 580 220.88 960 226.48"
      />
    </svg>
    <svg id="sh-ang-right" className={s.bgPiece} viewBox="0 0 320.46 186.29">
      <polygon
        className={scoped(s, 'polygon', 'polygon--dark')}
        points="320.46 186.29 0 186.29 0 0 320.46 186.29"
      />
    </svg>
    <svg id="light-angled" className={s.bgPiece} viewBox="0 0 639.54 370.41">
      <polygon
        className={scoped(s, 'polygon', 'polygon--light')}
        points="639.54 0 639.54 370.41 0 370.41 639.54 0"
      />
    </svg>
    <div className={scoped(s, 'bgFill', 'bgFill--dark')}></div>
    <div className={scoped(s, 'bgFill', 'bgFill--light')}></div>
    <svg
      id="light-angled-bottom"
      className={s.bgPiece}
      viewBox="0 0 639.54 370.41"
    >
      <polygon
        className={scoped(s, 'polygon', 'polygon--light')}
        points="639.54 370.41 639.54 0 0 0 639.54 370.41"
      />
    </svg>
    <svg id="sh-ang-bottom" viewBox="0 0 1280.46 744.36">
      <polygon
        className={scoped(s, 'polygon', 'polygon--dark')}
        points="1280.46 0 0 0 0 744.36 1280.46 0"
      />
    </svg>
  </>
);
