import React, { useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import s from './projectCard.scss';
import { useTransition, useRedirectHandler } from './hooks';
import { scoped } from '../../helpers/utils';

gsap.registerPlugin(ScrollTrigger);

export function Card({ projID, projObj, cardUp }) {
  // Cards loading animation
  let cardRef = useRef();
  const transitionDone = useTransition(cardRef);

  const redirectToProject = useRedirectHandler();

  return (
    <>
      <div
        ref={cardRef}
        role="button"
        onClick={() => redirectToProject(projID)}
        id={projID + '-Card'}
        className={scoped(s, 'card', {
          cardUp,
          cardApplyHoverTransition: transitionDone,
        })}
        style={{ backgroundColor: projObj.overlayColor }}
      >
        <img
          className={s.img}
          src={projObj.cardImgs[0]}
          alt={projID + ' Background'}
          loading="lazy"
        />

        <div className={s.content}>
          <img
            className={s.logo}
            src={projObj.cardImgs[1]}
            alt={projID + 'Logo'}
            loading="lazy"
          />
        </div>
      </div>
    </>
  );
}
