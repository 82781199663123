import React, { useEffect } from 'react';
import { Link } from 'react-location';

function NavBar() {
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      {/* Nav should be a child of landContentContainer */}
      <div id="navRow">
        <li id="navAbout">
          <a
            href={process.env.PUBLIC_URL + 'Anthony Medugno Resume.pdf'}
            target="_blank"
            rel="noreferrer"
            className="btn-primary btn-action btn-secondary"
            download
          >
            See resume
          </a>
        </li>
        <li id="navWork">
          <Link className="navLink" to="/work">
            work
          </Link>
        </li>

        <div id="logoCont">
          <Link to="/">
            <svg viewBox="0 0 71.94 40">
              <polygon
                id="logoAccent"
                points="25.97 22.34 20.21 22.34 23.09 13.7 25.97 22.34"
              />
              <path
                id="logoM"
                d="M58.55,0,48.74,26,38.7,0H25.25V12L29.56,25H25.25V40H36.37V17.76L44,40h9.31l7.54-22.07V40H71.94V0Z"
              />
              <polygon
                id="logoA"
                points="20.93 12.03 16.68 24.97 20.93 24.97 20.93 33.47 13.84 33.47 11.69 40 0 40 14.58 0 20.93 0 20.93 12.03"
              />
            </svg>
          </Link>
        </div>

        <li id="navProjects">
          <Link className="navLink" to="/projects">
            projects
          </Link>
        </li>
        <li id="navContact">
          <Link className="navLink" to="/contact">
            contact
          </Link>
        </li>
      </div>
    </React.Fragment>
  );
}

export default NavBar;
