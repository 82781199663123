import { useEffect, useRef, useState, useCallback } from 'react';
import gsap from 'gsap/all';

const typewriterPhrases = [
  'I develop web applications. 👨‍💻',
  "I'm a problem-solver. 💡",
  "I'm a tinkerer. 🛠️",
  "I'm a gym rat. 🏋️‍♂️",
  "I'm a gamer. 🎮",
  "I'm a <del>Boston sports</del> fan. 🏆",
  "I'm a Titletown fan. 🏆🏆🏆🏆🏆🏆",
];

export function useTypewriterAnimation() {
  // Subtitle Typewriter Animation:
  const didMountRef = useRef(false); // allows me to execute a useEffect hook only after first render
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
  const typeWriterTimeline = useRef();
  const typeWriterSubTimeline = useRef();

  const buildTypeWriterAnimation = useCallback(() => {
    // Effect adapted from Jesse Hall's repo: https://github.com/codeSTACKr/gsap-typing-animation
    gsap.to('#headRow > h3 > .subtitleCursor', {
      opacity: 0,
      ease: 'power2.inOut',
      repeat: -1,
    });
    typeWriterTimeline.current = gsap.timeline({ repeat: -1 }).pause();
    typeWriterSubTimeline.current = gsap.timeline();

    typewriterPhrases.forEach((phrase) => {
      typeWriterSubTimeline.current = gsap.timeline({
        repeat: 1,
        yoyo: true,
        repeatDelay: 2,
      });
      typeWriterSubTimeline.current.to('#headRow > h3 > .subtitleText', {
        duration: 4,
        text: phrase,
      });
      typeWriterTimeline.current.add(typeWriterSubTimeline.current);
    });
  }, []);

  useEffect(() => {
    // create the typewriter timeline when page is first loaded
    buildTypeWriterAnimation();
  }, [buildTypeWriterAnimation]);

  // Replay the animation if the mobile navbar is closed
  useEffect(() => {
    if (didMountRef.current) {
      // executes every time 'mobileMenuOpened' is toggled (except the first render doesn't count)

      if (mobileMenuOpened) {
        typeWriterTimeline.current.kill();
      } else {
        // simply pausing and replaying animation is not working in this case,
        // so I've opted to kill and rebuild/replay the timeline as a workaround
        buildTypeWriterAnimation();
        typeWriterTimeline.current.play();
      }
    } else didMountRef.current = true;
  }, [buildTypeWriterAnimation, mobileMenuOpened]);

  return [mobileMenuOpened, setMobileMenuOpened, typeWriterTimeline];
}
