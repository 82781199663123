import { useNavigate } from 'react-location';

export const useRedirectHandler = () => {
  const navigate = useNavigate();
  const redirectToProject = (projID) => {
    if (projID) {
      navigate({ to: `/projects/${projID}`, exact: true });
    }
  };

  return redirectToProject;
};
