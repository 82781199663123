import React from 'react';
import gsap, { ScrollTrigger } from 'gsap/all';
import Hero from '../../components/hero';
import HomeContent from '../../components/homeContent';
import { useTypewriterAnimation, useIntroAnimation } from './hooks';

gsap.registerPlugin(ScrollTrigger);

export function Home() {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  const [mobileMenuOpened, setMobileMenuOpened, typeWriterTimeline] =
    useTypewriterAnimation();

  useIntroAnimation(typeWriterTimeline); // Home Page Loading Animation:

  return (
    <>
      <Hero {...{ mobileMenuOpened, setMobileMenuOpened }} />
      <HomeContent />
    </>
  );
}
