import React, { useRef } from 'react';
import { Link } from 'react-location';
import gsap from 'gsap';
import { useEventListener } from '../hooks';

function MobileNavBar({
  mobileMenuOpened,
  setMobileMenuOpened,
  onOpen,
  onClose,
}) {
  const menuBtnRef = useRef();
  const mobileNavLinksRef = useRef();

  const handleMenuToggle = () => {
    // toggle menu button style
    let svg = menuBtnRef.current.children[0];
    svg.classList.toggle('opened');
    svg.setAttribute('aria-expanded', svg.classList.contains('opened'));

    // trigger event handler from nonHomeHead.jsx
    if (svg.classList.contains('opened')) {
      // change state before fade in animation
      setMobileMenuOpened(true);
      onOpen?.();
      // reveal links
      gsap.to(mobileNavLinksRef.current.children, {
        autoAlpha: 1,
        duration: 0.5,
        ease: 'power2.inOut',
        stagger: 0.1,
        delay: 0.5,
      });
    } else {
      // hide links
      gsap.to(mobileNavLinksRef.current.children, {
        autoAlpha: 0,
        duration: 0.2,
        ease: 'power2.inOut',

        // change state when animation is finished
        onComplete: onClose,
      });
    }
  };

  const onResize = (e) => {
    // if window is resized from mobile size to
    // desktop size while the menu is opened, then change the state to closed.
    if (e.target.innerWidth >= 630 && mobileMenuOpened) {
      setMobileMenuOpened(false);
    }
  };

  useEventListener('resize', onResize);

  return (
    <>
      <div id="mobileNavRow">
        <div id="logoCont">
          <Link to="/">
            <svg viewBox="0 0 71.94 40">
              <polygon
                id="logoAccent"
                points="25.97 22.34 20.21 22.34 23.09 13.7 25.97 22.34"
              />
              <path
                id="logoM"
                d="M58.55,0,48.74,26,38.7,0H25.25V12L29.56,25H25.25V40H36.37V17.76L44,40h9.31l7.54-22.07V40H71.94V0Z"
              />
              <polygon
                id="logoA"
                points="20.93 12.03 16.68 24.97 20.93 24.97 20.93 33.47 13.84 33.47 11.69 40 0 40 14.58 0 20.93 0 20.93 12.03"
              />
            </svg>
          </Link>
        </div>

        <div id="mobileNavRow__menuBtn">
          <button
            onClick={handleMenuToggle}
            ref={menuBtnRef}
            className="menu"
            aria-label="Main Menu"
          >
            <svg viewBox="0 0 100 100">
              <path
                className="line line1"
                d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
              />
              <path className="line line2" d="M 20,50 H 80" />
              <path
                className="line line3"
                d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Expanded nav menu Links */}
      {
        <ul
          className="mobileNavLinks"
          style={mobileMenuOpened ? { display: 'flex' } : { display: 'none' }}
          ref={mobileNavLinksRef}
        >
          <li id="navWork">
            <Link className="navLink" to="/work">
              work
            </Link>
          </li>
          <li id="navProjects">
            <Link className="navLink" to="/projects">
              projects
            </Link>
          </li>
          <li id="navContact">
            <Link className="navLink" to="/contact">
              contact
            </Link>
          </li>
          <li id="navAbout">
            <a
              href={process.env.PUBLIC_URL + 'Anthony Medugno Resume.pdf'}
              download
              className="btn-primary btn-action btn-secondary"
            >
              See resume
            </a>
          </li>
        </ul>
      }
    </>
  );
}

export default MobileNavBar;
