import React, { useLayoutEffect, useState } from 'react';
import gsap from 'gsap';
import NavBar from './navbar';
import MobileNavBar from './mobileNavbar';
import HeroCanvas from './heroCanvas';
import { useEventListener } from '../hooks';

function PageHead({ pageHeadRef }) {
  const [showFullNav, setShowFullNav] = useState(true);
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

  const chooseFullOrMobileNav = (width) => {
    if (width <= 630) {
      setShowFullNav(false);
    } else {
      setShowFullNav(true);
    }
  };

  useLayoutEffect(() => {
    chooseFullOrMobileNav(window.innerWidth);
  }, []);

  const onResize = (e) => {
    chooseFullOrMobileNav(e.target.innerWidth);
  };
  useEventListener('resize', onResize);

  const expandHeadSection = () => {
    // expand headSection
    gsap.to(pageHeadRef.current, {
      height: '80vh',
      clipPath: 'ellipse(130% 100% at 50% 0%)',
      duration: 0.5,
      ease: 'power2.inOut',
    });
  };

  const collapseHeadSection = () => {
    // collapse headSection
    gsap.to(pageHeadRef.current, {
      height: '18vh',
      clipPath: 'ellipse(70% 100% at 50% 0%)',
      duration: 0.5,
      ease: 'power2.inOut',
    });
  };

  return (
    <>
      <div className="pageHead" ref={pageHeadRef}>
        <div className="pageHead__Canvas">
          <HeroCanvas propHeight={window.innerHeight / 5} />
        </div>
        <div className="pageHead__Nav">
          {showFullNav ? (
            <NavBar />
          ) : (
            <MobileNavBar
              mobileMenuOpened={mobileMenuOpened}
              setMobileMenuOpened={setMobileMenuOpened}
              onOpen={expandHeadSection}
              onClose={collapseHeadSection}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default PageHead;
