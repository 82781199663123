import React, { forwardRef } from 'react';

const BackgroundSkillsFC = (props, ref) => {
  return (
    <svg id="bgSkills" viewBox="0 0 1814.3 950.1" ref={ref}>
      <g id="panel-2">
        <path
          id="svg"
          d="M326.4,171c0.1,2.8-0.7,5.5-2.2,7.9c-1.6,2.3-3.8,4-6.4,5.1c-3.1,1.2-6.5,1.9-9.9,1.8c-1.8,0-3.6-0.1-5.4-0.3
              c-1.6-0.2-3.3-0.5-4.8-0.9c-1.4-0.4-2.8-0.9-4.2-1.5V175c2.3,1,4.6,1.8,7,2.5c2.6,0.7,5.2,1.1,7.9,1.1c1.8,0.1,3.7-0.2,5.4-0.9
              c1.3-0.5,2.4-1.3,3.2-2.5c0.7-1.1,1.1-2.4,1.1-3.7c0.1-1.4-0.4-2.7-1.2-3.8c-1-1.2-2.3-2.2-3.7-2.9c-2-1.1-4.1-2-6.3-2.9
              c-1.7-0.6-3.3-1.4-4.8-2.3c-1.5-0.8-2.9-1.9-4.1-3.1c-1.2-1.2-2.2-2.6-2.8-4.2c-0.7-1.8-1.1-3.7-1.1-5.7c-0.1-2.6,0.7-5.3,2.1-7.5
              c1.5-2.1,3.5-3.8,5.9-4.8c2.8-1.2,5.9-1.7,8.9-1.6c2.6,0,5.1,0.3,7.6,0.9c2.4,0.6,4.7,1.4,7,2.4l-2.7,6.8c-2-0.8-4.1-1.5-6.1-2.1
              c-2-0.5-4-0.8-6-0.8c-1.5,0-3.1,0.2-4.5,0.8c-1.1,0.5-2.1,1.3-2.8,2.3c-0.7,1-1,2.2-1,3.5c-0.1,1.4,0.3,2.7,1.1,3.8
              c0.9,1.2,2.1,2.1,3.4,2.8c1.6,0.8,3.6,1.8,6,2.8c2.5,1,4.9,2.3,7.2,3.7c1.9,1.2,3.4,2.7,4.5,4.6C325.9,166.3,326.5,168.6,326.4,171
              z M374.4,133.6l-18,51.4h-9l-17.9-51.4h8.6l10.8,32.2c0.3,0.9,0.7,2.1,1.1,3.4s0.8,2.7,1.1,4s0.6,2.6,0.8,3.6
              c0.2-1,0.5-2.3,0.8-3.6c0.3-1.4,0.7-2.7,1.1-4c0.4-1.3,0.8-2.5,1.1-3.5l10.8-32.2L374.4,133.6z M401.9,157.1h19.2v25.8
              c-5.8,2-11.9,2.9-18.1,2.8c-4.6,0.1-9.2-0.9-13.3-3.1c-3.6-2.1-6.5-5.2-8.2-9c-2-4.5-3-9.4-2.8-14.3c-0.1-4.9,1-9.7,3.1-14
              c2-3.9,5.2-7.1,9.1-9.2c4.5-2.3,9.5-3.4,14.5-3.3c5.3,0,10.5,1,15.3,3.1l-3,7c-1.9-0.8-3.8-1.5-5.8-2c-2.2-0.6-4.4-0.8-6.7-0.8
              c-3.3-0.1-6.6,0.7-9.5,2.4c-2.7,1.6-4.8,3.9-6.1,6.7c-1.5,3.2-2.2,6.7-2.1,10.2c-0.1,3.4,0.5,6.8,1.7,10.1c1,2.8,2.9,5.1,5.4,6.7
              c2.9,1.7,6.2,2.6,9.5,2.4c1.7,0,3.3-0.1,4.9-0.3c1.4-0.2,2.6-0.4,3.8-0.7v-13.3h-10.8V157.1z"
        />
        <path
          id="handlebars"
          d="M507.8,128.5h-8.4v-23.2h-23.6v23.2h-8.4V77.1h8.4v21h23.7v-21h8.4V128.5z M535.8,88.9c4.9,0,8.6,1.1,11.2,3.3
              c2.5,2.2,3.8,5.6,3.8,10.2v26.2H545l-1.6-5.5h-0.3c-1,1.3-2.1,2.5-3.4,3.5c-1.2,0.9-2.5,1.6-4,2.1c-1.8,0.5-3.7,0.7-5.6,0.7
              c-2.2,0-4.3-0.4-6.3-1.3c-1.8-0.8-3.3-2.2-4.3-3.9c-1.1-2-1.7-4.4-1.6-6.7c0-4,1.5-7,4.5-9s7.5-3.1,13.6-3.3l6.7-0.3v-2
              c0-2.7-0.6-4.6-1.9-5.7c-1.5-1.2-3.4-1.8-5.3-1.7c-1.9,0-3.8,0.3-5.7,0.9c-1.8,0.5-3.6,1.2-5.3,2l-2.7-5.8c2.1-1.1,4.3-1.9,6.5-2.5
              C530.8,89.2,533.3,88.9,535.8,88.9z M542.6,109.9l-5,0.2c-4.1,0.1-7,0.8-8.7,2.1c-1.6,1.2-2.6,3.2-2.5,5.2
              c-0.1,1.6,0.5,3.2,1.7,4.2c1.3,0.9,2.9,1.4,4.5,1.3c2.6,0.1,5.1-0.8,7.1-2.4c1.9-1.6,2.8-4,2.8-7.1V109.9z M582.6,88.9
              c4.4,0,7.8,1.1,10.3,3.4c2.4,2.3,3.7,5.9,3.7,10.9v25.4h-8.3v-23.8c0-3-0.6-5.3-1.9-6.8s-3.2-2.3-5.8-2.2c-3.8,0-6.4,1.2-7.9,3.5
              c-1.5,2.3-2.2,5.7-2.2,10.1v19.3h-8.3v-39h6.5l1.2,5.3h0.5c0.8-1.3,1.9-2.5,3.2-3.4c1.3-0.9,2.7-1.6,4.2-2
              C579.4,89.1,581,88.9,582.6,88.9z M621.1,129.2c-4.3,0.1-8.4-1.7-11.1-5.1c-2.8-3.4-4.2-8.4-4.2-15c0-6.6,1.4-11.7,4.3-15.1
              c2.8-3.4,6.9-5.3,11.3-5.1c1.7,0,3.5,0.2,5.1,0.8c1.4,0.5,2.7,1.2,3.8,2.1c1,0.9,2,1.9,2.7,3h0.4c-0.1-0.7-0.3-1.8-0.4-3.1
              c-0.1-1.3-0.2-2.5-0.2-3.8v-14h8.3v54.7h-6.5l-1.5-5.3h-0.3c-0.7,1.1-1.6,2.1-2.7,3c-1.1,0.9-2.4,1.7-3.8,2.2
              C624.7,129,622.9,129.3,621.1,129.2z M623.5,122.5c3.5,0,6-1,7.5-3.1c1.5-2,2.2-5.1,2.2-9.2v-1.1c0-4.4-0.7-7.7-2.1-10
              s-4-3.5-7.7-3.5c-2.8-0.1-5.4,1.2-6.8,3.6c-1.6,2.4-2.3,5.7-2.3,10c0,4.3,0.8,7.6,2.3,9.8C618.2,121.4,620.8,122.7,623.5,122.5z
              M661.2,128.5h-8.3V73.8h8.3V128.5z M688.5,88.9c3.1-0.1,6.2,0.6,8.9,2.1c2.5,1.4,4.5,3.5,5.7,6.1c1.4,2.9,2.1,6.2,2,9.4v4.4h-26
              c0.1,3.8,1.1,6.7,3,8.7c1.9,2,4.6,3.1,8.1,3.1c2.3,0,4.5-0.2,6.7-0.7c2.1-0.5,4.2-1.2,6.1-2.1v6.7c-1.9,0.9-3.9,1.6-6,2
              c-2.4,0.5-4.8,0.7-7.2,0.6c-3.5,0.1-7-0.7-10.1-2.2c-2.9-1.5-5.3-3.8-6.8-6.6c-1.7-3.4-2.6-7.2-2.4-11c-0.1-3.8,0.6-7.6,2.2-11.1
              c1.3-2.9,3.5-5.3,6.2-7C681.9,89.6,685.2,88.8,688.5,88.9z M688.5,95.1c-2.4-0.1-4.7,0.8-6.3,2.5c-1.6,1.7-2.6,4.2-2.8,7.4H697
              c0-1.8-0.3-3.5-1-5.1c-0.6-1.4-1.5-2.6-2.8-3.5C691.8,95.5,690.2,95,688.5,95.1z M722.7,73.8V87c0,1.5,0,3-0.1,4.5
              s-0.2,2.6-0.2,3.4h0.3c1.1-1.7,2.6-3.2,4.4-4.3c6.2-3.2,13.8-1.9,18.4,3.3c2.8,3.4,4.2,8.4,4.2,15c0.1,3.8-0.5,7.6-1.9,11.1
              c-1.1,2.8-3,5.1-5.4,6.8c-2.4,1.6-5.3,2.4-8.2,2.3c-2.5,0.1-5-0.4-7.2-1.6c-1.7-1-3.1-2.3-4.3-3.8h-0.6l-1.5,4.8h-6.2V73.8H722.7z
              M732.2,95.7c-2-0.1-3.9,0.4-5.6,1.4c-1.4,1-2.5,2.5-3,4.2c-0.7,2.3-1,4.7-1,7.1v0.7c0,4.3,0.7,7.7,2.1,10c1.4,2.3,3.9,3.4,7.5,3.4
              c2.7,0.1,5.2-1.2,6.7-3.5c1.5-2.3,2.3-5.7,2.3-10s-0.8-7.6-2.3-9.9C737.4,96.9,734.9,95.6,732.2,95.7z M774.6,88.9
              c4.9,0,8.7,1.1,11.2,3.3s3.8,5.6,3.8,10.2v26.2h-5.9L782,123h-0.3c-1,1.3-2.1,2.5-3.4,3.5c-1.2,0.9-2.5,1.6-4,2.1
              c-1.8,0.5-3.7,0.7-5.6,0.7c-2.2,0-4.3-0.4-6.3-1.3c-1.8-0.8-3.3-2.2-4.3-3.9c-1.1-2-1.7-4.4-1.6-6.7c0-4,1.5-7,4.5-9
              s7.5-3.1,13.5-3.3l6.7-0.3v-2c0-2.7-0.6-4.6-1.9-5.7c-1.5-1.2-3.4-1.8-5.3-1.7c-1.9,0-3.8,0.3-5.7,0.9c-1.8,0.5-3.6,1.2-5.3,2
              l-2.7-5.8c2.1-1.1,4.3-1.9,6.5-2.5C769.5,89.2,772,88.9,774.6,88.9z M781.3,109.9l-5,0.2c-4.1,0.1-7,0.8-8.7,2.1
              c-1.6,1.2-2.6,3.2-2.5,5.2c-0.1,1.6,0.5,3.2,1.7,4.2c1.3,0.9,2.9,1.4,4.5,1.3c2.6,0.1,5.1-0.8,7.1-2.4c1.9-1.6,2.8-4,2.8-7.1
              L781.3,109.9z M820.8,88.9c0.7,0,1.4,0,2.2,0.1c0.7,0.1,1.3,0.2,2,0.3l-0.8,7.8c-0.6-0.2-1.2-0.3-1.8-0.3c-0.6-0.1-1.3-0.1-1.9-0.1
              c-1.4,0-2.9,0.2-4.2,0.7c-1.3,0.5-2.5,1.2-3.5,2.2c-1.1,1-1.9,2.3-2.4,3.6c-0.6,1.6-0.9,3.3-0.9,5.1v20.3H801V89.6h6.5l1.1,6.9h0.4
              c0.8-1.4,1.7-2.7,2.9-3.8c1.2-1.2,2.5-2.1,4-2.8C817.5,89.2,819.1,88.9,820.8,88.9z M858.3,117.4c0.1,2.3-0.6,4.6-1.9,6.5
              c-1.4,1.9-3.3,3.3-5.4,4c-2.8,1-5.8,1.4-8.7,1.4c-2.3,0-4.7-0.2-7-0.6c-1.9-0.4-3.8-1-5.6-1.8v-7.2c2,0.9,4.1,1.7,6.3,2.3
              c2.1,0.6,4.3,1,6.6,1c2.7,0,4.7-0.4,5.8-1.3c1.2-0.8,1.8-2.1,1.8-3.4c0-0.8-0.2-1.6-0.7-2.3c-0.7-0.9-1.6-1.6-2.7-2.1
              c-1.9-1-3.8-1.8-5.8-2.6c-2.1-0.8-4.2-1.8-6.2-3c-1.5-0.9-2.9-2.1-3.8-3.6c-0.9-1.6-1.4-3.5-1.3-5.3c-0.2-3.2,1.4-6.2,4.1-7.9
              c2.7-1.8,6.4-2.7,10.9-2.7c2.3,0,4.5,0.2,6.8,0.7c2.1,0.5,4.2,1.2,6.2,2.1l-2.6,6.2c-1.7-0.7-3.5-1.4-5.3-1.9
              c-1.8-0.5-3.6-0.8-5.4-0.8c-1.7-0.1-3.4,0.3-4.9,1c-1,0.5-1.7,1.6-1.7,2.8c0,0.8,0.3,1.7,0.8,2.3c0.8,0.8,1.7,1.5,2.8,1.9
              c1.3,0.6,3.2,1.4,5.5,2.4c2.1,0.8,4.1,1.7,6,2.8c1.6,0.9,2.9,2.1,3.9,3.6C857.9,113.6,858.4,115.5,858.3,117.4z"
        />
        <path
          id="streamlit"
          d="M937.5,90.1c0.1,2.8-0.7,5.5-2.2,7.9c-1.6,2.3-3.8,4.1-6.4,5.1c-3.2,1.2-6.5,1.8-9.9,1.8c-1.8,0-3.6-0.1-5.4-0.3
  c-1.6-0.2-3.2-0.5-4.8-0.9c-1.4-0.4-2.8-0.9-4.2-1.5v-7.9c2.3,1,4.6,1.8,7,2.5c2.6,0.7,5.2,1.1,7.9,1.1c1.8,0.1,3.7-0.2,5.4-0.9
  c1.3-0.5,2.4-1.3,3.2-2.5c0.7-1.1,1.1-2.4,1.1-3.7c0-1.4-0.4-2.7-1.2-3.9c-1-1.2-2.3-2.2-3.7-2.9c-2-1.1-4.1-2-6.2-2.9
  c-1.7-0.6-3.3-1.4-4.8-2.3c-1.5-0.8-2.9-1.9-4.1-3.1c-1.2-1.2-2.2-2.6-2.8-4.2c-0.7-1.8-1.1-3.7-1.1-5.7c-0.1-2.6,0.7-5.3,2.1-7.5
  c1.5-2.1,3.5-3.8,5.9-4.8c2.8-1.2,5.9-1.7,8.9-1.7c2.6,0,5.1,0.3,7.6,0.9c2.4,0.6,4.7,1.4,7,2.4L934,62c-2-0.8-4.1-1.5-6.2-2.1
  c-2-0.5-4-0.8-6-0.8c-1.5,0-3.1,0.2-4.5,0.8c-1.1,0.5-2.1,1.3-2.8,2.3c-0.7,1-1,2.2-1,3.4c-0.1,1.4,0.3,2.7,1.1,3.8
  c0.9,1.2,2.1,2.1,3.4,2.8c1.6,0.8,3.6,1.8,6,2.8c2.5,1,4.9,2.2,7.2,3.7c1.9,1.2,3.4,2.8,4.5,4.6C937,85.4,937.6,87.7,937.5,90.1z
  M961.1,98.1c1.1,0,2.1-0.1,3.2-0.3c1-0.2,1.9-0.4,2.9-0.7v6.3c-1.1,0.5-2.3,0.8-3.6,1c-1.5,0.3-3,0.4-4.5,0.4
  c-2,0-4.1-0.3-5.9-1.1c-1.8-0.8-3.3-2.1-4.2-3.8c-1.2-2.3-1.7-4.9-1.5-7.5V71.5h-5.3v-3.7l5.7-2.9l2.7-8.3h5.2v8.6h11.1v6.3h-11.1
  v20.8c-0.1,1.6,0.4,3.2,1.5,4.4C958.2,97.6,959.6,98.2,961.1,98.1z M994.8,64.4c0.7,0,1.4,0,2.2,0.1c0.7,0.1,1.3,0.1,2,0.3
  l-0.8,7.8c-0.6-0.2-1.2-0.3-1.8-0.3c-0.6-0.1-1.3-0.1-1.9-0.1c-1.4,0-2.9,0.2-4.2,0.7c-1.3,0.5-2.5,1.2-3.6,2.2
  c-1.1,1-1.9,2.3-2.4,3.6c-0.6,1.6-0.9,3.3-0.9,5.1v20.3H975V65.1h6.5l1.1,6.9h0.4c0.8-1.4,1.7-2.7,2.9-3.8c1.2-1.2,2.5-2.1,4-2.8
  C991.4,64.7,993.1,64.4,994.8,64.4z M1021.7,64.4c3.1-0.1,6.2,0.6,8.9,2.1c2.5,1.4,4.5,3.5,5.7,6.1c1.4,2.9,2.1,6.2,2,9.4v4.4h-26
  c0.1,3.8,1.1,6.7,3,8.7s4.7,3.1,8.1,3.1c2.3,0,4.5-0.2,6.7-0.7c2.1-0.5,4.2-1.2,6.1-2.1v6.7c-1.9,0.9-3.9,1.6-6,2
  c-2.4,0.5-4.8,0.7-7.2,0.6c-3.5,0.1-6.9-0.7-10.1-2.2c-2.9-1.5-5.3-3.8-6.8-6.6c-1.7-3.4-2.6-7.2-2.4-11c-0.1-3.8,0.6-7.6,2.2-11.1
  c1.3-2.9,3.5-5.3,6.2-7C1015.1,65.1,1018.4,64.3,1021.7,64.4z M1021.7,70.6c-2.4-0.1-4.7,0.8-6.3,2.5c-1.6,1.7-2.6,4.2-2.8,7.4
  h17.7c0-1.8-0.3-3.5-1-5.1c-0.6-1.4-1.5-2.6-2.8-3.5C1025,71,1023.3,70.6,1021.7,70.6z M1062.9,64.4c4.9,0,8.6,1.1,11.2,3.3
  s3.8,5.6,3.8,10.2v26.2h-5.9l-1.6-5.5h-0.3c-1,1.3-2.1,2.5-3.4,3.5c-1.2,0.9-2.5,1.6-4,2.1c-1.8,0.5-3.7,0.7-5.6,0.7
  c-2.2,0-4.3-0.4-6.3-1.3c-1.8-0.8-3.3-2.2-4.3-3.9c-1.1-2-1.6-4.4-1.6-6.7c0-4,1.5-7,4.5-9c3-2,7.5-3.1,13.6-3.3l6.7-0.3v-2
  c0-2.7-0.6-4.6-1.9-5.7c-1.5-1.2-3.4-1.8-5.3-1.7c-1.9,0-3.8,0.3-5.7,0.8c-1.8,0.5-3.6,1.2-5.3,2l-2.7-5.8c2.1-1.1,4.3-1.9,6.5-2.5
  C1057.8,64.7,1060.3,64.4,1062.9,64.4z M1069.6,85.5l-5,0.2c-4.1,0.1-7,0.8-8.7,2.1c-1.6,1.2-2.6,3.1-2.5,5.2
  c-0.1,1.6,0.5,3.2,1.7,4.2c1.3,0.9,2.9,1.4,4.5,1.3c2.6,0.1,5.1-0.8,7.1-2.4c1.9-1.6,2.8-4,2.8-7.2L1069.6,85.5z M1133.4,64.4
  c4.4,0,7.6,1.1,9.9,3.4c2.2,2.2,3.3,5.9,3.3,10.8v25.4h-8.3V80.1c0-3-0.6-5.2-1.7-6.7c-1.3-1.5-3.2-2.4-5.2-2.2
  c-3.3,0-5.6,1-7.1,3.1s-2.2,5.1-2.2,9.1v20.6h-8.3V80.1c0.1-1.7-0.2-3.4-0.8-5c-0.4-1.2-1.2-2.3-2.3-3c-1.2-0.7-2.5-1-3.9-1
  c-1.9-0.1-3.8,0.4-5.4,1.5c-1.4,1.2-2.5,2.7-2.9,4.5c-0.6,2.4-0.9,4.9-0.9,7.4V104h-8.3V65.1h6.5l1.2,5.2h0.5
  c0.8-1.3,1.8-2.5,3-3.4c1.2-0.9,2.6-1.6,4-2c1.5-0.4,3-0.7,4.5-0.6c2.5-0.1,5.1,0.4,7.3,1.5c2,1,3.5,2.6,4.5,4.7h0.6
  c1.2-2.1,2.9-3.7,5.1-4.7C1128.7,64.9,1131,64.4,1133.4,64.4z M1166.4,104.1h-8.3V49.4h8.3V104.1z M1182.3,50.2
  c1.2,0,2.3,0.3,3.3,1c1,0.9,1.5,2.2,1.4,3.5c0.1,1.3-0.4,2.6-1.4,3.5c-2,1.4-4.6,1.4-6.6,0c-1-0.9-1.5-2.2-1.4-3.5
  c-0.1-1.3,0.4-2.6,1.4-3.5C1180,50.5,1181.1,50.2,1182.3,50.2z M1186.4,65.1v38.9h-8.3V65.1H1186.4z M1212.7,98.1
  c1.1,0,2.1-0.1,3.2-0.3c1-0.2,1.9-0.4,2.9-0.7v6.3c-1.1,0.5-2.3,0.8-3.6,1c-1.5,0.3-3,0.4-4.5,0.4c-2,0-4.1-0.3-5.9-1.1
  c-1.8-0.8-3.3-2.1-4.2-3.8c-1.2-2.3-1.7-4.9-1.6-7.5V71.5h-5.3v-3.7l5.7-2.9l2.7-8.3h5.2v8.6h11.1v6.3h-11.1v20.8
  c-0.1,1.6,0.4,3.2,1.5,4.4C1209.8,97.6,1211.2,98.2,1212.7,98.1z"
        />
        <path
          id="gsap"
          d="M1288.2,48.6h19.2v25.8c-5.8,1.9-11.9,2.9-18.1,2.8c-4.6,0.1-9.2-0.9-13.3-3.1c-3.6-2.1-6.5-5.2-8.2-9
              c-2-4.5-3-9.4-2.8-14.3c-0.1-4.9,1-9.7,3.1-14c2-3.9,5.2-7.1,9.1-9.2c4.5-2.3,9.5-3.4,14.5-3.3c2.7,0,5.4,0.3,8.1,0.8
              c2.5,0.5,4.9,1.3,7.2,2.3l-3,7c-1.9-0.8-3.8-1.5-5.8-2c-2.2-0.6-4.4-0.8-6.7-0.8c-3.3-0.1-6.6,0.7-9.5,2.4
              c-2.7,1.6-4.8,3.9-6.1,6.7c-1.5,3.2-2.2,6.7-2.2,10.2c-0.1,3.4,0.5,6.8,1.7,10.1c1,2.8,2.9,5.1,5.4,6.7c2.9,1.7,6.2,2.6,9.5,2.4
              c1.7,0,3.3-0.1,4.9-0.3c1.4-0.2,2.6-0.4,3.8-0.7V55.9h-10.9L1288.2,48.6z M1349.4,62.6c0.1,2.8-0.7,5.5-2.2,7.9
              c-1.6,2.3-3.8,4-6.4,5.1c-3.2,1.2-6.5,1.8-9.9,1.8c-1.8,0-3.6-0.1-5.4-0.3c-1.6-0.2-3.3-0.5-4.8-0.9c-1.4-0.4-2.8-0.9-4.2-1.5v-7.9
              c2.3,1,4.6,1.8,7,2.5c2.6,0.7,5.2,1.1,7.9,1.1c1.8,0.1,3.7-0.2,5.4-0.9c1.3-0.5,2.4-1.3,3.2-2.5c0.7-1.1,1.1-2.4,1.1-3.7
              c0-1.4-0.4-2.7-1.2-3.8c-1-1.2-2.3-2.2-3.7-2.9c-2-1.1-4.1-2-6.2-2.8c-1.7-0.6-3.3-1.4-4.8-2.3c-1.5-0.8-2.9-1.9-4.1-3.1
              c-1.2-1.2-2.2-2.6-2.8-4.2c-0.7-1.8-1.1-3.7-1-5.7c-0.1-2.6,0.7-5.3,2.1-7.5c1.5-2.1,3.5-3.8,5.9-4.8c2.8-1.2,5.9-1.7,8.9-1.7
              c2.6,0,5.1,0.3,7.6,0.9c2.4,0.6,4.7,1.4,7,2.4l-2.7,6.8c-2-0.8-4.1-1.5-6.2-2.1c-2-0.5-4-0.8-6.1-0.8c-1.6,0-3.1,0.2-4.5,0.8
              c-1.1,0.5-2.1,1.3-2.8,2.3c-0.7,1-1,2.2-1,3.4c0,1.4,0.3,2.7,1.1,3.8c0.9,1.2,2.1,2.1,3.4,2.8c1.6,0.8,3.6,1.8,6,2.8
              c2.5,1,4.9,2.2,7.2,3.7c1.9,1.2,3.4,2.7,4.6,4.6C1348.9,57.9,1349.5,60.2,1349.4,62.6z M1391.2,76.5l-5-14.3h-19.7l-5,14.3h-8.9
              l19.1-51.6h9.3l19.1,51.6H1391.2z M1384,55.1l-4.9-14.1c-0.2-0.6-0.5-1.5-0.8-2.6s-0.7-2.2-1-3.4s-0.7-2.2-0.9-3
              c-0.2,1-0.5,2-0.9,3.2s-0.7,2.3-1,3.3s-0.6,1.8-0.7,2.4l-4.9,14.1L1384,55.1z M1421.9,25.2c6.6,0,11.5,1.4,14.5,4.1
              c3.1,2.7,4.6,6.5,4.6,11.5c0,2.1-0.3,4.3-1,6.3c-0.7,2-1.9,3.8-3.4,5.3c-1.8,1.7-3.9,2.9-6.2,3.7c-3.1,1-6.3,1.4-9.5,1.3h-5.5v19.2
              h-8.4V25.2H1421.9z M1421.3,32.2h-5.9v18.1h4.6c2.3,0.1,4.6-0.3,6.7-0.9c1.7-0.5,3.2-1.6,4.2-3c1-1.6,1.5-3.5,1.4-5.3
              c0-3-0.9-5.2-2.7-6.6S1425.1,32.2,1421.3,32.2L1421.3,32.2z"
        />
        <path
          id="bitbucket"
          d="M1487.2,3.3h15.3c6.6,0,11.6,1,15,2.9s5.1,5.3,5.1,10c0,1.8-0.3,3.6-1,5.3c-0.7,1.5-1.7,2.9-3,3.9
              c-1.4,1.1-3.1,1.8-4.8,2.1v0.3c1.9,0.3,3.7,0.9,5.3,1.9c1.6,0.9,2.8,2.2,3.7,3.8c1,2,1.4,4.2,1.3,6.5c0.3,5.7-3.2,11-8.6,13
              c-3.1,1.2-6.4,1.8-9.8,1.7h-18.4L1487.2,3.3z M1495.6,24.5h8.1c3.8,0,6.5-0.6,7.9-1.9c1.5-1.4,2.3-3.4,2.2-5.4
              c0.2-2.1-0.8-4.1-2.6-5.3c-1.8-1.1-4.5-1.6-8.3-1.6h-7.3V24.5z M1495.6,31.4v16.3h8.9c3.9,0,6.7-0.8,8.3-2.3
              c1.6-1.6,2.5-3.9,2.4-6.2c0-1.4-0.3-2.9-1-4.2c-0.8-1.3-2.1-2.2-3.5-2.7c-2.1-0.7-4.3-1-6.5-0.9L1495.6,31.4z M1538,0.9
              c1.2,0,2.3,0.3,3.3,1c1,0.9,1.5,2.2,1.4,3.5c0.1,1.3-0.4,2.6-1.4,3.5c-2,1.4-4.6,1.4-6.6,0c-1-0.9-1.5-2.2-1.4-3.5
              c-0.1-1.3,0.4-2.6,1.4-3.5C1535.6,1.2,1536.8,0.8,1538,0.9z M1542.1,15.8v38.9h-8.3V15.8H1542.1z M1568.4,48.7
              c1.1,0,2.1-0.1,3.2-0.3c1-0.2,1.9-0.4,2.9-0.7v6.3c-1.1,0.5-2.3,0.8-3.6,1c-1.5,0.3-3,0.4-4.5,0.4c-2,0-4.1-0.3-5.9-1.1
              c-1.8-0.8-3.3-2.1-4.2-3.8c-1.1-2.3-1.7-4.9-1.5-7.5V22.1h-5.3v-3.7l5.7-2.9l2.7-8.3h5.2v8.7h11.2v6.3H1563v20.8
              c-0.1,1.6,0.4,3.2,1.5,4.4C1565.5,48.3,1566.9,48.8,1568.4,48.7z M1590.6,0v13.2c0,1.5,0,3-0.1,4.5s-0.2,2.6-0.2,3.4h0.4
              c1.1-1.8,2.6-3.2,4.4-4.3c6.2-3.2,13.8-1.9,18.4,3.3c2.8,3.4,4.2,8.4,4.2,15c0.1,3.8-0.5,7.6-1.9,11.1c-1.1,2.8-3,5.1-5.4,6.8
              c-2.4,1.6-5.3,2.4-8.2,2.3c-2.5,0.1-5-0.4-7.2-1.6c-1.7-1-3.1-2.3-4.3-3.8h-0.6l-1.5,4.7h-6.2V0H1590.6z M1600.1,21.8
              c-2-0.1-3.9,0.4-5.6,1.4c-1.4,1-2.5,2.5-3,4.2c-0.7,2.3-1,4.7-1,7.1v0.7c0,4.3,0.7,7.7,2,9.9c1.4,2.3,3.9,3.4,7.5,3.4
              c2.7,0.1,5.2-1.2,6.6-3.5c1.5-2.3,2.3-5.7,2.3-10c0-4.3-0.8-7.6-2.3-9.9C1605.2,23,1602.7,21.8,1600.1,21.8z M1661.1,15.8v38.9
              h-6.5l-1.2-5.2h-0.4c-0.8,1.3-1.9,2.4-3.1,3.3c-1.3,0.9-2.7,1.6-4.2,2c-1.6,0.4-3.2,0.6-4.9,0.6c-2.6,0.1-5.1-0.4-7.5-1.5
              c-2.1-1-3.7-2.6-4.8-4.6c-1.2-2.5-1.8-5.3-1.7-8.1V15.8h8.3v23.9c0,3,0.6,5.3,1.8,6.8s3.1,2.3,5.7,2.3c2.1,0.1,4.2-0.4,6-1.6
              c1.5-1.1,2.6-2.7,3.2-4.6c0.7-2.4,1-4.9,1-7.4V15.8H1661.1z M1688.6,55.4c-3.3,0.1-6.6-0.6-9.6-2.1c-2.7-1.5-4.9-3.8-6.2-6.6
              c-1.6-3.5-2.3-7.4-2.2-11.3c0-4.7,0.8-8.6,2.4-11.6c1.5-2.9,3.8-5.2,6.6-6.6c3-1.5,6.4-2.2,9.7-2.2c2.1,0,4.2,0.2,6.3,0.7
              c1.7,0.4,3.3,0.9,4.8,1.7L1698,24c-1.4-0.6-2.9-1.1-4.4-1.5c-1.4-0.4-2.9-0.6-4.3-0.6c-2-0.1-4,0.5-5.7,1.5
              c-1.6,1.1-2.8,2.7-3.4,4.5c-0.8,2.4-1.2,4.9-1.1,7.4c-0.1,2.5,0.3,4.9,1.1,7.2c0.6,1.8,1.8,3.3,3.3,4.4c1.6,1,3.5,1.6,5.4,1.5
              c1.9,0,3.9-0.2,5.7-0.8c1.7-0.5,3.3-1.2,4.8-2v7.2c-1.5,0.8-3.1,1.5-4.8,1.9C1692.8,55.2,1690.7,55.4,1688.6,55.4z M1716.9,0v26.4
              c0,1.2,0,2.5-0.1,3.9s-0.2,2.7-0.3,4h0.2c0.6-0.8,1.3-1.8,2.2-2.9s1.7-2.1,2.4-2.9l11.9-12.8h9.5l-15.6,16.7l16.6,22.2h-9.7
              l-12.4-17.1l-4.6,4v13.1h-8.3V0H1716.9z M1765.4,15c3.1-0.1,6.2,0.7,8.9,2.2c2.5,1.4,4.5,3.5,5.7,6.1c1.4,2.9,2.1,6.2,2,9.4v4.4
              h-26c0.1,3.8,1.1,6.7,3,8.7s4.6,3.1,8.1,3.1c2.3,0,4.5-0.2,6.7-0.7c2.1-0.5,4.2-1.2,6.1-2.1v6.7c-1.9,0.9-3.9,1.6-5.9,2
              c-2.4,0.5-4.8,0.7-7.3,0.6c-3.5,0.1-6.9-0.7-10.1-2.2c-2.9-1.5-5.3-3.8-6.8-6.7c-1.7-3.4-2.6-7.2-2.4-11c-0.1-3.8,0.6-7.6,2.2-11.1
              c1.3-2.9,3.5-5.3,6.2-7C1758.8,15.8,1762.1,15,1765.4,15z M1765.4,21.3c-2.4-0.1-4.7,0.8-6.3,2.5c-1.6,1.7-2.6,4.2-2.8,7.4h17.7
              c0-1.8-0.3-3.5-1-5.2c-0.6-1.4-1.5-2.6-2.8-3.5C1768.7,21.7,1767.1,21.2,1765.4,21.3z M1805.9,48.7c1.1,0,2.1-0.1,3.2-0.3
              c1-0.2,1.9-0.4,2.9-0.7v6.3c-1.1,0.5-2.3,0.8-3.6,1c-1.5,0.3-3,0.4-4.5,0.4c-2,0-4.1-0.3-5.9-1.1c-1.8-0.8-3.3-2.1-4.2-3.8
              c-1.1-2.3-1.7-4.9-1.6-7.5V22.1h-5.3v-3.7l5.7-2.9l2.7-8.3h5.2v8.7h11.1v6.3h-11.1v20.8c-0.1,1.6,0.4,3.2,1.5,4.4
              C1803,48.3,1804.4,48.8,1805.9,48.7z"
        />
        <path
          id="web_dev"
          d="M622.2,161.6l-28,107.1H575l-17.9-64.2c-0.4-1.6-0.9-3.4-1.5-5.5c-0.5-2.1-1.1-4.3-1.6-6.5s-1-4.3-1.3-6.2
              s-0.6-3.4-0.8-4.4c-0.1,1.1-0.3,2.5-0.7,4.4s-0.7,3.9-1.2,6.1s-1,4.4-1.5,6.6s-1,4.1-1.5,5.8l-17.6,64h-19.2l-27.8-107.1h17.7
              l15.5,62.8c0.5,2,1,4.2,1.5,6.5s1,4.7,1.5,7c0.5,2.3,0.9,4.6,1.3,6.9c0.4,2.2,0.7,4.2,1,6c0.3-1.9,0.6-3.9,1-6.2
              c0.4-2.3,0.8-4.6,1.3-7s1-4.6,1.5-6.9s1-4.2,1.6-5.9l17.4-63.2h17.2l17.8,63.4c0.5,1.8,1.1,3.8,1.6,6.1s1,4.5,1.5,6.9
              s0.9,4.6,1.3,6.8s0.7,4.2,1,5.9c0.3-2.4,0.8-5.1,1.3-8.2c0.5-3.1,1.2-6.2,1.8-9.4s1.4-6.1,2-8.8l15.4-62.8L622.2,161.6z M667.9,186
              c7.2,0,13.4,1.5,18.6,4.5c5.1,3,9.3,7.4,11.9,12.7c2.8,5.5,4.2,12,4.2,19.6v9.2h-54.1c0.2,7.9,2.3,13.9,6.3,18.2
              c4,4.3,9.7,6.4,17,6.4c4.7,0.1,9.4-0.4,14-1.5c4.4-1.1,8.6-2.6,12.7-4.4v14c-3.9,1.9-8.1,3.3-12.4,4.2c-5,1-10,1.4-15.1,1.3
              c-7.3,0.2-14.5-1.4-21-4.6c-6-3.1-11-7.9-14.1-13.9c-3.4-6.2-5.1-13.8-5.1-23c0-9.1,1.5-16.8,4.6-23.1c2.8-6,7.3-11.1,13-14.5
              C654.3,187.6,661.1,185.9,667.9,186z M667.9,199c-5.4,0-9.8,1.8-13.2,5.3c-3.3,3.5-5.3,8.7-5.9,15.5h36.8c0-3.7-0.7-7.3-2-10.7
              c-1.2-3-3.2-5.5-5.8-7.3C674.9,199.8,671.4,198.9,667.9,199L667.9,199z M739.2,154.7v27.5c0,3.2-0.1,6.3-0.3,9.3s-0.3,5.4-0.5,7.1
              h0.7c2.3-3.6,5.5-6.7,9.2-8.9c3.9-2.4,9-3.6,15.1-3.6c9.6,0,17.3,3.5,23.2,10.6c5.9,7.1,8.8,17.5,8.8,31.3c0,9.2-1.3,16.9-4,23.1
              c-2.7,6.3-6.4,11-11.3,14.2c-5.1,3.3-11,4.9-17.1,4.8c-6.2,0-11.1-1.1-14.9-3.4c-3.5-2-6.5-4.7-8.9-8h-1.2l-3.2,9.9h-13v-114H739.2
              z M759,200.2c-4.9,0-8.8,1-11.6,2.9c-3,2.1-5.2,5.2-6.2,8.7c-1.4,4.8-2.1,9.8-2,14.8v1.5c0,9,1.4,15.9,4.3,20.7s8.1,7.2,15.6,7.2
              c5.6,0.2,10.9-2.6,13.9-7.3c3.2-4.8,4.8-11.8,4.8-20.9c0-9.1-1.6-16-4.8-20.6C769.8,202.5,765.1,200.2,759,200.2L759,200.2z
              M943,214.1c0,12.1-2.2,22.1-6.7,30.2c-4.4,8-11.3,14.4-19.5,18.2c-8.5,4.1-18.9,6.1-30.9,6.1h-29.7V161.6h33c11.1,0,20.7,2,28.7,6
              c7.9,3.8,14.4,10,18.7,17.6C940.9,193,943.1,202.6,943,214.1z M924.7,214.6c0-8.7-1.4-16-4.1-21.6c-2.5-5.5-6.8-9.9-12.1-12.6
              c-5.3-2.7-11.9-4.1-19.7-4.1h-15v77.7h12.5c12.9,0,22.5-3.3,28.9-9.9C921.5,237.5,924.7,227.6,924.7,214.6L924.7,214.6z M996.6,186
              c7.2,0,13.4,1.5,18.6,4.5c5.1,3,9.3,7.4,11.9,12.7c2.8,5.5,4.2,12,4.2,19.6v9.2h-54.1c0.2,7.9,2.3,13.9,6.3,18.2s9.7,6.4,16.9,6.4
              c4.7,0.1,9.4-0.4,14-1.5c4.4-1.1,8.7-2.6,12.8-4.4v14c-3.9,1.9-8.1,3.3-12.4,4.2c-5,1-10,1.4-15.1,1.3c-7.3,0.1-14.5-1.4-21-4.6
              c-6-3-11-7.9-14.2-13.8c-3.4-6.2-5.1-13.8-5.1-23c0-9.1,1.5-16.8,4.6-23.1c2.8-6,7.3-11.1,13-14.5
              C983.1,187.6,989.8,185.9,996.6,186z M996.6,199c-5.4,0-9.8,1.8-13.1,5.3c-3.3,3.5-5.3,8.7-5.9,15.5h36.8c0-3.7-0.7-7.3-2-10.7
              c-1.2-3-3.2-5.5-5.8-7.3C1003.6,199.8,1000.1,198.9,996.6,199L996.6,199z M1069.3,268.7l-30.9-81.1h18.2l16.7,47.6
              c1.1,3,2,6.3,2.9,9.8c0.8,2.9,1.4,5.9,1.8,8.9h0.6c0.5-3,1.1-6,2-9c1-3.5,2-6.7,3-9.7l16.7-47.6h18.2l-30.8,81.1H1069.3z
              M1163.2,186c7.2,0,13.4,1.5,18.6,4.5c5.1,3,9.3,7.4,11.9,12.7c2.8,5.5,4.2,12,4.2,19.6v9.2h-54.1c0.2,7.9,2.3,13.9,6.3,18.2
              s9.7,6.4,17,6.4c4.7,0.1,9.4-0.5,13.9-1.5c4.4-1.1,8.7-2.6,12.8-4.4v14c-3.9,1.9-8.1,3.3-12.4,4.2c-5,1-10,1.4-15.1,1.3
              c-7.3,0.1-14.5-1.4-21-4.6c-6-3-11-7.9-14.2-13.8c-3.4-6.2-5-13.8-5-23c0-9.1,1.5-16.8,4.6-23.1c2.8-6,7.3-11.1,13-14.5
              C1149.6,187.6,1156.3,185.9,1163.2,186z M1163.2,199c-5.4,0-9.8,1.8-13.2,5.3c-3.3,3.5-5.3,8.7-5.9,15.5h36.8c0-3.7-0.7-7.3-2-10.7
              c-1.2-3-3.2-5.5-5.8-7.3C1170.2,199.8,1166.7,198.9,1163.2,199L1163.2,199z M1234.6,268.7h-17.4v-114h17.4V268.7z M1330.9,227.9
              c0.1,6.1-0.8,12.1-2.6,17.9c-1.6,4.9-4.2,9.4-7.7,13.3c-3.4,3.6-7.5,6.4-12.2,8.2c-5.1,1.9-10.5,2.9-16,2.8
              c-5.2,0.1-10.4-0.9-15.3-2.8c-4.6-1.8-8.7-4.6-12.1-8.2c-3.5-3.8-6.2-8.3-7.9-13.3c-1.9-5.8-2.9-11.8-2.8-17.9
              c0-8.9,1.6-16.5,4.7-22.7c2.9-6,7.5-11,13.3-14.3c5.8-3.3,12.6-4.9,20.6-4.9c6.9-0.1,13.7,1.5,19.8,4.9c5.8,3.3,10.4,8.3,13.4,14.3
              C1329.3,211.5,1330.9,219.1,1330.9,227.9z M1272.1,227.9c-0.1,5.1,0.6,10.3,2.2,15.2c1.2,3.8,3.5,7.2,6.7,9.6
              c3,2.2,6.9,3.3,11.7,3.3s8.6-1.1,11.6-3.3c3.2-2.4,5.5-5.8,6.7-9.6c1.5-4.9,2.3-10,2.2-15.2c0.1-5.1-0.6-10.2-2.2-15
              c-1.2-3.8-3.5-7.1-6.7-9.5c-3-2.2-6.9-3.3-11.7-3.3c-7.1,0-12.3,2.4-15.6,7.2C1273.7,212.2,1272.1,219,1272.1,227.9L1272.1,227.9z
              M1392.3,186c9.6,0,17.3,3.5,23.2,10.5c5.8,7,8.8,17.5,8.8,31.4c0,9.2-1.4,16.9-4.1,23.2c-2.7,6.3-6.5,11-11.4,14.2
              c-5.1,3.3-11,4.9-17,4.8c-3.6,0.1-7.2-0.5-10.7-1.6c-2.8-0.9-5.4-2.4-7.8-4.2c-2-1.6-3.9-3.5-5.4-5.6h-1c0.2,1.9,0.5,4,0.7,6.4
              s0.3,4.5,0.3,6.6v33h-17.4v-117h14.1l2.4,11.2h0.8c1.6-2.4,3.4-4.5,5.5-6.4c2.3-2,5-3.6,7.9-4.6
              C1384.8,186.6,1388.5,186,1392.3,186z M1387.6,200.2c-4.7,0-8.5,0.9-11.4,2.8c-3,2-5.2,5-6.2,8.4c-1.4,4.6-2.1,9.3-2.1,14.1v2.4
              c-0.1,5.2,0.5,10.3,1.9,15.3c1,3.8,3.2,7.1,6.2,9.5c2.9,2.2,6.8,3.3,11.8,3.3c3.8,0.1,7.4-1.1,10.4-3.4c3-2.6,5.1-6,6.2-9.8
              c1.4-4.9,2.1-9.9,2-15c0-8.7-1.5-15.5-4.6-20.3C1398.7,202.6,1394,200.2,1387.6,200.2z M1535.7,186c9.1,0,15.9,2.3,20.5,7
              s6.9,12.2,6.9,22.6v53h-17.2v-49.8c0-6.2-1.2-10.9-3.6-14c-2.4-3.1-6-4.7-10.9-4.6c-6.8,0-11.8,2.2-14.8,6.5
              c-3,4.3-4.5,10.7-4.5,19v43h-17.2v-49.9c0.1-3.5-0.4-7-1.6-10.4c-0.9-2.5-2.6-4.7-4.8-6.2c-2.5-1.4-5.3-2.2-8.1-2
              c-4.7,0-8.5,1.1-11.3,3.2c-3,2.4-5.1,5.7-6,9.5c-1.3,5-1.9,10.2-1.8,15.3v40.5H1444v-81.1h13.5l2.4,10.9h1c1.6-2.7,3.7-5.1,6.2-7
              c2.5-1.8,5.3-3.2,8.2-4.1c3.1-0.9,6.3-1.4,9.4-1.4c6.1,0,11.2,1.1,15.3,3.2c4.1,2.1,7.4,5.5,9.3,9.7h1.3c2.4-4.3,6.1-7.7,10.6-9.8
              C1525.8,187.1,1530.7,186,1535.7,186z M1619.4,186c7.2,0,13.4,1.5,18.6,4.5c5.1,3,9.3,7.4,11.9,12.7c2.8,5.5,4.2,12,4.2,19.6v9.2
              H1600c0.2,7.9,2.3,13.9,6.3,18.2s9.7,6.4,17,6.4c4.7,0.1,9.4-0.4,14-1.5c4.4-1.1,8.7-2.6,12.8-4.4v14c-3.9,1.9-8.1,3.3-12.4,4.2
              c-5,1-10,1.4-15.1,1.3c-7.3,0.1-14.5-1.4-21-4.6c-6.1-3-11-7.9-14.2-13.8c-3.4-6.2-5.1-13.8-5.1-23c0-9.1,1.5-16.8,4.6-23.1
              c2.8-6,7.3-11.1,13-14.5C1605.9,187.6,1612.6,185.9,1619.4,186z M1619.4,199c-5.4,0-9.8,1.8-13.1,5.3c-3.3,3.5-5.3,8.7-5.9,15.5
              h36.8c0-3.7-0.7-7.3-2-10.7c-1.2-3-3.2-5.5-5.9-7.4C1626.4,199.8,1622.9,198.9,1619.4,199L1619.4,199z M1715.8,186
              c9.1,0,16.2,2.4,21.3,7.1s7.7,12.3,7.7,22.7v52.9h-17.2V219c0-6.2-1.3-10.9-3.9-14.1c-2.6-3.2-6.6-4.7-12-4.7
              c-7.9,0-13.3,2.4-16.4,7.3s-4.6,11.8-4.6,21v40.3h-17.3v-81.1h13.5l2.4,11h1c1.7-2.8,3.9-5.1,6.6-7c2.7-1.9,5.6-3.3,8.8-4.1
              C1709,186.5,1712.4,186,1715.8,186z M1799,256.2c2.2,0,4.5-0.2,6.7-0.6c2-0.4,4-0.9,6-1.5v13c-2.4,1-4.9,1.7-7.4,2.1
              c-3.1,0.6-6.3,0.9-9.4,0.9c-4.2,0.1-8.4-0.7-12.4-2.3c-3.7-1.6-6.8-4.4-8.7-8c-2.2-3.8-3.2-9-3.2-15.6v-43.5h-11.1v-7.7l11.9-6.2
              l5.6-17.4h10.8v18h23.2v13.2h-23.2V244c0,4.1,1,7.2,3.1,9.2C1793.1,255.3,1796,256.3,1799,256.2z"
        />
        <path
          id="sql"
          d="M971.8,374.5c0.1,2.8-0.7,5.5-2.2,7.9c-1.6,2.3-3.8,4-6.4,5.1c-3.2,1.2-6.5,1.8-9.9,1.8c-1.8,0-3.6-0.1-5.4-0.3
              c-1.6-0.2-3.2-0.5-4.8-0.9c-1.4-0.4-2.8-0.9-4.2-1.5v-7.9c2.3,1,4.6,1.8,7,2.5c2.6,0.7,5.2,1.1,7.9,1.1c1.8,0.1,3.7-0.2,5.4-0.9
              c1.3-0.5,2.4-1.3,3.2-2.5c0.7-1.1,1.1-2.4,1.1-3.7c0-1.4-0.4-2.7-1.2-3.8c-1-1.2-2.3-2.2-3.7-2.9c-2-1.1-4.1-2-6.2-2.8
              c-1.7-0.6-3.3-1.4-4.8-2.3c-1.5-0.8-2.9-1.9-4.1-3.1c-1.2-1.2-2.2-2.6-2.8-4.2c-0.7-1.8-1.1-3.7-1.1-5.7c-0.1-2.6,0.7-5.3,2.1-7.5
              c1.5-2.1,3.5-3.8,5.9-4.8c2.8-1.2,5.9-1.7,8.9-1.7c2.6,0,5.1,0.3,7.6,0.9c2.4,0.6,4.7,1.4,7,2.4l-2.7,6.8c-2-0.8-4.1-1.5-6.2-2.1
              c-2-0.5-4-0.8-6-0.8c-1.6,0-3.1,0.2-4.5,0.8c-1.1,0.5-2.1,1.3-2.8,2.3c-1.3,2.2-1.3,5.1,0.1,7.3c0.9,1.2,2.1,2.1,3.4,2.8
              c1.6,0.8,3.6,1.8,6,2.8c2.5,1,5,2.3,7.3,3.8c1.9,1.2,3.4,2.7,4.6,4.6C971.3,369.8,971.9,372.2,971.8,374.5z M1027.4,362.7
              c0,3.7-0.5,7.4-1.5,11c-0.9,3.1-2.5,6-4.6,8.5c-2.1,2.4-4.8,4.2-7.8,5.3l12.3,13.2h-11.3l-9.7-11.5h-0.7h-0.6
              c-3.7,0.1-7.3-0.6-10.7-1.9c-2.9-1.2-5.5-3-7.5-5.4c-2-2.5-3.6-5.3-4.4-8.4c-1-3.5-1.5-7.2-1.5-10.9c-0.1-4.8,0.8-9.5,2.6-13.9
              c1.6-3.8,4.4-7,8-9.2c3.6-2.2,8.1-3.3,13.6-3.3c5.3,0,9.8,1.1,13.3,3.3c3.5,2.2,6.3,5.4,8,9.2
              C1026.6,353.2,1027.5,357.9,1027.4,362.7z M988.1,362.7c-0.1,3.5,0.5,7,1.6,10.3c0.9,2.7,2.7,5,5,6.7c5.3,3.1,11.9,3.1,17.3,0
              c2.3-1.6,4-4,4.9-6.7c1.1-3.3,1.6-6.8,1.6-10.3c0-6-1.2-10.7-3.6-14.1c-2.4-3.4-6.2-5.1-11.5-5.1c-3.1-0.1-6.1,0.7-8.7,2.3
              c-2.3,1.6-4.1,4-5,6.6C988.6,355.7,988.1,359.2,988.1,362.7z M1038.4,388.5v-51.4h8.4v44.2h21.8v7.2H1038.4z"
        />
        <path
          id="jQuery"
          d="M1148.2,396.9c-1.2,0-2.3-0.1-3.5-0.3c-1-0.1-1.9-0.4-2.8-0.6v-6.6c0.8,0.2,1.5,0.4,2.3,0.5c0.8,0.1,1.7,0.2,2.6,0.2
              c1.4,0.1,2.7-0.4,3.8-1.3c1-0.9,1.5-2.5,1.5-4.8v-43.4h8.3v44.1c0,2.2-0.4,4.3-1.3,6.3c-0.8,1.8-2.2,3.3-3.9,4.3
              C1153,396.4,1150.6,397,1148.2,396.9z M1151.5,330.3c-0.1-1.3,0.4-2.6,1.4-3.5c2-1.4,4.6-1.4,6.6,0c1,0.9,1.5,2.2,1.4,3.5
              c0.1,1.3-0.4,2.6-1.4,3.5c-2,1.4-4.6,1.4-6.6,0C1151.9,332.9,1151.4,331.6,1151.5,330.3z M1218.6,353.8c0,3.7-0.5,7.4-1.6,11
              c-0.9,3.1-2.5,6-4.6,8.5c-2.1,2.4-4.8,4.2-7.8,5.3l12.2,13.3h-11.3l-9.7-11.6h-0.7h-0.6c-3.7,0.1-7.3-0.6-10.7-1.9
              c-2.9-1.2-5.5-3-7.5-5.4c-2-2.5-3.5-5.3-4.4-8.4c-1-3.5-1.5-7.2-1.5-10.9c-0.1-4.8,0.8-9.5,2.6-13.9c1.6-3.8,4.4-7.1,8-9.2
              c3.6-2.2,8.1-3.3,13.6-3.3c5.3,0,9.8,1.1,13.3,3.3c3.6,2.2,6.3,5.4,8,9.2C1217.9,344.2,1218.8,349,1218.6,353.8z M1179.4,353.8
              c-0.1,3.5,0.5,7,1.6,10.3c0.9,2.7,2.7,5,5,6.7c5.3,3.1,11.9,3.1,17.3,0c2.3-1.6,4-4,4.9-6.7c1.1-3.3,1.6-6.8,1.6-10.3
              c0-6-1.2-10.7-3.6-14.1s-6.2-5.1-11.5-5.1c-3.1-0.1-6.1,0.7-8.7,2.3c-2.3,1.6-4.1,4-5,6.6C1179.9,346.8,1179.3,350.3,1179.4,353.8
              L1179.4,353.8z M1262.7,340.6v38.9h-6.5l-1.2-5.2h-0.4c-0.8,1.3-1.9,2.4-3.1,3.3c-1.3,0.9-2.7,1.6-4.2,2c-1.6,0.4-3.2,0.6-4.9,0.6
              c-2.6,0.1-5.1-0.4-7.5-1.5c-2.1-1-3.7-2.6-4.8-4.6c-1.2-2.5-1.8-5.3-1.7-8.1v-25.4h8.3v23.9c0,3,0.6,5.3,1.8,6.8
              c1.2,1.5,3.1,2.3,5.8,2.3c2.1,0.1,4.2-0.4,6-1.5c1.5-1.1,2.6-2.7,3.1-4.6c0.7-2.4,1-4.9,1-7.4v-19.4H1262.7z M1290.1,339.9
              c3.1-0.1,6.2,0.6,8.9,2.1c2.5,1.4,4.5,3.5,5.7,6.1c1.4,2.9,2.1,6.2,2,9.4v4.4h-26c0.1,3.8,1.1,6.7,3,8.7s4.7,3.1,8.1,3.1
              c2.3,0,4.5-0.2,6.7-0.7c2.1-0.5,4.2-1.2,6.1-2.1v6.7c-1.9,0.9-3.9,1.6-5.9,2c-2.4,0.5-4.8,0.7-7.2,0.6c-3.5,0.1-7-0.7-10.1-2.2
              c-2.9-1.5-5.3-3.8-6.8-6.6c-1.7-3.4-2.5-7.2-2.4-11c-0.1-3.8,0.6-7.6,2.2-11.1c1.3-2.9,3.5-5.3,6.2-7
              C1283.5,340.6,1286.8,339.8,1290.1,339.9z M1290.1,346.1c-2.4-0.1-4.7,0.8-6.3,2.5c-1.6,1.7-2.6,4.2-2.8,7.4h17.7
              c0-1.8-0.3-3.5-1-5.1c-0.6-1.4-1.5-2.6-2.8-3.5C1293.4,346.5,1291.7,346.1,1290.1,346.1z M1335.8,339.9c0.7,0,1.4,0,2.2,0.1
              c0.7,0.1,1.3,0.2,2,0.3l-0.8,7.8c-0.6-0.2-1.2-0.3-1.8-0.3c-0.6-0.1-1.3-0.1-1.9-0.1c-1.4,0-2.9,0.2-4.2,0.7
              c-1.3,0.5-2.5,1.2-3.6,2.2c-1.1,1-1.9,2.3-2.4,3.6c-0.6,1.6-0.9,3.3-0.9,5.1v20.3h-8.3v-38.9h6.5l1.1,6.9h0.4
              c0.8-1.4,1.7-2.7,2.9-3.8c1.2-1.2,2.5-2.1,4-2.8C1332.4,340.2,1334.1,339.9,1335.8,339.9z M1341.2,340.6h9l8,22.2c0.4,1,0.7,2,1,3
              c0.3,1,0.6,1.9,0.8,2.9s0.4,1.9,0.5,2.8h0.2c0.2-1.2,0.6-2.6,1-4.1s0.9-3,1.5-4.6l7.6-22.2h8.9l-16.7,44.3c-0.8,2.3-2,4.5-3.5,6.5
              c-1.3,1.7-3,3.1-4.9,4.1c-2,1-4.3,1.4-6.5,1.4c-1,0-2-0.1-3.1-0.2c-0.9-0.1-1.6-0.3-2.2-0.4v-6.6c0.6,0.1,1.2,0.2,1.9,0.3
              c0.8,0.1,1.5,0.1,2.3,0.1c1.3,0,2.6-0.3,3.8-0.9c1.1-0.6,2-1.5,2.7-2.5c0.8-1.1,1.4-2.3,1.9-3.6l1.4-3.6L1341.2,340.6z"
        />
        <path
          id="matplotlib"
          d="M1497,340.9c4.3,0,7.6,1.1,9.9,3.4s3.3,5.9,3.3,10.8v25.5h-8.3v-24c0-3-0.6-5.2-1.7-6.7c-1.3-1.6-3.2-2.4-5.2-2.2
              c-3.3,0-5.7,1-7.1,3.1c-1.4,2.1-2.2,5.1-2.2,9.1v20.6h-8.3v-23.9c0.1-1.7-0.2-3.4-0.8-5c-0.4-1.2-1.2-2.3-2.3-3
              c-1.2-0.7-2.5-1-3.9-1c-1.9-0.1-3.8,0.4-5.4,1.5c-1.4,1.2-2.5,2.7-2.9,4.5c-0.6,2.4-0.9,4.9-0.9,7.4v19.5h-8.3v-38.8h6.5l1.2,5.2
              h0.5c0.8-1.3,1.8-2.5,3-3.4c1.2-0.9,2.5-1.6,3.9-2c3.9-1.1,8.1-0.8,11.9,0.9c2,1,3.6,2.6,4.5,4.7h0.6c1.2-2.1,2.9-3.7,5.1-4.7
              C1492.3,341.4,1494.6,340.9,1497,340.9z M1537,340.9c4.9,0,8.6,1.1,11.2,3.3c2.5,2.2,3.8,5.6,3.8,10.2v26.2h-5.9l-1.6-5.5h-0.3
              c-1,1.3-2.1,2.5-3.4,3.5c-1.2,0.9-2.5,1.6-4,2.1c-1.8,0.5-3.7,0.7-5.6,0.7c-2.2,0.1-4.3-0.4-6.3-1.2c-1.8-0.8-3.3-2.2-4.3-3.9
              c-1.1-2-1.6-4.4-1.6-6.7c0-4,1.5-7,4.5-9s7.5-3.1,13.6-3.3l6.7-0.2v-2c0-2.7-0.6-4.6-1.9-5.7c-1.5-1.2-3.4-1.9-5.3-1.7
              c-1.9,0-3.8,0.3-5.7,0.9c-1.8,0.5-3.6,1.2-5.3,2l-2.7-5.8c2.1-1.1,4.3-1.9,6.5-2.5C1531.9,341.3,1534.4,341,1537,340.9z
              M1543.7,362l-5,0.2c-4.1,0.1-7,0.8-8.7,2.1c-1.6,1.2-2.6,3.2-2.5,5.2c-0.1,1.6,0.5,3.2,1.7,4.2c1.3,0.9,2.9,1.4,4.5,1.3
              c2.6,0.1,5.1-0.8,7.1-2.5c1.9-1.6,2.8-4,2.8-7.1L1543.7,362z M1578,374.6c1.1,0,2.1-0.1,3.2-0.3c1-0.2,1.9-0.4,2.9-0.7v6.3
              c-1.1,0.5-2.3,0.8-3.6,1c-1.5,0.3-3,0.4-4.5,0.4c-2,0-4.1-0.3-5.9-1.1c-1.8-0.8-3.3-2.1-4.2-3.8c-1.1-2.3-1.7-4.9-1.6-7.5V348h-5.3
              v-3.7l5.7-3l2.7-8.3h5.2v8.6h11.2v6.3h-11.2v20.8c-0.1,1.6,0.4,3.2,1.5,4.4C1575.1,374.1,1576.5,374.7,1578,374.6z M1611.9,340.9
              c4.3-0.1,8.4,1.7,11.1,5.1c2.8,3.4,4.2,8.4,4.2,15c0.1,3.8-0.6,7.6-2,11.1c-1.1,2.8-3,5.1-5.4,6.8c-2.4,1.6-5.3,2.4-8.1,2.3
              c-1.7,0-3.5-0.2-5.1-0.8c-1.4-0.4-2.6-1.1-3.7-2c-1-0.8-1.9-1.7-2.6-2.7h-0.5c0.1,0.9,0.2,1.9,0.3,3.1s0.2,2.2,0.2,3.1v15.8h-8.3
              v-56.2h6.8l1.2,5.4h0.4c0.8-1.1,1.6-2.2,2.7-3.1c1.1-1,2.4-1.7,3.8-2.2C1608.4,341.2,1610.2,340.9,1611.9,340.9z M1609.7,347.7
              c-1.9-0.1-3.8,0.4-5.5,1.4c-1.4,1-2.5,2.4-3,4c-0.7,2.2-1,4.5-1,6.8v1.2c-0.1,2.5,0.3,4.9,0.9,7.3c0.5,1.8,1.5,3.4,3,4.6
              c1.7,1.1,3.7,1.7,5.7,1.6c1.8,0.1,3.6-0.5,5-1.6c1.4-1.3,2.5-2.9,3-4.7c0.7-2.3,1-4.8,1-7.2c0-4.2-0.7-7.4-2.2-9.8
              C1615.2,348.9,1612.5,347.5,1609.7,347.7L1609.7,347.7z M1645.1,380.6h-8.3v-54.7h8.3V380.6z M1691.3,361.1c0,2.9-0.4,5.8-1.3,8.6
              c-0.8,2.4-2,4.5-3.7,6.4c-1.6,1.7-3.6,3.1-5.8,3.9c-2.5,0.9-5.1,1.4-7.7,1.3c-2.5,0-5-0.4-7.3-1.3c-2.2-0.9-4.2-2.2-5.8-3.9
              c-1.7-1.8-3-4-3.8-6.4c-0.9-2.8-1.4-5.7-1.3-8.6c-0.1-3.8,0.7-7.5,2.2-10.9c1.4-2.9,3.6-5.3,6.4-6.8c3-1.6,6.4-2.5,9.9-2.4
              c3.3-0.1,6.6,0.7,9.5,2.4c2.8,1.6,5,4,6.4,6.9C1690.6,353.6,1691.4,357.3,1691.3,361.1z M1663.1,361.1c-0.1,2.5,0.3,4.9,1,7.3
              c1.5,4.9,6.7,7.6,11.5,6.1c2.9-0.9,5.2-3.2,6.1-6.1c0.7-2.4,1.1-4.8,1-7.3c0.1-2.4-0.3-4.9-1-7.2c-0.6-1.8-1.7-3.4-3.2-4.5
              c-1.7-1.1-3.6-1.6-5.6-1.6c-3.4,0-5.9,1.1-7.5,3.5C1663.8,353.5,1663.1,356.8,1663.1,361.1L1663.1,361.1z M1715.3,374.6
              c1.1,0,2.1-0.1,3.2-0.3c1-0.2,1.9-0.4,2.9-0.7v6.3c-1.1,0.5-2.3,0.8-3.6,1c-1.5,0.3-3,0.4-4.5,0.4c-2.1,0-4.1-0.3-6-1.1
              c-1.8-0.8-3.3-2.1-4.2-3.8c-1.1-2.3-1.7-4.9-1.6-7.5V348h-5.3v-3.7l5.7-3l2.7-8.3h5.2v8.6h11.2v6.3h-11.1v20.8
              c-0.1,1.6,0.4,3.2,1.5,4.4C1712.4,374.1,1713.8,374.7,1715.3,374.6z M1737.6,380.6h-8.3v-54.7h8.3V380.6z M1753.6,326.8
              c1.2,0,2.3,0.3,3.3,1c1,0.9,1.5,2.2,1.4,3.5c0.1,1.3-0.4,2.6-1.4,3.5c-2,1.4-4.6,1.4-6.6,0c-1-0.9-1.5-2.2-1.4-3.5
              c-0.1-1.3,0.4-2.6,1.4-3.5C1751.2,327.1,1752.4,326.7,1753.6,326.8z M1757.6,341.7v38.9h-8.3v-38.9L1757.6,341.7z M1777.7,325.9
              v13.2c0,1.5,0,3-0.1,4.5s-0.2,2.6-0.2,3.4h0.3c1.1-1.7,2.6-3.2,4.4-4.3c6.2-3.2,13.8-1.9,18.4,3.3c2.8,3.4,4.2,8.4,4.2,15
              c0.1,3.8-0.6,7.6-1.9,11.1c-1.1,2.8-3,5.1-5.4,6.8c-2.4,1.6-5.3,2.4-8.2,2.3c-2.5,0.1-5-0.4-7.2-1.6c-1.7-1-3.1-2.3-4.3-3.8h-0.6
              l-1.5,4.8h-6.2v-54.7H1777.7z M1787.2,347.7c-2-0.1-3.9,0.4-5.6,1.4c-1.4,1-2.5,2.5-3,4.2c-0.7,2.3-1,4.7-1,7.1v0.7
              c0,4.3,0.7,7.7,2.1,10c1.4,2.3,3.9,3.4,7.5,3.4c2.7,0.1,5.2-1.2,6.7-3.4c1.5-2.3,2.3-5.7,2.3-10s-0.8-7.7-2.3-10
              C1792.4,348.9,1789.9,347.6,1787.2,347.7z"
        />
        <path
          id="video_editing"
          d="M1133.1,440.8l-29.9,85.7h-14.9l-29.9-85.7h14.4l18,53.7c0.5,1.6,1.1,3.5,1.8,5.7s1.3,4.5,1.8,6.7s1,4.3,1.3,6
              c0.3-1.8,0.8-3.8,1.3-6s1.2-4.5,1.8-6.7s1.2-4.1,1.8-5.7l18-53.6L1133.1,440.8z M1150,436.8c2,0,3.9,0.6,5.5,1.7
              c1.5,1.1,2.3,3.1,2.3,5.9c0,2.7-0.8,4.7-2.3,5.9c-3.3,2.3-7.7,2.3-11.1,0c-1.5-1.2-2.3-3.1-2.3-5.9s0.8-4.7,2.3-5.9
              C1146,437.3,1148,436.7,1150,436.8z M1156.8,461.6v64.9h-13.8v-64.9L1156.8,461.6z M1198.2,527.6c-7.7,0-13.9-2.8-18.5-8.5
              c-4.7-5.6-7-14-7-25c0-11.1,2.4-19.5,7.1-25.3s11-8.6,18.8-8.5c2.9-0.1,5.8,0.4,8.6,1.3c2.3,0.8,4.5,2,6.4,3.5
              c1.7,1.4,3.3,3.1,4.5,5h0.6c-0.2-1.2-0.4-3-0.6-5.3c-0.2-2.1-0.4-4.2-0.4-6.3v-23.3h13.8v91.2h-10.8l-2.5-8.8h-0.6
              c-1.2,1.9-2.7,3.6-4.4,5c-1.9,1.6-4,2.8-6.4,3.6C1204.1,527.2,1201.1,527.7,1198.2,527.6z M1202.1,516.4c5.9,0,10-1.7,12.4-5.1
              c2.4-3.4,3.7-8.5,3.7-15.3v-1.8c0-7.3-1.2-12.8-3.5-16.7c-2.3-3.9-6.6-5.8-12.8-5.8c-4.6-0.2-8.9,2.1-11.3,6
              c-2.6,4-3.9,9.6-3.9,16.7c0,7.1,1.3,12.6,3.9,16.4C1193.3,514.6,1197.1,516.5,1202.1,516.4L1202.1,516.4z M1277,460.4
              c5.2-0.1,10.3,1.1,14.9,3.6c4.1,2.4,7.4,5.9,9.6,10.1c2.3,4.9,3.5,10.3,3.3,15.7v7.4h-43.3c0.2,6.3,1.8,11.1,5.1,14.5
              c3.2,3.4,7.7,5.1,13.6,5.1c3.8,0,7.5-0.4,11.2-1.2c3.5-0.9,6.9-2.1,10.2-3.5v11.2c-3.2,1.5-6.5,2.6-9.9,3.3c-4,0.7-8,1.1-12.1,1
              c-5.8,0.1-11.6-1.1-16.8-3.7c-4.8-2.4-8.8-6.3-11.3-11.1c-2.7-4.9-4-11.1-4-18.4c0-7.3,1.2-13.5,3.7-18.5
              c2.2-4.8,5.8-8.9,10.4-11.6C1266.2,461.6,1271.5,460.2,1277,460.4z M1277,470.7c-3.9-0.2-7.8,1.4-10.5,4.2
              c-2.7,2.8-4.3,6.9-4.7,12.4h29.5c0-2.9-0.5-5.9-1.6-8.6c-0.9-2.4-2.6-4.4-4.7-5.8C1282.6,471.4,1279.8,470.6,1277,470.7L1277,470.7
              z M1377.7,493.9c0.1,4.9-0.6,9.7-2.1,14.4c-1.3,3.9-3.4,7.6-6.2,10.6c-2.7,2.9-6,5.1-9.7,6.6c-4.1,1.5-8.5,2.3-12.8,2.2
              c-4.2,0.1-8.3-0.7-12.2-2.2c-3.7-1.4-7-3.7-9.6-6.6c-2.8-3.1-5-6.7-6.3-10.6c-1.6-4.6-2.3-9.5-2.2-14.4c0-7.2,1.2-13.2,3.7-18.2
              c2.3-4.8,6-8.8,10.6-11.4c5-2.7,10.7-4.1,16.5-3.9c5.5-0.1,11,1.2,15.8,3.9c4.6,2.7,8.3,6.7,10.7,11.4
              C1376.4,480.7,1377.7,486.8,1377.7,493.9z M1330.7,493.9c-0.1,4.1,0.5,8.2,1.7,12.1c2.5,8.1,11.1,12.7,19.2,10.2
              c4.9-1.5,8.7-5.3,10.2-10.2c1.2-3.9,1.8-8,1.7-12.1c0.1-4.1-0.5-8.1-1.7-12c-1-3-2.8-5.7-5.4-7.6c-2.8-1.8-6-2.8-9.4-2.6
              c-5.7,0-9.9,1.9-12.5,5.7S1330.7,486.8,1330.7,493.9z M1474.6,526.5h-48.5v-85.7h48.5v11.8h-34.5v23.3h32.3v11.8h-32.3v26.9h34.5
              V526.5z M1513.4,527.6c-7.7,0-13.9-2.8-18.6-8.5c-4.7-5.6-7-14-7-25c0-11.1,2.4-19.5,7.1-25.3s11-8.6,18.8-8.5
              c2.9-0.1,5.8,0.4,8.6,1.3c2.3,0.8,4.5,2,6.4,3.5c1.7,1.4,3.3,3.1,4.5,5h0.6c-0.2-1.2-0.4-3-0.6-5.3s-0.3-4.4-0.3-6.3v-23.3h13.8
              v91.2h-10.8l-2.5-8.8h-0.6c-1.2,1.9-2.7,3.6-4.4,5c-1.9,1.6-4,2.8-6.3,3.6C1519.3,527.2,1516.4,527.7,1513.4,527.6z M1517.3,516.4
              c5.9,0,10-1.7,12.4-5.1c2.4-3.4,3.7-8.5,3.7-15.3v-1.8c0-7.3-1.2-12.8-3.5-16.7c-2.3-3.9-6.6-5.8-12.8-5.8c-4.6-0.2-8.9,2.1-11.3,6
              c-2.6,4-3.9,9.6-3.9,16.7c0,7.1,1.3,12.6,3.9,16.4C1508.5,514.6,1512.3,516.5,1517.3,516.4L1517.3,516.4z M1573.4,436.8
              c2,0,3.9,0.6,5.4,1.7c1.5,1.1,2.3,3.1,2.3,5.9c0,2.7-0.8,4.7-2.3,5.9c-3.3,2.3-7.7,2.3-11,0c-1.5-1.2-2.3-3.1-2.3-5.9
              s0.8-4.7,2.3-5.9C1569.4,437.3,1571.4,436.7,1573.4,436.8z M1580.2,461.6v64.9h-13.8v-64.9L1580.2,461.6z M1624,516.5
              c1.8,0,3.6-0.2,5.3-0.5c1.6-0.3,3.2-0.7,4.8-1.2v10.4c-1.9,0.8-3.9,1.3-5.9,1.7c-2.5,0.5-5,0.7-7.6,0.7c-3.4,0.1-6.8-0.6-9.9-1.8
              c-3-1.3-5.5-3.5-7-6.4c-1.7-3-2.6-7.2-2.6-12.5v-34.8h-8.8V466l9.5-4.9l4.5-13.9h8.7v14.4h18.6v10.5H1615v34.6
              c0,3.3,0.8,5.7,2.5,7.3C1619.2,515.7,1621.6,516.6,1624,516.5z M1654.3,436.8c2-0.1,3.9,0.5,5.5,1.7c1.6,1.1,2.3,3.1,2.3,5.9
              c0,2.7-0.8,4.7-2.3,5.9c-3.3,2.3-7.7,2.3-11,0c-1.5-1.2-2.3-3.1-2.3-5.9s0.8-4.7,2.3-5.9C1650.4,437.3,1652.3,436.7,1654.3,436.8z
              M1661.1,461.6v64.9h-13.8v-64.9L1661.1,461.6z M1714.6,460.4c7.3,0,13,1.9,17.1,5.7c4.1,3.8,6.1,9.8,6.1,18.1v42.3H1724v-39.7
              c0-5-1-8.8-3.1-11.3s-5.3-3.8-9.6-3.8c-6.3,0-10.7,1.9-13.1,5.8c-2.4,3.9-3.7,9.5-3.7,16.8v32.2h-13.8v-64.9h10.8l1.9,8.8h0.8
              c1.3-2.2,3.1-4.1,5.2-5.6c2.1-1.5,4.5-2.6,7-3.3C1709.1,460.7,1711.8,460.4,1714.6,460.4z M1775.5,555.3c-8.8,0-15.5-1.6-20.3-4.8
              c-4.5-2.9-7.2-7.9-7.1-13.3c-0.1-3.7,1.3-7.4,3.8-10.1c2.5-2.7,6.1-4.6,10.8-5.7c-1.8-0.8-3.4-2.1-4.6-3.7
              c-1.3-1.6-1.9-3.5-1.9-5.6c-0.1-2.3,0.7-4.4,2.1-6.2c1.7-2,3.8-3.7,6.1-5c-3.4-1.4-6.3-3.9-8.2-7.1c-2.1-3.5-3.2-7.5-3.1-11.5
              c-0.1-4.2,0.9-8.4,3-12c2.1-3.4,5.1-6,8.8-7.6c4.4-1.8,9.1-2.7,13.9-2.6c1.2,0,2.5,0.1,3.8,0.2s2.7,0.3,3.9,0.5
              c0.9,0.1,1.8,0.3,2.7,0.6h22.4v7.7l-11,2c1.1,1.6,1.9,3.3,2.5,5.1c0.6,2,1,4.1,0.9,6.2c0,6.7-2.3,12-6.9,15.8s-11,5.8-19.1,5.8
              c-1.9,0-3.8-0.2-5.7-0.5c-1.2,0.7-2.3,1.7-3.2,2.9c-0.7,1-1.1,2.2-1.1,3.5c0,1,0.4,1.9,1.1,2.6c1,0.8,2.1,1.2,3.3,1.4
              c1.8,0.3,3.6,0.5,5.4,0.5h11.2c7.1,0,12.5,1.5,16.3,4.5s5.6,7.4,5.6,13.2c0.2,6.9-3.2,13.4-9.1,17.1
              C1795.7,553.3,1787,555.3,1775.5,555.3z M1776,545.6c4.1,0.1,8.1-0.4,12.1-1.4c2.8-0.7,5.4-2,7.5-4c1.7-1.6,2.6-3.9,2.6-6.2
              c0.1-1.8-0.5-3.5-1.6-4.9c-1.3-1.2-3-2.1-4.8-2.3c-2.6-0.5-5.2-0.7-7.9-0.6h-10.1c-2.3,0-4.6,0.4-6.7,1.2c-1.8,0.7-3.4,1.9-4.5,3.5
              c-1.1,1.6-1.7,3.5-1.6,5.5c-0.1,2.9,1.5,5.5,4,6.9C1767.5,544.8,1771.2,545.6,1776,545.6L1776,545.6z M1778.7,495.1
              c4,0,7-1.1,8.9-3.3s2.9-5.3,2.9-9.3c0-4.3-1-7.6-3-9.8c-2-2.2-5-3.3-8.9-3.3c-3.9,0-6.8,1.1-8.8,3.3s-3,5.5-3,9.8c0,3.9,1,7,3,9.2
              C1771.8,494,1774.8,495.1,1778.7,495.1L1778.7,495.1z"
        />
        <path
          id="photoshop"
          d="M1201,591.1c6.6,0,11.5,1.4,14.5,4.1c3,2.7,4.6,6.5,4.6,11.5c0,2.1-0.3,4.3-1,6.3c-0.7,2-1.9,3.8-3.4,5.3
              c-1.8,1.7-3.9,2.9-6.2,3.7c-3.1,1-6.3,1.4-9.5,1.3h-5.5v19.2h-8.4v-51.4L1201,591.1z M1200.5,598.1h-5.9v18.1h4.6
              c2.3,0.1,4.6-0.3,6.7-0.9c1.7-0.5,3.2-1.6,4.2-3c1-1.6,1.5-3.5,1.4-5.3c0-3-0.9-5.2-2.7-6.7S1204.3,598.1,1200.5,598.1
              L1200.5,598.1z M1237.8,587.8v13.7c0,1.4,0,2.8-0.1,4.2s-0.2,2.4-0.3,3.2h0.4c0.8-1.3,1.8-2.4,3-3.4c1.2-0.9,2.6-1.6,4-2
              c1.5-0.5,3.2-0.7,4.8-0.7c2.6-0.1,5.2,0.4,7.6,1.5c2.1,1,3.8,2.6,4.8,4.6c1.2,2.5,1.8,5.3,1.7,8.1v25.4h-8.3v-23.8
              c0-3-0.6-5.3-1.9-6.8c-1.3-1.5-3.2-2.3-5.8-2.3c-2.1-0.1-4.2,0.4-6,1.6c-1.5,1.1-2.6,2.8-3.2,4.6c-0.7,2.4-1,4.9-1,7.4v19.3h-8.3
              v-54.7H1237.8z M1309.7,622.9c0,2.9-0.4,5.8-1.3,8.6c-0.8,2.4-2,4.5-3.7,6.4c-1.6,1.7-3.6,3.1-5.8,3.9c-2.5,0.9-5.1,1.4-7.7,1.3
              c-2.5,0-5-0.4-7.3-1.3c-2.2-0.9-4.2-2.2-5.8-3.9c-1.7-1.8-3-4-3.8-6.4c-0.9-2.8-1.4-5.7-1.3-8.6c-0.1-3.8,0.7-7.5,2.2-10.9
              c1.4-2.9,3.6-5.3,6.4-6.8c3-1.6,6.4-2.5,9.9-2.4c3.3-0.1,6.6,0.7,9.5,2.4c2.8,1.6,5,4,6.4,6.9
              C1309,615.4,1309.8,619.2,1309.7,622.9z M1281.5,622.9c-0.1,2.5,0.3,4.9,1,7.3c1.5,4.9,6.7,7.6,11.5,6.1c2.9-0.9,5.2-3.2,6.1-6.1
              c0.7-2.4,1.1-4.8,1-7.3c0.1-2.4-0.3-4.9-1-7.2c-0.6-1.8-1.7-3.4-3.2-4.5c-1.7-1.1-3.6-1.7-5.6-1.6c-3.4,0-5.9,1.2-7.5,3.5
              C1282.2,615.4,1281.4,618.7,1281.5,622.9z M1333.7,636.5c1.1,0,2.1-0.1,3.2-0.3c1-0.2,1.9-0.4,2.9-0.7v6.3c-1.1,0.5-2.3,0.8-3.6,1
              c-1.5,0.3-3,0.4-4.5,0.4c-2,0-4.1-0.3-5.9-1.1c-1.8-0.8-3.3-2.1-4.2-3.8c-1.1-2.3-1.7-4.9-1.6-7.5v-20.9h-5.3v-3.7l5.7-3l2.7-8.3
              h5.2v8.7h11.2v6.3h-11.2v20.8c-0.1,1.6,0.4,3.2,1.5,4.4C1330.8,636,1332.2,636.6,1333.7,636.5z M1382.1,622.9
              c0,2.9-0.4,5.8-1.3,8.6c-0.8,2.4-2,4.5-3.7,6.4c-1.6,1.7-3.6,3.1-5.8,3.9c-2.5,0.9-5.1,1.4-7.7,1.3c-2.5,0-5-0.4-7.3-1.3
              c-2.2-0.9-4.2-2.2-5.8-3.9c-1.7-1.8-3-4-3.8-6.4c-0.9-2.8-1.4-5.7-1.3-8.6c-0.1-3.8,0.7-7.5,2.2-10.9c1.4-2.9,3.6-5.3,6.4-6.8
              c3-1.6,6.4-2.5,9.9-2.4c3.3-0.1,6.6,0.7,9.5,2.4c2.8,1.6,5,4,6.4,6.9C1381.4,615.4,1382.2,619.2,1382.1,622.9z M1353.9,622.9
              c-0.1,2.5,0.3,4.9,1,7.3c1.5,4.9,6.7,7.6,11.5,6.1c2.9-0.9,5.2-3.2,6.1-6.1c0.7-2.4,1.1-4.8,1-7.3c0.1-2.4-0.3-4.9-1-7.2
              c-0.6-1.8-1.7-3.4-3.2-4.5c-1.7-1.1-3.6-1.7-5.6-1.6c-3.4,0-5.9,1.2-7.5,3.5C1354.6,615.4,1353.9,618.7,1353.9,622.9z
              M1417.8,631.4c0.1,2.3-0.6,4.6-1.9,6.5c-1.4,1.9-3.3,3.3-5.4,4c-2.8,1-5.8,1.4-8.7,1.3c-2.3,0-4.7-0.2-7-0.6
              c-1.9-0.4-3.8-1-5.6-1.8v-7.1c2,0.9,4.1,1.7,6.3,2.3c2.1,0.6,4.3,1,6.6,1c2.7,0,4.7-0.4,5.8-1.3c1.2-0.8,1.8-2.1,1.8-3.4
              c0-0.8-0.2-1.6-0.7-2.3c-0.7-0.9-1.6-1.6-2.7-2.1c-1.9-1-3.8-1.8-5.8-2.5c-2.1-0.8-4.2-1.8-6.2-3c-1.5-0.9-2.9-2.1-3.8-3.6
              c-0.9-1.6-1.4-3.5-1.3-5.3c-0.2-3.2,1.4-6.2,4.1-7.9c2.7-1.8,6.4-2.7,10.9-2.7c2.3,0,4.5,0.2,6.8,0.7c2.1,0.5,4.2,1.2,6.2,2.1
              l-2.6,6.2c-1.7-0.7-3.5-1.4-5.3-1.9c-1.8-0.5-3.6-0.8-5.4-0.8c-1.7-0.1-3.4,0.2-4.9,1c-1,0.5-1.7,1.6-1.7,2.8
              c0,0.8,0.3,1.7,0.8,2.3c0.8,0.8,1.7,1.5,2.8,1.9c1.3,0.6,3.2,1.4,5.5,2.4c2.1,0.8,4.1,1.7,6,2.8c1.6,0.9,2.9,2.1,3.9,3.6
              C1417.4,627.6,1417.9,629.5,1417.8,631.4z M1434.9,587.8v13.7c0,1.4,0,2.8-0.1,4.2s-0.2,2.4-0.3,3.2h0.5c0.8-1.3,1.8-2.4,3-3.4
              c1.2-0.9,2.6-1.6,4-2c1.5-0.5,3.1-0.7,4.8-0.7c2.6-0.1,5.2,0.4,7.6,1.5c2.1,1,3.7,2.6,4.8,4.6c1.2,2.5,1.8,5.3,1.7,8.1v25.4h-8.3
              v-23.8c0-3-0.6-5.3-1.9-6.8s-3.2-2.3-5.7-2.3c-2.1-0.1-4.2,0.4-6,1.6c-1.5,1.1-2.6,2.8-3.2,4.6c-0.7,2.4-1,4.9-0.9,7.4v19.3h-8.3
              v-54.7H1434.9z M1506.9,622.9c0,2.9-0.4,5.8-1.3,8.6c-0.8,2.4-2,4.5-3.7,6.4c-1.6,1.7-3.6,3.1-5.8,3.9c-2.5,0.9-5.1,1.4-7.7,1.3
              c-2.5,0-5-0.4-7.3-1.3c-2.2-0.9-4.2-2.2-5.8-3.9c-1.7-1.8-3-4-3.8-6.4c-0.9-2.8-1.4-5.7-1.3-8.6c-0.1-3.8,0.7-7.5,2.2-10.9
              c1.4-2.9,3.6-5.3,6.4-6.8c3-1.6,6.4-2.5,9.9-2.4c3.3-0.1,6.6,0.7,9.5,2.4c2.8,1.6,5,4,6.4,6.9
              C1506.2,615.4,1507,619.2,1506.9,622.9z M1478.6,622.9c-0.1,2.5,0.3,4.9,1,7.3c1.5,4.9,6.7,7.6,11.5,6.1c2.9-0.9,5.2-3.2,6.1-6.1
              c0.7-2.4,1.1-4.8,1-7.3c0.1-2.4-0.3-4.9-1-7.2c-0.6-1.8-1.7-3.4-3.2-4.5c-1.7-1.1-3.6-1.7-5.6-1.6c-3.4,0-5.9,1.2-7.5,3.5
              C1479.4,615.4,1478.6,618.7,1478.6,622.9z M1536.3,602.8c4.3-0.1,8.4,1.7,11.1,5.1c2.8,3.4,4.2,8.4,4.2,15
              c0.1,3.8-0.6,7.6-1.9,11.1c-1.1,2.8-3,5.1-5.4,6.8c-2.4,1.6-5.3,2.4-8.1,2.3c-1.7,0-3.5-0.2-5.1-0.8c-1.4-0.4-2.6-1.1-3.7-2
              c-1-0.8-1.9-1.7-2.6-2.7h-0.5c0.1,0.9,0.2,1.9,0.3,3.1s0.2,2.2,0.2,3.1v15.8h-8.3v-56.2h6.8l1.2,5.4h0.4c0.8-1.1,1.6-2.2,2.7-3.1
              c1.1-1,2.4-1.7,3.8-2.2C1532.8,603.1,1534.5,602.8,1536.3,602.8z M1534.1,609.6c-1.9-0.1-3.8,0.4-5.5,1.3c-1.4,1-2.5,2.4-3,4
              c-0.7,2.2-1,4.5-1,6.8v1.2c-0.1,2.5,0.2,4.9,0.9,7.3c0.5,1.8,1.5,3.4,3,4.6c1.7,1.1,3.7,1.7,5.7,1.6c1.8,0.1,3.6-0.5,5-1.7
              c1.4-1.3,2.5-2.9,3-4.7c0.7-2.3,1-4.8,1-7.2c0-4.2-0.7-7.5-2.2-9.8C1539.5,610.7,1536.9,609.4,1534.1,609.6L1534.1,609.6z"
        />
        <path
          id="java"
          d="M1628.9,641.6c-1.1,0-2.3-0.1-3.4-0.3c-0.9-0.1-1.7-0.3-2.5-0.6v-7.1c0.8,0.2,1.6,0.3,2.4,0.5c0.9,0.1,1.8,0.2,2.8,0.2
              c1.2,0,2.4-0.2,3.5-0.7c1.1-0.6,2-1.5,2.5-2.7c0.7-1.7,1-3.6,0.9-5.5v-49.6h8.5v49.4c0.1,3.2-0.5,6.4-1.8,9.3
              c-1.1,2.3-2.9,4.2-5.1,5.3C1634.2,641.1,1631.6,641.7,1628.9,641.6z M1671.3,587.6c4.9,0,8.6,1.1,11.2,3.3
              c2.5,2.2,3.8,5.6,3.8,10.2v26.2h-5.9l-1.6-5.5h-0.3c-1,1.3-2.1,2.5-3.4,3.5c-1.2,0.9-2.5,1.6-4,2.1c-1.8,0.5-3.7,0.7-5.6,0.7
              c-2.2,0-4.3-0.4-6.3-1.3c-1.8-0.8-3.3-2.2-4.3-3.9c-1.1-2-1.6-4.4-1.6-6.7c0-4,1.5-7,4.5-9c3-2,7.5-3.1,13.6-3.3l6.7-0.3v-2
              c0-2.7-0.6-4.6-1.9-5.7c-1.5-1.2-3.4-1.8-5.3-1.7c-1.9,0-3.8,0.3-5.7,0.8c-1.8,0.6-3.6,1.2-5.3,2l-2.7-5.8c2.1-1.1,4.3-1.9,6.5-2.5
              C1666.2,588,1668.7,587.6,1671.3,587.6z M1678,608.6l-5,0.2c-4.1,0.1-7,0.8-8.7,2.1c-1.6,1.2-2.6,3.1-2.5,5.2
              c-0.1,1.6,0.5,3.2,1.7,4.2c1.3,0.9,2.9,1.4,4.5,1.3c2.6,0.1,5.1-0.8,7.1-2.4c1.9-1.6,2.8-4,2.8-7.2L1678,608.6z M1706.7,627.3
              l-14.8-38.9h8.8l8,22.9c0.5,1.5,1,3.1,1.4,4.7c0.4,1.7,0.7,3.1,0.9,4.3h0.3c0.2-1.5,0.6-2.9,1-4.3c0.5-1.7,1-3.2,1.4-4.7l8-22.9
              h8.7l-14.8,38.9H1706.7z M1751.5,587.6c4.9,0,8.6,1.1,11.2,3.3s3.8,5.6,3.8,10.2v26.2h-5.9l-1.6-5.5h-0.3c-1,1.3-2.1,2.5-3.4,3.5
              c-1.2,0.9-2.5,1.6-4,2.1c-1.8,0.5-3.7,0.7-5.6,0.7c-2.2,0-4.3-0.4-6.3-1.3c-1.8-0.8-3.3-2.2-4.3-3.9c-1.1-2-1.6-4.4-1.6-6.7
              c0-4,1.5-7,4.5-9c3-2,7.5-3.1,13.6-3.3l6.7-0.3v-2c0-2.7-0.6-4.6-1.9-5.7c-1.5-1.2-3.4-1.8-5.3-1.7c-1.9,0-3.8,0.3-5.7,0.8
              c-1.8,0.5-3.6,1.2-5.3,2l-2.7-5.8c2.1-1.1,4.3-1.9,6.5-2.5C1746.4,588,1749,587.6,1751.5,587.6z M1758.2,608.6l-5,0.2
              c-4.1,0.1-7,0.8-8.7,2.1c-1.6,1.2-2.6,3.1-2.5,5.2c-0.1,1.6,0.5,3.2,1.7,4.2c1.3,0.9,2.9,1.4,4.5,1.3c2.6,0.1,5.1-0.8,7.1-2.4
              c1.9-1.6,2.8-4,2.8-7.2L1758.2,608.6z"
        />
      </g>
      <g id="panel-1">
        <path
          id="canvas"
          d="M1056.2,691.7c-2.3,0-4.5,0.4-6.6,1.3c-1.9,0.9-3.6,2.2-4.9,3.9c-1.4,1.8-2.4,3.9-3.1,6.1c-0.7,2.6-1,5.3-1,8
              c-0.1,3.5,0.5,7,1.7,10.2c1,2.7,2.8,5,5.1,6.6c2.6,1.6,5.6,2.4,8.7,2.3c2.1,0,4.3-0.2,6.4-0.6c2.2-0.4,4.3-1,6.4-1.7v7.2
              c-2,0.8-4.2,1.4-6.3,1.8c-2.4,0.4-4.9,0.6-7.4,0.6c-5.3,0-9.6-1.1-13.1-3.3c-3.5-2.2-6.2-5.4-7.7-9.2c-1.8-4.4-2.6-9.2-2.5-13.9
              c0-3.6,0.5-7.2,1.6-10.7c1-3.1,2.6-5.9,4.7-8.4c2.1-2.4,4.7-4.2,7.6-5.5c3.3-1.3,6.9-2,10.4-1.9c2.6,0,5.1,0.3,7.6,0.9
              c2.4,0.6,4.8,1.4,7,2.4l-3,7c-1.8-0.8-3.7-1.6-5.6-2.2C1060.3,692,1058.3,691.7,1056.2,691.7z M1094.3,697c4.9,0,8.6,1.1,11.2,3.3
              c2.5,2.2,3.8,5.6,3.8,10.2v26.2h-5.9l-1.6-5.5h-0.3c-1,1.3-2.1,2.5-3.4,3.5c-1.2,0.9-2.5,1.6-4,2.1c-1.8,0.5-3.7,0.7-5.6,0.7
              c-2.2,0-4.3-0.4-6.3-1.3c-1.8-0.8-3.3-2.2-4.3-3.9c-1.1-2-1.7-4.4-1.6-6.7c0-4,1.5-7,4.5-9c3-2,7.5-3.1,13.6-3.3l6.7-0.3v-2
              c0-2.7-0.6-4.6-1.9-5.7c-1.5-1.2-3.4-1.9-5.3-1.7c-1.9,0-3.8,0.3-5.7,0.8c-1.8,0.5-3.6,1.2-5.3,2l-2.7-5.8c2.1-1.1,4.3-1.9,6.5-2.5
              C1089.3,697.4,1091.8,697,1094.3,697z M1101.1,718.1l-5,0.2c-4.1,0.1-7,0.8-8.7,2.1c-1.6,1.2-2.6,3.2-2.5,5.2
              c-0.1,1.6,0.5,3.2,1.7,4.2c1.3,0.9,2.9,1.4,4.5,1.3c2.6,0.1,5.1-0.8,7.1-2.5c1.9-1.6,2.8-4,2.8-7.2L1101.1,718.1z M1141.1,697
              c4.4,0,7.8,1.1,10.2,3.4s3.7,5.9,3.7,10.9v25.4h-8.3v-23.8c0-3-0.6-5.3-1.9-6.8s-3.2-2.3-5.8-2.3c-3.8,0-6.4,1.2-7.9,3.5
              s-2.2,5.7-2.2,10v19.4h-8.3v-38.9h6.5l1.2,5.3h0.5c0.8-1.3,1.9-2.5,3.2-3.4c1.3-0.9,2.7-1.6,4.2-2
              C1137.9,697.2,1139.5,697,1141.1,697z M1175.5,736.7l-14.8-38.9h8.8l8,22.8c0.5,1.5,1,3.1,1.4,4.7c0.4,1.7,0.7,3.1,0.9,4.3h0.3
              c0.2-1.5,0.6-2.9,1-4.3c0.5-1.7,1-3.2,1.4-4.7l8-22.8h8.7l-14.8,38.9L1175.5,736.7z M1220.3,697c4.9,0,8.6,1.1,11.2,3.3
              c2.5,2.2,3.8,5.6,3.8,10.2v26.2h-5.9l-1.6-5.5h-0.3c-1,1.3-2.1,2.5-3.4,3.5c-1.2,0.9-2.5,1.6-4,2.1c-1.8,0.5-3.7,0.7-5.6,0.7
              c-2.2,0-4.3-0.4-6.3-1.3c-1.8-0.8-3.3-2.2-4.3-3.9c-1.1-2-1.6-4.4-1.6-6.7c0-4,1.5-7,4.5-9c3-2,7.5-3.1,13.6-3.3l6.7-0.3v-2
              c0-2.7-0.6-4.6-1.9-5.7c-1.5-1.2-3.4-1.9-5.3-1.7c-1.9,0-3.8,0.3-5.7,0.8c-1.8,0.5-3.6,1.2-5.3,2l-2.7-5.8c2.1-1.1,4.3-1.9,6.5-2.5
              C1215.2,697.4,1217.8,697,1220.3,697z M1227,718.1l-5,0.2c-4.1,0.1-7,0.8-8.7,2.1c-1.6,1.2-2.6,3.2-2.5,5.2
              c-0.1,1.6,0.5,3.2,1.7,4.2c1.3,0.9,2.9,1.4,4.5,1.3c2.6,0.1,5.1-0.8,7.1-2.5c1.9-1.6,2.8-4,2.8-7.2L1227,718.1z M1273,725.6
              c0.1,2.3-0.6,4.6-1.9,6.5c-1.4,1.8-3.3,3.2-5.4,4c-2.8,1-5.8,1.4-8.7,1.3c-2.3,0-4.7-0.2-7-0.6c-1.9-0.4-3.8-1-5.6-1.8v-7.1
              c2,0.9,4.1,1.7,6.3,2.3c2.1,0.6,4.3,1,6.6,1c2.7,0,4.6-0.4,5.8-1.3c1.2-0.8,1.8-2.1,1.8-3.4c0-0.8-0.2-1.6-0.7-2.3
              c-0.7-0.9-1.6-1.6-2.7-2.1c-1.9-1-3.8-1.8-5.8-2.5c-2.1-0.8-4.2-1.8-6.2-3c-1.5-0.9-2.9-2.1-3.8-3.6c-0.9-1.6-1.4-3.5-1.3-5.3
              c-0.1-3.2,1.4-6.2,4.1-7.9c2.7-1.8,6.4-2.7,10.9-2.7c2.3,0,4.5,0.2,6.8,0.7c2.1,0.5,4.2,1.2,6.2,2.1l-2.6,6.2
              c-1.7-0.7-3.5-1.4-5.3-1.9c-1.8-0.5-3.6-0.8-5.4-0.8c-1.7-0.1-3.4,0.3-4.9,1c-1,0.5-1.7,1.6-1.7,2.8c0,0.8,0.3,1.7,0.8,2.3
              c0.8,0.8,1.7,1.5,2.8,1.9c1.3,0.6,3.2,1.4,5.5,2.3c2.1,0.8,4.1,1.7,6,2.8c1.6,0.9,2.9,2.1,3.9,3.6
              C1272.6,721.8,1273,723.7,1273,725.6z"
        />
        <path
          id="jira"
          d="M901.7,841.9c-1.1,0-2.3-0.1-3.4-0.3c-0.9-0.1-1.7-0.3-2.5-0.6V834c0.8,0.2,1.6,0.3,2.4,0.5c0.9,0.1,1.8,0.2,2.8,0.2
              c1.2,0,2.4-0.2,3.5-0.7c1.1-0.6,2-1.5,2.5-2.7c0.7-1.7,1-3.6,0.9-5.5v-49.6h8.5v49.4c0.1,3.2-0.5,6.4-1.8,9.3
              c-1.1,2.3-2.9,4.1-5.1,5.3C907,841.4,904.4,842,901.7,841.9z M929.7,827.6v-51.4h8.4v51.4H929.7z M966.1,776.2
              c3.7-0.1,7.5,0.4,11,1.6c2.6,0.9,4.9,2.6,6.5,4.9c1.5,2.5,2.2,5.4,2.1,8.4c0.1,2.2-0.4,4.5-1.4,6.5c-0.9,1.7-2.2,3.3-3.7,4.4
              c-1.5,1.2-3.1,2.1-4.9,2.8l14.6,22.8h-9.5L968.4,807h-8.2v20.6h-8.4v-51.4H966.1z M965.6,783.3h-5.4V800h5.8c3.9,0,6.7-0.7,8.5-2.2
              c1.8-1.6,2.8-4,2.7-6.4c0-2.9-0.9-5-2.8-6.3C972.4,783.9,969.5,783.3,965.6,783.3z M1029.5,827.6l-5-14.3h-19.7l-5,14.3H991
              l19.1-51.6h9.3l19.1,51.6H1029.5z M1022.3,806.1l-4.9-14.1c-0.2-0.6-0.4-1.5-0.8-2.6s-0.7-2.2-1-3.4s-0.7-2.2-0.9-3
              c-0.2,1-0.5,2-0.9,3.2s-0.7,2.3-1,3.3s-0.6,1.8-0.7,2.4l-4.9,14.1H1022.3z"
        />
        <path
          id="ajax"
          d="M680.5,875.3l-5-14.3h-19.7l-5,14.3h-8.9l19.1-51.6h9.3l19,51.6H680.5z M673.3,853.8l-4.8-14.1c-0.2-0.6-0.5-1.5-0.8-2.6
              s-0.7-2.2-1-3.4s-0.7-2.2-0.9-3c-0.2,1-0.5,2-0.9,3.2s-0.7,2.3-1,3.3s-0.6,1.8-0.7,2.4l-4.9,14.1L673.3,853.8z M689.9,889.7
              c-1.1,0-2.3-0.1-3.4-0.3c-0.9-0.1-1.7-0.3-2.5-0.6v-7.1c0.8,0.2,1.6,0.4,2.4,0.5c0.9,0.1,1.8,0.2,2.8,0.2c1.2,0,2.4-0.2,3.5-0.7
              c1.1-0.6,2-1.5,2.5-2.7c0.7-1.7,1-3.6,0.9-5.5v-49.6h8.5v49.4c0.1,3.2-0.5,6.4-1.8,9.3c-1.1,2.3-2.9,4.2-5.1,5.3
              C695.2,889.2,692.5,889.7,689.9,889.7z M749.7,875.3l-5-14.3H725l-5,14.3h-8.9l19.1-51.6h9.3l19.1,51.6H749.7z M742.5,853.8
              l-4.9-14.1c-0.2-0.6-0.5-1.5-0.8-2.6s-0.7-2.2-1-3.4s-0.7-2.2-0.9-3c-0.2,1-0.5,2-0.9,3.2s-0.7,2.3-1,3.3s-0.5,1.8-0.7,2.4
              l-4.9,14.1L742.5,853.8z M803.4,875.3h-9.6l-12.9-21l-13,21h-9l17.1-26.7l-16-24.8h9.4l12,19.5l11.9-19.5h9l-16,25L803.4,875.3z"
        />
        <path
          id="bootstrap"
          d="M222.1,858.1h15.3c6.6,0,11.6,1,15,2.9c3.4,1.9,5.1,5.3,5.1,10c0,1.8-0.3,3.6-1,5.3c-0.7,1.5-1.7,2.9-3,3.9
              c-1.4,1.1-3.1,1.8-4.9,2v0.4c1.9,0.3,3.7,0.9,5.3,1.9c1.6,0.9,2.8,2.2,3.7,3.8c1,2,1.5,4.2,1.4,6.5c0.3,5.7-3.2,11-8.6,13
              c-3.1,1.2-6.4,1.8-9.8,1.7h-18.5V858.1z M230.5,879.3h8.1c3.8,0,6.5-0.6,7.9-1.9c1.5-1.4,2.3-3.4,2.2-5.4c0-2.5-0.9-4.2-2.6-5.3
              s-4.5-1.6-8.3-1.6h-7.3L230.5,879.3z M230.5,886.2v16.3h8.9c3.9,0,6.7-0.8,8.3-2.3c1.6-1.6,2.5-3.9,2.4-6.2c0-1.5-0.3-2.9-1.1-4.2
              c-0.8-1.3-2-2.2-3.5-2.7c-2.1-0.7-4.3-1.1-6.5-1L230.5,886.2z M303.1,890c0,2.9-0.4,5.8-1.3,8.6c-0.8,2.4-2,4.5-3.7,6.4
              c-1.6,1.7-3.6,3.1-5.8,3.9c-2.5,0.9-5.1,1.4-7.7,1.3c-2.5,0-5-0.4-7.4-1.3c-2.2-0.9-4.2-2.2-5.8-3.9c-1.7-1.8-3-4-3.8-6.4
              c-0.9-2.8-1.4-5.7-1.3-8.6c-0.1-3.8,0.7-7.5,2.2-10.9c1.4-2.9,3.6-5.3,6.4-6.8c3-1.6,6.4-2.4,9.9-2.3c3.3-0.1,6.6,0.7,9.5,2.3
              c2.8,1.6,5,4,6.4,6.9C302.4,882.5,303.2,886.2,303.1,890z M274.9,890c-0.1,2.5,0.3,4.9,1,7.3c1.5,4.9,6.7,7.6,11.5,6.1
              c2.9-0.9,5.2-3.2,6.1-6.1c0.7-2.4,1.1-4.8,1-7.3c0.1-2.4-0.3-4.9-1-7.2c-0.6-1.8-1.7-3.4-3.2-4.5c-1.7-1.1-3.6-1.6-5.6-1.6
              c-3.4,0-5.9,1.2-7.5,3.4S274.9,885.7,274.9,890L274.9,890z M347.1,890c0,2.9-0.4,5.8-1.3,8.6c-0.8,2.4-2,4.5-3.7,6.4
              c-1.6,1.7-3.6,3.1-5.8,3.9c-2.5,0.9-5.1,1.4-7.7,1.3c-2.5,0-5-0.4-7.4-1.3c-2.2-0.9-4.2-2.2-5.8-3.9c-1.7-1.8-3-4-3.8-6.4
              c-0.9-2.8-1.4-5.7-1.3-8.6c-0.1-3.8,0.7-7.5,2.2-10.9c1.4-2.9,3.6-5.3,6.4-6.8c3-1.6,6.4-2.4,9.9-2.3c3.3-0.1,6.6,0.7,9.5,2.3
              c2.8,1.6,5,4,6.4,6.9C346.4,882.5,347.2,886.2,347.1,890z M318.9,890c-0.1,2.5,0.3,4.9,1,7.3c0.6,1.8,1.7,3.4,3.2,4.6
              c4,3.1,9.8,2.3,12.9-1.7c0.7-0.9,1.2-1.9,1.5-2.9c0.7-2.4,1.1-4.8,1-7.3c0.1-2.4-0.3-4.9-1-7.2c-0.6-1.8-1.7-3.4-3.2-4.5
              c-1.7-1.1-3.6-1.6-5.6-1.6c-3.4,0-5.9,1.1-7.5,3.4C319.6,882.4,318.8,885.7,318.9,890z M371.1,903.6c1.1,0,2.1-0.1,3.2-0.3
              c1-0.2,1.9-0.4,2.9-0.7v6.3c-1.1,0.5-2.3,0.8-3.5,1c-1.5,0.3-3,0.4-4.5,0.4c-2,0-4.1-0.3-5.9-1.1c-1.8-0.8-3.3-2.1-4.2-3.8
              c-1.2-2.3-1.7-4.9-1.5-7.5v-20.9h-5.3v-3.7l5.7-3l2.7-8.3h5.2v8.7h11.1v6.3h-11.1v20.8c-0.1,1.6,0.4,3.2,1.5,4.4
              C368.2,903.1,369.6,903.6,371.1,903.6z M411.2,898.4c0.1,2.3-0.6,4.6-1.9,6.5c-1.4,1.9-3.3,3.3-5.4,4c-2.8,1-5.8,1.4-8.7,1.4
              c-2.3,0-4.7-0.2-7-0.6c-1.9-0.4-3.8-1-5.6-1.8v-7.1c2,0.9,4.1,1.7,6.3,2.3c2.1,0.6,4.3,0.9,6.6,1c2.7,0,4.6-0.4,5.9-1.3
              c1.2-0.8,1.8-2.1,1.8-3.4c0-0.8-0.2-1.6-0.7-2.3c-0.7-0.9-1.6-1.6-2.7-2.1c-1.9-1-3.8-1.8-5.8-2.5c-2.1-0.8-4.2-1.8-6.2-3
              c-1.6-0.9-2.9-2.1-3.8-3.6c-0.9-1.6-1.4-3.5-1.3-5.3c-0.2-3.2,1.4-6.2,4.1-7.9c2.7-1.8,6.4-2.7,10.9-2.7c2.3,0,4.5,0.2,6.8,0.7
              c2.1,0.5,4.2,1.2,6.2,2.1l-2.6,6.2c-1.7-0.7-3.5-1.4-5.3-1.9c-1.8-0.5-3.6-0.8-5.4-0.8c-1.7-0.1-3.4,0.3-4.9,1
              c-1,0.5-1.7,1.6-1.7,2.8c0,0.8,0.3,1.7,0.8,2.3c0.8,0.8,1.8,1.5,2.8,1.9c1.3,0.6,3.2,1.4,5.5,2.3c2.1,0.8,4.1,1.7,6,2.8
              c1.6,0.9,2.9,2.1,3.9,3.6C410.8,894.6,411.3,896.5,411.2,898.4z M434.6,903.6c1.1,0,2.1-0.1,3.2-0.3c1-0.2,1.9-0.4,2.9-0.7v6.3
              c-1.1,0.5-2.3,0.8-3.5,1c-1.5,0.3-3,0.4-4.5,0.4c-2,0-4.1-0.3-5.9-1.1c-1.8-0.8-3.3-2.1-4.2-3.8c-1.1-2.3-1.7-4.9-1.5-7.5v-20.9
              h-5.3v-3.7l5.7-3l2.7-8.3h5.2v8.7h11.1v6.3h-11.1v20.8c-0.1,1.6,0.4,3.2,1.5,4.4C431.7,903.1,433.1,903.6,434.6,903.6z
              M468.3,869.9c0.7,0,1.4,0,2.1,0.1c0.7,0.1,1.3,0.2,2,0.3l-0.8,7.8c-0.6-0.2-1.2-0.3-1.8-0.3c-0.6-0.1-1.3-0.1-1.9-0.1
              c-1.4,0-2.9,0.2-4.2,0.7c-2.8,1-4.9,3.1-6,5.8c-0.6,1.6-0.9,3.3-0.9,5.1v20.3h-8.3v-38.9h6.5l1.1,6.8h0.4c0.8-1.4,1.8-2.7,2.9-3.8
              c1.1-1.1,2.5-2.1,4-2.8C464.9,870.2,466.6,869.9,468.3,869.9z M494.9,869.9c4.9,0,8.6,1.1,11.2,3.3s3.8,5.6,3.8,10.2v26.2H504
              l-1.7-5.5h-0.3c-1,1.3-2.1,2.5-3.4,3.5c-1.2,0.9-2.5,1.6-4,2.1c-1.8,0.5-3.7,0.7-5.6,0.7c-2.2,0-4.3-0.4-6.3-1.3
              c-1.8-0.8-3.3-2.2-4.3-3.9c-1.1-2-1.6-4.4-1.6-6.7c0-4,1.5-7,4.5-9s7.5-3.1,13.6-3.3l6.7-0.3v-2c0-2.7-0.6-4.6-1.9-5.7
              c-1.5-1.2-3.4-1.8-5.3-1.7c-1.9,0-3.8,0.3-5.7,0.8c-1.8,0.6-3.6,1.2-5.3,2l-2.7-5.8c2.1-1.1,4.3-1.9,6.5-2.5
              C489.8,870.2,492.4,869.9,494.9,869.9z M501.6,890.9l-5,0.2c-4.1,0.1-7,0.8-8.7,2.1c-1.6,1.2-2.6,3.2-2.5,5.2
              c-0.1,1.6,0.5,3.2,1.7,4.2c1.3,0.9,2.9,1.4,4.5,1.3c2.6,0.1,5.1-0.8,7.1-2.5c1.9-1.6,2.8-4,2.8-7.2L501.6,890.9z M541.4,869.9
              c4.3-0.1,8.4,1.7,11.1,5.1c2.8,3.4,4.2,8.4,4.2,15c0.1,3.8-0.6,7.6-2,11.1c-1.1,2.8-3,5.1-5.5,6.8c-2.4,1.6-5.3,2.4-8.1,2.3
              c-1.7,0-3.5-0.2-5.1-0.8c-1.3-0.4-2.6-1.1-3.7-2c-1-0.8-1.9-1.7-2.6-2.7h-0.5c0.1,0.9,0.2,1.9,0.3,3.1s0.2,2.2,0.2,3.2v15.8h-8.3
              v-56.2h6.8l1.2,5.4h0.4c0.8-1.1,1.6-2.2,2.7-3.1c1.1-1,2.4-1.7,3.8-2.2C537.8,870.1,539.6,869.8,541.4,869.9z M539.2,876.7
              c-1.9-0.1-3.8,0.4-5.5,1.4c-1.4,1-2.5,2.4-3,4c-0.7,2.2-1,4.5-1,6.8v1.2c-0.1,2.5,0.3,4.9,0.9,7.3c0.5,1.8,1.5,3.4,3,4.6
              c1.7,1.1,3.7,1.7,5.7,1.6c1.8,0.1,3.6-0.5,5-1.7c1.4-1.2,2.5-2.9,3-4.7c0.7-2.3,1-4.8,1-7.2c0-4.2-0.7-7.4-2.2-9.8
              C544.6,877.7,541.9,876.4,539.2,876.7z"
        />
        <path
          id="pandas"
          d="M700.3,693.5c4.3-0.1,8.4,1.7,11.1,5.1c2.8,3.4,4.2,8.4,4.2,15c0.1,3.8-0.6,7.6-1.9,11.1c-1.1,2.8-3,5.1-5.4,6.8
              c-2.4,1.6-5.3,2.4-8.1,2.3c-1.7,0-3.5-0.2-5.1-0.8c-1.3-0.4-2.6-1.1-3.7-2c-1-0.8-1.8-1.7-2.6-2.7h-0.5c0.1,0.9,0.2,1.9,0.3,3.1
              s0.2,2.2,0.2,3.2v15.8h-8.3v-56.2h6.8l1.2,5.4h0.4c0.8-1.1,1.6-2.2,2.7-3.1c1.1-1,2.4-1.7,3.8-2.2
              C696.7,693.7,698.5,693.5,700.3,693.5z M698.1,700.3c-1.9-0.1-3.8,0.4-5.5,1.3c-1.4,1-2.5,2.4-3,4c-0.7,2.2-1,4.5-1,6.8v1.2
              c-0.1,2.5,0.2,4.9,0.9,7.3c0.5,1.8,1.5,3.4,3,4.6c1.7,1.1,3.7,1.7,5.7,1.6c1.8,0.1,3.6-0.5,5-1.7c1.4-1.3,2.5-2.9,3-4.7
              c0.7-2.3,1-4.8,1-7.2c0-4.2-0.7-7.5-2.2-9.8C703.5,701.4,700.8,700.1,698.1,700.3z M740.4,693.5c4.9,0,8.6,1.1,11.2,3.3
              c2.5,2.2,3.8,5.6,3.8,10.2v26.2h-5.9l-1.6-5.5h-0.3c-1,1.3-2.1,2.5-3.4,3.5c-1.2,0.9-2.5,1.6-4,2.1c-1.8,0.5-3.7,0.7-5.6,0.7
              c-2.2,0-4.3-0.4-6.3-1.3c-1.8-0.8-3.3-2.2-4.3-3.9c-1.1-2-1.6-4.4-1.6-6.7c0-4,1.5-7,4.5-9s7.5-3.1,13.6-3.3l6.7-0.3v-2
              c0-2.7-0.6-4.6-1.9-5.7c-1.5-1.2-3.4-1.8-5.3-1.7c-1.9,0-3.8,0.3-5.7,0.8c-1.8,0.5-3.6,1.2-5.3,2l-2.7-5.8c2.1-1.1,4.3-1.9,6.5-2.5
              C735.3,693.8,737.8,693.5,740.4,693.5z M747.1,714.6l-5,0.2c-4.1,0.1-7,0.8-8.7,2.1c-1.6,1.2-2.6,3.1-2.5,5.2
              c-0.1,1.6,0.5,3.2,1.7,4.2c1.3,0.9,2.9,1.4,4.5,1.3c2.6,0.1,5.1-0.8,7.1-2.4c1.9-1.6,2.8-4,2.8-7.2L747.1,714.6z M787.2,693.5
              c4.4,0,7.8,1.1,10.3,3.4c2.5,2.3,3.7,5.9,3.7,10.9v25.4h-8.3v-23.8c0-3-0.6-5.3-1.9-6.8c-1.3-1.5-3.2-2.3-5.8-2.3
              c-3.8,0-6.4,1.2-7.9,3.5c-1.5,2.3-2.2,5.7-2.2,10v19.3h-8.3v-38.9h6.5l1.2,5.3h0.5c0.8-1.3,1.9-2.5,3.1-3.4c1.3-0.9,2.7-1.6,4.2-2
              C783.9,693.7,785.5,693.5,787.2,693.5z M825.7,733.9c-4.3,0.1-8.4-1.7-11.1-5.1c-2.8-3.4-4.2-8.4-4.2-15s1.4-11.7,4.3-15.2
              c2.7-3.4,6.9-5.3,11.3-5.1c1.7,0,3.5,0.2,5.1,0.8c1.4,0.5,2.7,1.2,3.8,2.1c1,0.9,2,1.9,2.7,3h0.4c-0.1-0.7-0.3-1.8-0.4-3.2
              c-0.1-1.3-0.2-2.5-0.2-3.8v-14h8.3v54.7h-6.5l-1.5-5.3h-0.3c-0.7,1.1-1.6,2.1-2.7,3c-1.1,0.9-2.4,1.7-3.8,2.2
              C829.2,733.7,827.5,733.9,825.7,733.9z M828,727.2c3.5,0,6-1,7.5-3.1c1.5-2,2.2-5.1,2.2-9.2v-1.1c0-4.4-0.7-7.7-2.1-10
              c-1.4-2.3-4-3.5-7.7-3.5c-2.7-0.1-5.3,1.3-6.8,3.6c-1.6,2.4-2.3,5.7-2.3,10s0.8,7.5,2.3,9.8C822.7,726,825.3,727.3,828,727.2
              L828,727.2z M872.7,693.5c4.9,0,8.6,1.1,11.2,3.3c2.5,2.2,3.8,5.6,3.8,10.2v26.2h-5.9l-1.6-5.5h-0.3c-1,1.3-2.1,2.5-3.4,3.5
              c-1.2,0.9-2.5,1.6-4,2.1c-1.8,0.5-3.7,0.7-5.6,0.7c-2.2,0-4.3-0.4-6.3-1.3c-1.8-0.8-3.3-2.2-4.3-3.9c-1.1-2-1.6-4.4-1.6-6.7
              c0-4,1.5-7,4.5-9c3-2,7.5-3.1,13.6-3.3l6.7-0.3v-2c0-2.7-0.6-4.6-1.9-5.7c-1.5-1.2-3.4-1.8-5.3-1.7c-1.9,0-3.8,0.3-5.7,0.8
              c-1.8,0.5-3.6,1.2-5.3,2l-2.7-5.9c2.1-1.1,4.3-1.9,6.5-2.5C867.6,693.8,870.2,693.5,872.7,693.5z M879.4,714.6l-5,0.2
              c-4.1,0.1-7,0.8-8.7,2.1c-1.6,1.2-2.6,3.1-2.5,5.2c-0.1,1.6,0.5,3.2,1.7,4.2c1.3,0.9,2.9,1.4,4.5,1.3c2.6,0.1,5.1-0.8,7.1-2.4
              c1.9-1.6,2.8-4,2.8-7.2L879.4,714.6z M925.4,722.1c0.1,2.3-0.6,4.6-1.9,6.5c-1.4,1.9-3.3,3.2-5.4,4c-2.8,1-5.8,1.4-8.7,1.3
              c-2.3,0-4.7-0.2-7-0.6c-1.9-0.4-3.8-1-5.6-1.8v-7.1c2,0.9,4.1,1.7,6.3,2.3c2.1,0.6,4.3,0.9,6.6,1c2.7,0,4.6-0.4,5.8-1.3
              c1.2-0.8,1.8-2.1,1.8-3.4c0-0.8-0.2-1.6-0.7-2.3c-0.7-0.9-1.6-1.6-2.7-2.1c-1.9-1-3.8-1.8-5.8-2.5c-2.1-0.8-4.2-1.8-6.2-3
              c-1.5-0.9-2.9-2.1-3.8-3.6c-0.9-1.6-1.4-3.5-1.3-5.3c-0.2-3.2,1.4-6.2,4.1-7.9c2.7-1.8,6.4-2.7,10.9-2.7c2.3,0,4.5,0.2,6.8,0.7
              c2.1,0.5,4.2,1.2,6.2,2.1l-2.6,6.2c-1.7-0.7-3.5-1.4-5.3-1.9c-1.8-0.5-3.6-0.8-5.4-0.8c-1.7-0.1-3.4,0.2-4.9,1
              c-1,0.5-1.7,1.6-1.7,2.8c0,0.8,0.3,1.7,0.8,2.3c0.8,0.8,1.7,1.5,2.8,1.9c1.3,0.6,3.2,1.4,5.5,2.4c2.1,0.8,4.1,1.7,6,2.8
              c1.6,0.9,2.9,2.1,3.9,3.6C925,718.3,925.5,720.1,925.4,722.1z"
        />
        <path
          id="animation"
          d="M65.4,804.7L57,780.9H24.2l-8.4,23.8H1l31.9-86h15.6l31.8,86H65.4z M53.4,768.9l-8.2-23.5c-0.3-1-0.8-2.5-1.3-4.3
              s-1.2-3.7-1.8-5.7s-1.1-3.6-1.5-4.9c-0.4,1.6-0.9,3.4-1.4,5.4c-0.6,2-1.1,3.8-1.6,5.6l-1.2,4l-8.2,23.5L53.4,768.9z M124,738.6
              c7.3,0,13,1.9,17.1,5.7c4.1,3.8,6.1,9.8,6.1,18.1v42.3h-13.8v-39.7c0-5-1-8.8-3.1-11.3s-5.3-3.8-9.6-3.8c-6.3,0-10.7,1.9-13.1,5.8
              c-2.4,3.9-3.7,9.5-3.7,16.8v32.2H90.1v-64.9h10.8l1.9,8.8h0.8c1.4-2.2,3.1-4.1,5.3-5.6c2.1-1.5,4.5-2.6,7-3.3
              C118.5,738.9,121.2,738.5,124,738.6z M173.4,715c2,0,3.9,0.5,5.5,1.7c1.5,1.1,2.3,3.1,2.3,5.9c0,2.8-0.8,4.7-2.3,5.9
              c-3.3,2.3-7.7,2.3-11,0c-1.5-1.2-2.3-3.1-2.3-5.9s0.8-4.7,2.3-5.9C169.5,715.5,171.4,714.9,173.4,715z M180.2,739.8v64.9h-13.8
              v-64.9H180.2z M273.2,738.6c7.3,0,12.8,1.9,16.4,5.6c3.7,3.7,5.5,9.7,5.5,18v42.4h-13.8v-39.9c0-5-1-8.7-2.9-11.2s-4.8-3.7-8.7-3.7
              c-5.5,0-9.4,1.7-11.8,5.2s-3.6,8.5-3.6,15.2v34.4h-13.8v-39.9c0.1-2.8-0.3-5.6-1.3-8.3c-0.7-2-2-3.8-3.8-5c-2-1.2-4.2-1.7-6.5-1.6
              c-3.8,0-6.8,0.8-9,2.5c-2.4,1.9-4.1,4.6-4.8,7.5c-1.1,4-1.6,8.1-1.5,12.3v32.4h-13.8v-64.8h10.8l1.9,8.7h0.8c1.3-2.2,3-4.1,5-5.6
              c2-1.5,4.2-2.6,6.6-3.3c2.5-0.7,5-1.1,7.6-1.1c4.2-0.1,8.4,0.7,12.3,2.5c3.3,1.7,5.9,4.4,7.4,7.8h1c1.9-3.4,4.9-6.2,8.5-7.8
              C265.3,739.4,269.2,738.6,273.2,738.6z M339.8,738.6c8.2,0,14.4,1.8,18.6,5.5c4.2,3.6,6.3,9.3,6.3,17v43.7h-9.8l-2.7-9.2h-0.5
              c-1.7,2.2-3.6,4.1-5.7,5.8c-2,1.6-4.3,2.7-6.7,3.4c-3,0.8-6.2,1.2-9.3,1.1c-3.6,0.1-7.2-0.7-10.4-2.1c-3-1.4-5.5-3.7-7.2-6.5
              c-1.9-3.4-2.8-7.3-2.6-11.1c0-6.7,2.5-11.7,7.5-15.1c5-3.4,12.5-5.2,22.6-5.6L351,765v-3.4c0-4.5-1-7.6-3.1-9.5
              c-2.1-1.9-5-2.9-8.8-2.9c-3.2,0-6.4,0.5-9.4,1.4c-3,0.9-6,2.1-8.9,3.4l-4.5-9.7c3.5-1.8,7.1-3.2,10.9-4.1
              C331.3,739.1,335.5,738.6,339.8,738.6z M351,773.7l-8.3,0.3c-6.9,0.2-11.7,1.4-14.5,3.5c-2.7,2-4.3,5.3-4.2,8.7c0,3.2,1,5.5,2.9,7
              c2.2,1.6,4.9,2.3,7.6,2.2c4.3,0.2,8.5-1.3,11.8-4.1c3.1-2.7,4.7-6.7,4.7-11.9L351,773.7z M408.1,794.7c1.8,0,3.6-0.2,5.3-0.5
              c1.6-0.3,3.2-0.7,4.8-1.2v10.4c-1.9,0.8-3.9,1.4-5.9,1.7c-2.5,0.5-5,0.7-7.6,0.7c-3.4,0.1-6.8-0.6-9.9-1.8c-3-1.3-5.5-3.5-7-6.4
              c-1.7-3-2.6-7.2-2.6-12.5v-34.8h-8.9v-6.2l9.5-4.9l4.5-13.9h8.7v14.4h18.6v10.5h-18.5V785c0,3.3,0.8,5.7,2.5,7.3
              C403.4,794,405.7,794.8,408.1,794.7z M438.4,715c2,0,3.9,0.5,5.5,1.7c1.5,1.1,2.3,3.1,2.3,5.9c0,2.8-0.8,4.7-2.3,5.9
              c-3.3,2.3-7.7,2.3-11,0c-1.5-1.2-2.3-3.1-2.3-5.9s0.8-4.7,2.3-5.9C434.4,715.5,436.4,714.9,438.4,715z M445.2,739.8v64.9h-13.8
              v-64.9H445.2z M522.3,772.1c0.1,4.9-0.6,9.7-2.1,14.4c-1.3,3.9-3.4,7.6-6.2,10.6c-2.7,2.9-6,5.1-9.7,6.6c-4.1,1.5-8.5,2.3-12.8,2.2
              c-4.2,0-8.3-0.7-12.2-2.2c-3.7-1.4-6.9-3.7-9.6-6.6c-2.8-3.1-5-6.7-6.3-10.6c-1.6-4.6-2.3-9.5-2.2-14.4c0-7.1,1.2-13.2,3.7-18.2
              c2.3-4.8,6-8.8,10.6-11.4c5.1-2.7,10.7-4.1,16.5-3.9c5.5-0.1,11,1.2,15.8,3.9c4.6,2.7,8.4,6.6,10.7,11.4
              C521,758.9,522.3,765,522.3,772.1z M475.3,772.1c-0.1,4.1,0.5,8.2,1.7,12.1c2.5,8.1,11.1,12.7,19.2,10.2c4.9-1.5,8.7-5.3,10.2-10.2
              c1.2-3.9,1.8-8,1.7-12.1c0.1-4.1-0.5-8.1-1.7-12c-1-3-2.8-5.7-5.4-7.6c-2.8-1.8-6-2.7-9.4-2.6c-5.7,0-9.9,1.9-12.5,5.7
              S475.3,765,475.3,772.1L475.3,772.1z M571.9,738.6c7.3,0,13,1.9,17.1,5.7s6.1,9.8,6.1,18.1v42.3h-13.8v-39.7c0-5-1-8.8-3.1-11.3
              s-5.3-3.8-9.6-3.8c-6.3,0-10.6,1.9-13.1,5.8c-2.4,3.9-3.7,9.5-3.7,16.8v32.2h-13.8v-64.9h10.8l1.9,8.8h0.8c1.4-2.2,3.1-4.1,5.3-5.6
              c2.1-1.5,4.5-2.6,7-3.3C566.4,738.9,569.2,738.5,571.9,738.6z"
        />
        <path
          id="react"
          d="M896.3,576.5c3.7-0.1,7.5,0.4,11,1.6c2.6,0.9,4.9,2.6,6.5,4.9c1.5,2.5,2.2,5.4,2.1,8.4c0.1,2.2-0.4,4.5-1.4,6.5
              c-0.9,1.7-2.2,3.3-3.7,4.5c-1.5,1.2-3.1,2.1-4.9,2.8l14.6,22.7H911l-12.5-20.6h-8.2v20.6h-8.4v-51.4H896.3z M895.7,583.6h-5.5v16.8
              h5.8c3.9,0,6.7-0.7,8.5-2.2c1.8-1.6,2.8-4,2.7-6.4c0-3-1-5-2.8-6.3S899.6,583.6,895.7,583.6z M942.5,588.2c3.1-0.1,6.2,0.6,8.9,2.1
              c2.5,1.4,4.5,3.5,5.7,6.1c1.4,2.9,2.1,6.2,2,9.4v4.4h-26c0.1,3.8,1.1,6.7,3,8.7s4.7,3.1,8.1,3.1c2.3,0,4.5-0.2,6.7-0.7
              c2.1-0.5,4.2-1.2,6.1-2.1v6.7c-1.9,0.9-3.9,1.6-5.9,2c-2.4,0.5-4.8,0.7-7.2,0.6c-3.5,0.1-7-0.7-10.1-2.2c-2.9-1.5-5.3-3.8-6.8-6.6
              c-1.7-3.4-2.5-7.2-2.4-11c-0.1-3.8,0.6-7.6,2.2-11.1c1.3-2.9,3.5-5.3,6.2-7C935.9,589,939.2,588.1,942.5,588.2z M942.5,594.5
              c-2.4-0.1-4.7,0.8-6.3,2.5c-1.6,1.7-2.5,4.2-2.8,7.4H951c0-1.8-0.3-3.5-1-5.2c-0.6-1.4-1.5-2.6-2.8-3.5
              C945.8,594.8,944.2,594.4,942.5,594.5L942.5,594.5z M983.7,588.2c4.9,0,8.7,1.1,11.2,3.3c2.5,2.2,3.8,5.6,3.8,10.2v26.2h-5.9
              l-1.6-5.5h-0.3c-1,1.3-2.1,2.5-3.4,3.5c-1.2,0.9-2.5,1.6-4,2.1c-1.8,0.5-3.7,0.7-5.6,0.7c-2.2,0-4.3-0.4-6.3-1.3
              c-1.8-0.8-3.3-2.2-4.3-3.9c-1.1-2-1.7-4.4-1.6-6.7c0-4,1.5-7,4.5-9s7.5-3.1,13.5-3.3l6.7-0.3v-2c0-2.7-0.6-4.6-1.9-5.7
              c-1.5-1.2-3.4-1.8-5.3-1.7c-1.9,0-3.8,0.3-5.7,0.8c-1.8,0.6-3.6,1.2-5.3,2l-2.7-5.8c2.1-1.1,4.3-1.9,6.5-2.5
              C978.6,588.6,981.2,588.2,983.7,588.2z M990.5,609.3l-5,0.2c-4.1,0.1-7,0.8-8.7,2.1c-1.6,1.2-2.6,3.1-2.5,5.2
              c-0.1,1.6,0.5,3.2,1.7,4.2c1.3,0.9,2.9,1.4,4.5,1.3c2.6,0.1,5.1-0.8,7.1-2.4c1.9-1.6,2.8-4,2.8-7.2L990.5,609.3z M1025.9,628.6
              c-3.3,0.1-6.6-0.6-9.6-2.1c-2.7-1.5-4.9-3.8-6.2-6.6c-1.6-3.5-2.3-7.4-2.2-11.3c0-4.7,0.8-8.6,2.4-11.6c1.4-2.9,3.8-5.2,6.6-6.7
              c3-1.5,6.4-2.2,9.7-2.1c2.1,0,4.2,0.2,6.3,0.7c1.7,0.4,3.3,0.9,4.8,1.7l-2.5,6.6c-1.4-0.6-2.9-1.1-4.4-1.5
              c-1.4-0.4-2.9-0.6-4.3-0.6c-2-0.1-4,0.5-5.7,1.5c-1.6,1.1-2.8,2.7-3.4,4.5c-0.8,2.4-1.2,4.9-1.1,7.4c-0.1,2.5,0.3,4.9,1.2,7.3
              c0.6,1.8,1.8,3.3,3.3,4.4c1.6,1,3.5,1.6,5.5,1.5c1.9,0,3.9-0.2,5.7-0.8c1.7-0.5,3.3-1.2,4.8-2v7.2c-1.5,0.8-3.1,1.5-4.8,1.9
              C1030.1,628.4,1028,628.6,1025.9,628.6z M1060.5,621.9c1.1,0,2.1-0.1,3.2-0.3c1-0.2,1.9-0.4,2.9-0.7v6.3c-1.1,0.5-2.3,0.8-3.5,1
              c-1.5,0.3-3,0.4-4.5,0.4c-2.1,0-4.1-0.3-6-1.1c-1.8-0.8-3.3-2.1-4.2-3.8c-1.1-2.3-1.7-4.9-1.5-7.5v-20.9h-5.3v-3.7l5.7-3l2.7-8.3
              h5.2v8.7h11.2v6.3h-11.1v20.8c-0.1,1.6,0.4,3.2,1.5,4.4C1057.6,621.5,1059,622,1060.5,621.9z"
        />
        <path
          id="sharepoint"
          d="M456.1,615.5c0.1,2.8-0.7,5.5-2.2,7.9c-1.6,2.3-3.8,4-6.4,5.1c-3.2,1.2-6.5,1.8-9.9,1.8c-1.8,0-3.6-0.1-5.4-0.3
              c-1.6-0.2-3.3-0.5-4.8-0.9c-1.4-0.4-2.8-0.9-4.2-1.5v-7.9c2.3,1,4.6,1.8,7,2.5c2.6,0.7,5.2,1.1,7.9,1.1c1.8,0.1,3.7-0.2,5.4-0.9
              c1.3-0.5,2.4-1.3,3.2-2.5c0.7-1.1,1.1-2.4,1.1-3.7c0-1.4-0.4-2.7-1.2-3.8c-1-1.2-2.3-2.2-3.7-2.9c-2-1.1-4.1-2-6.2-2.8
              c-1.7-0.6-3.3-1.4-4.8-2.3c-1.5-0.8-2.9-1.8-4.1-3c-1.2-1.2-2.2-2.6-2.8-4.2c-0.7-1.8-1.1-3.7-1-5.7c-0.1-2.6,0.7-5.3,2.1-7.5
              c1.5-2.1,3.5-3.8,5.9-4.8c2.8-1.2,5.9-1.7,8.9-1.7c2.6,0,5.1,0.3,7.6,0.9c2.4,0.6,4.7,1.4,7,2.4l-2.7,6.8c-2-0.8-4.1-1.5-6.2-2.1
              c-2-0.5-4-0.8-6-0.8c-1.6,0-3.1,0.2-4.5,0.8c-1.1,0.5-2.1,1.3-2.8,2.3c-0.7,1-1,2.2-1,3.5c-0.1,1.3,0.3,2.7,1.1,3.8
              c0.9,1.2,2.1,2.1,3.5,2.8c1.6,0.8,3.6,1.8,6,2.8c2.5,1,4.9,2.2,7.2,3.7c1.9,1.2,3.4,2.8,4.6,4.6
              C455.6,610.9,456.2,613.2,456.1,615.5z M473.4,574.8v13.7c0,1.4,0,2.8-0.1,4.2s-0.2,2.4-0.3,3.2h0.5c0.8-1.3,1.8-2.4,3-3.4
              c1.2-0.9,2.6-1.6,4-2c1.5-0.5,3.1-0.7,4.8-0.7c2.6-0.1,5.2,0.4,7.6,1.5c2.1,1,3.7,2.6,4.8,4.6c1.2,2.5,1.8,5.3,1.7,8.1v25.4h-8.3
              v-23.8c0-3-0.6-5.3-1.9-6.8s-3.2-2.3-5.7-2.3c-2.1-0.1-4.2,0.4-6,1.6c-1.5,1.1-2.6,2.8-3.2,4.6c-0.7,2.4-1,4.9-1,7.4v19.3H465
              v-54.7L473.4,574.8z M526.2,589.8c4.9,0,8.7,1.1,11.2,3.3s3.8,5.6,3.8,10.2v26.2h-5.9l-1.6-5.5h-0.3c-1,1.3-2.1,2.5-3.4,3.5
              c-1.2,0.9-2.6,1.6-4,2.1c-1.8,0.5-3.7,0.7-5.6,0.7c-2.2,0-4.3-0.4-6.3-1.3c-1.8-0.8-3.3-2.2-4.3-3.9c-1.1-2-1.7-4.4-1.6-6.7
              c0-4,1.5-7,4.5-9c3-2,7.5-3.1,13.5-3.3l6.8-0.2v-2c0-2.7-0.6-4.6-1.9-5.7c-1.5-1.2-3.4-1.8-5.3-1.7c-1.9,0-3.8,0.3-5.7,0.8
              c-1.8,0.6-3.6,1.2-5.3,2l-2.7-5.8c2.1-1.1,4.3-1.9,6.5-2.5C521.1,590.2,523.6,589.8,526.2,589.8z M532.9,610.8l-5,0.2
              c-4.1,0.2-7,0.8-8.7,2.1c-1.6,1.2-2.6,3.1-2.5,5.2c-0.1,1.6,0.5,3.2,1.7,4.2c1.3,0.9,2.9,1.4,4.5,1.3c2.6,0.1,5.1-0.8,7.1-2.4
              c1.9-1.6,2.8-4,2.8-7.2L532.9,610.8z M572.4,589.8c0.7,0,1.4,0,2.1,0.1c0.7,0.1,1.3,0.1,2,0.3l-0.8,7.8c-0.6-0.2-1.2-0.3-1.8-0.3
              c-0.6-0.1-1.3-0.1-1.9-0.1c-1.4,0-2.9,0.2-4.2,0.7c-1.3,0.5-2.5,1.2-3.6,2.2c-1.1,1-1.9,2.3-2.4,3.6c-0.6,1.6-0.9,3.3-0.9,5.1v20.3
              h-8.3v-38.9h6.5l1.1,6.9h0.4c0.8-1.4,1.8-2.7,2.9-3.8c1.1-1.1,2.5-2.1,4-2.8C569,590.2,570.7,589.8,572.4,589.8z M599.3,589.8
              c3.1-0.1,6.2,0.6,8.9,2.1c2.5,1.4,4.5,3.5,5.7,6.1c1.4,2.9,2.1,6.2,2,9.4v4.4h-26c0.1,3.8,1.1,6.7,3,8.7s4.7,3.1,8.1,3.1
              c2.3,0,4.5-0.2,6.7-0.7c2.1-0.5,4.2-1.2,6.1-2.1v6.7c-1.9,0.9-3.9,1.6-5.9,2c-2.4,0.5-4.8,0.7-7.2,0.6c-3.5,0.1-7-0.7-10.1-2.2
              c-2.9-1.5-5.3-3.8-6.8-6.6c-1.7-3.4-2.5-7.2-2.4-11c-0.1-3.8,0.6-7.6,2.2-11.1c1.3-2.9,3.5-5.3,6.2-7
              C592.7,590.6,596,589.7,599.3,589.8z M599.3,596c-2.4-0.1-4.7,0.8-6.3,2.5c-1.6,1.7-2.5,4.2-2.8,7.4h17.7c0-1.8-0.3-3.5-1-5.2
              c-0.6-1.4-1.5-2.6-2.8-3.5C602.6,596.4,600.9,596,599.3,596L599.3,596z M641,578.1c6.6,0,11.5,1.4,14.5,4.1c3,2.7,4.6,6.5,4.6,11.5
              c0,2.1-0.3,4.3-1,6.3c-0.7,2-1.9,3.8-3.4,5.3c-1.8,1.7-3.9,2.9-6.2,3.7c-3.1,1-6.3,1.4-9.5,1.3h-5.5v19.2h-8.4v-51.4H641z
              M640.5,585.1h-5.9v18.1h4.6c2.3,0.1,4.6-0.3,6.7-0.9c1.7-0.5,3.2-1.6,4.2-3c1-1.6,1.5-3.5,1.4-5.3c0-3-0.9-5.2-2.7-6.7
              S644.2,585.1,640.5,585.1L640.5,585.1z M704,609.9c0,2.9-0.4,5.8-1.3,8.6c-0.8,2.4-2,4.5-3.7,6.4c-1.6,1.7-3.6,3.1-5.8,3.9
              c-2.5,0.9-5.1,1.4-7.7,1.3c-2.5,0-5-0.4-7.3-1.3c-2.2-0.9-4.2-2.2-5.8-3.9c-1.7-1.8-3-4-3.8-6.4c-0.9-2.8-1.4-5.7-1.3-8.6
              c-0.1-3.8,0.7-7.5,2.2-10.9c1.4-2.9,3.6-5.3,6.4-6.8c3-1.6,6.4-2.4,9.9-2.3c3.3-0.1,6.6,0.7,9.5,2.3c2.8,1.6,5,4,6.4,6.9
              C703.2,602.4,704,606.2,704,609.9z M675.7,609.9c-0.1,2.5,0.3,4.9,1,7.3c1.5,4.9,6.7,7.6,11.5,6.1c2.9-0.9,5.2-3.2,6.1-6.1
              c0.7-2.4,1.1-4.8,1-7.3c0.1-2.4-0.3-4.9-1-7.2c-0.6-1.8-1.7-3.4-3.2-4.5c-1.7-1.1-3.6-1.6-5.6-1.6c-3.4,0-5.9,1.1-7.5,3.4
              S675.7,605.7,675.7,609.9z M717.6,575.7c1.2,0,2.3,0.3,3.3,1c1,0.9,1.5,2.2,1.4,3.5c0.1,1.3-0.4,2.6-1.4,3.5c-2,1.4-4.6,1.4-6.6,0
              c-1-0.9-1.5-2.2-1.4-3.5c-0.1-1.3,0.4-2.6,1.4-3.5C715.3,576,716.5,575.7,717.6,575.7z M721.7,590.6v38.9h-8.3v-39L721.7,590.6z
              M753.8,589.8c4.4,0,7.8,1.1,10.3,3.4s3.7,5.9,3.7,10.9v25.4h-8.3v-23.8c0-3-0.6-5.3-1.9-6.8c-1.2-1.5-3.2-2.3-5.8-2.3
              c-3.8,0-6.4,1.2-7.8,3.5c-1.5,2.3-2.2,5.7-2.2,10.1v19.3h-8.3v-38.9h6.5l1.2,5.3h0.5c0.8-1.3,1.9-2.5,3.1-3.4
              c1.3-0.9,2.7-1.6,4.2-2C750.5,590,752.2,589.8,753.8,589.8z M793.7,623.5c1.1,0,2.1-0.1,3.2-0.3c1-0.2,1.9-0.4,2.9-0.7v6.2
              c-1.1,0.5-2.3,0.8-3.5,1c-1.5,0.3-3,0.4-4.5,0.4c-2,0-4.1-0.3-6-1.1c-1.8-0.8-3.3-2.1-4.2-3.8c-1.1-2.3-1.7-4.9-1.5-7.5v-20.8h-5.3
              v-3.7l5.7-3l2.7-8.3h5.2v8.6h11.1v6.3h-11.1v20.9c-0.1,1.6,0.4,3.2,1.5,4.4C790.9,623.1,792.3,623.6,793.7,623.5z"
        />
        <path
          id="illustrator"
          d="M7.8,629.9v-51.4h8.4v51.4H7.8z M37.2,629.9h-8.3v-54.7h8.3V629.9z M57.3,629.9h-8.3v-54.7h8.3V629.9z M103,591v38.9h-6.5
              l-1.2-5.2h-0.4c-0.8,1.3-1.9,2.4-3.1,3.3c-1.3,0.9-2.7,1.6-4.2,2c-1.6,0.4-3.2,0.6-4.9,0.6c-2.6,0.1-5.1-0.4-7.5-1.5
              c-2.1-1-3.7-2.6-4.8-4.6c-1.2-2.5-1.8-5.3-1.7-8.1V591H77v23.9c0,3,0.6,5.3,1.8,6.8s3.1,2.3,5.8,2.3c2.1,0.1,4.2-0.4,6-1.5
              c1.5-1.1,2.6-2.7,3.1-4.6c0.7-2.4,1-4.9,0.9-7.4V591H103z M140.9,618.8c0.1,2.3-0.6,4.6-1.9,6.5c-1.4,1.9-3.3,3.2-5.4,4
              c-2.8,1-5.8,1.4-8.7,1.3c-2.3,0-4.7-0.2-7-0.6c-1.9-0.4-3.8-1-5.6-1.8v-7.1c2,0.9,4.1,1.7,6.3,2.3c2.1,0.6,4.3,1,6.6,1
              c2.7,0,4.6-0.4,5.9-1.3c1.2-0.8,1.8-2.1,1.8-3.4c0-0.8-0.2-1.6-0.7-2.3c-0.7-0.9-1.6-1.6-2.7-2.1c-1.9-1-3.8-1.8-5.8-2.5
              c-2.1-0.8-4.2-1.8-6.2-3c-1.5-0.9-2.9-2.1-3.8-3.6c-0.9-1.6-1.4-3.5-1.3-5.3c-0.2-3.2,1.4-6.2,4.1-7.9c2.7-1.8,6.4-2.7,10.9-2.7
              c2.3,0,4.5,0.2,6.8,0.7c2.1,0.5,4.2,1.2,6.2,2.1l-2.6,6.2c-1.7-0.7-3.5-1.4-5.3-1.9c-1.8-0.5-3.6-0.8-5.4-0.8
              c-1.7-0.1-3.4,0.2-4.9,1c-1,0.5-1.7,1.6-1.7,2.8c0,0.8,0.3,1.7,0.8,2.3c0.8,0.8,1.7,1.5,2.8,1.9c1.3,0.6,3.2,1.4,5.5,2.4
              c2.1,0.8,4.1,1.7,6,2.8c1.6,0.9,2.9,2.1,3.9,3.6C140.5,615,141,616.9,140.9,618.8z M164.3,623.9c1.1,0,2.1-0.1,3.2-0.3
              c1-0.2,1.9-0.4,2.9-0.7v6.3c-1.1,0.5-2.3,0.8-3.6,1c-1.5,0.3-3,0.4-4.5,0.4c-2,0-4.1-0.3-5.9-1.1c-1.8-0.8-3.3-2.1-4.2-3.8
              c-1.1-2.3-1.7-4.9-1.6-7.5v-20.9h-5.3v-3.7l5.7-3l2.7-8.3h5.2v8.7h11.1v6.3h-11.2v20.8c-0.1,1.6,0.4,3.2,1.5,4.4
              C161.5,623.5,162.9,624,164.3,623.9z M198,590.2c0.7,0,1.4,0,2.1,0.1c0.7,0.1,1.3,0.1,2,0.3l-0.8,7.8c-0.6-0.2-1.2-0.3-1.8-0.3
              c-0.6-0.1-1.3-0.1-1.9-0.1c-1.4,0-2.9,0.2-4.2,0.7c-1.3,0.5-2.5,1.2-3.6,2.2c-1.1,1-1.9,2.3-2.4,3.6c-0.6,1.6-0.9,3.3-0.9,5.1v20.3
              h-8.3V591h6.5l1.1,6.9h0.4c0.8-1.4,1.8-2.7,2.9-3.8c1.1-1.1,2.5-2.1,3.9-2.8C194.6,590.6,196.3,590.2,198,590.2z M224.6,590.2
              c4.9,0,8.6,1.1,11.2,3.3c2.5,2.2,3.8,5.6,3.8,10.2v26.2h-5.9l-1.6-5.5h-0.3c-1,1.3-2.1,2.5-3.4,3.5c-1.2,0.9-2.5,1.6-4,2.1
              c-1.8,0.5-3.7,0.7-5.6,0.7c-2.2,0-4.3-0.4-6.3-1.3c-1.8-0.8-3.3-2.2-4.3-3.9c-1.1-2-1.6-4.4-1.6-6.7c0-4,1.5-7,4.5-9
              s7.5-3.1,13.5-3.3l6.7-0.3v-2c0-2.7-0.6-4.6-1.9-5.7c-1.5-1.2-3.4-1.8-5.3-1.7c-1.9,0-3.8,0.3-5.7,0.8c-1.8,0.5-3.6,1.2-5.3,2
              l-2.7-5.8c2.1-1.1,4.3-1.9,6.5-2.5C219.6,590.6,222.1,590.2,224.6,590.2z M231.4,611.3l-5,0.2c-4.1,0.1-7,0.8-8.7,2.1
              c-1.6,1.2-2.6,3.1-2.5,5.2c-0.1,1.6,0.5,3.2,1.7,4.2c1.3,0.9,2.9,1.4,4.5,1.3c2.6,0.1,5.1-0.8,7.1-2.4c1.9-1.6,2.8-4,2.8-7.2
              L231.4,611.3z M265.6,623.9c1.1,0,2.1-0.1,3.2-0.3c1-0.2,1.9-0.4,2.9-0.7v6.3c-1.1,0.5-2.3,0.8-3.5,1c-1.5,0.3-3,0.4-4.5,0.4
              c-2,0-4.1-0.3-6-1.1c-1.8-0.8-3.3-2.1-4.2-3.8c-1.1-2.3-1.7-4.9-1.5-7.5v-20.9h-5.3v-3.7l5.7-2.9l2.7-8.3h5.2v8.7h11.1v6.3h-11.1
              v20.8c-0.1,1.6,0.4,3.2,1.5,4.4C262.8,623.5,264.2,624,265.6,623.9z M314.1,610.3c0,2.9-0.4,5.8-1.3,8.6c-0.8,2.4-2,4.5-3.7,6.4
              c-1.6,1.7-3.6,3.1-5.8,3.9c-2.5,0.9-5.1,1.4-7.7,1.3c-2.5,0-5-0.4-7.3-1.3c-2.2-0.9-4.2-2.2-5.8-3.9c-1.7-1.8-3-4-3.8-6.4
              c-0.9-2.8-1.4-5.7-1.3-8.6c-0.1-3.8,0.7-7.5,2.2-10.9c1.4-2.9,3.6-5.3,6.4-6.8c3-1.6,6.4-2.5,9.9-2.4c3.3-0.1,6.6,0.7,9.5,2.4
              c2.8,1.6,5,4,6.4,6.9C313.4,602.8,314.2,606.6,314.1,610.3z M285.8,610.3c-0.1,2.5,0.3,4.9,1,7.3c1.5,4.9,6.7,7.6,11.5,6.1
              c2.9-0.9,5.2-3.2,6.1-6.1c0.7-2.4,1.1-4.8,1-7.3c0.1-2.4-0.3-4.9-1-7.2c-0.6-1.8-1.7-3.4-3.2-4.5c-1.7-1.1-3.6-1.7-5.6-1.6
              c-3.4,0-5.9,1.2-7.5,3.5C286.6,602.8,285.8,606.1,285.8,610.3z M343.3,590.2c0.7,0,1.4,0,2.1,0.1c0.7,0.1,1.3,0.1,2,0.3l-0.8,7.8
              c-0.6-0.2-1.2-0.3-1.8-0.3c-0.6-0.1-1.3-0.1-1.9-0.1c-1.4,0-2.9,0.2-4.2,0.7c-1.3,0.5-2.5,1.2-3.5,2.2c-1.1,1-1.9,2.3-2.4,3.6
              c-0.6,1.6-0.9,3.3-0.9,5.1v20.3h-8.3V591h6.5l1.1,6.9h0.4c0.8-1.4,1.7-2.7,2.9-3.8c1.2-1.2,2.5-2.1,4-2.8
              C339.9,590.6,341.6,590.2,343.3,590.2z"
        />
        <path
          id="graphic_design"
          d="M49.7,449.3h34.6v46.6c-10.5,3.5-21.5,5.2-32.6,5.1c-9.4,0-17.4-1.9-23.9-5.6c-6.5-3.8-11.7-9.4-14.9-16.3
              c-3.4-7.1-5.1-15.7-5.1-25.8c0-9.7,1.9-18.1,5.6-25.2c3.7-7,9.4-12.8,16.4-16.6c7.2-3.9,15.9-5.9,26.2-5.9c9.5,0,18.9,1.9,27.6,5.6
              l-5.4,12.6c-3.4-1.5-6.9-2.8-10.5-3.7c-3.9-1-8-1.5-12.1-1.5c-6-0.2-12,1.3-17.2,4.3c-4.8,2.9-8.7,7.1-11.1,12.1
              c-2.7,5.8-4,12.1-3.9,18.5c-0.1,6.2,0.9,12.4,3.1,18.2c1.9,5,5.3,9.2,9.8,12.1c4.4,2.9,10.1,4.3,17.2,4.3c3,0,6-0.2,8.9-0.6
              c2.5-0.4,4.8-0.8,6.8-1.3v-23.8H49.7V449.3z M140.6,428.1c1.2,0,2.5,0.1,3.9,0.2c1.2,0.1,2.4,0.3,3.6,0.5l-1.4,14
              c-1.1-0.3-2.2-0.5-3.3-0.6c-1.1-0.1-2.3-0.2-3.5-0.2c-2.6,0-5.2,0.4-7.6,1.3c-2.4,0.9-4.6,2.2-6.4,4c-1.9,1.8-3.4,4.1-4.4,6.6
              c-1.1,2.9-1.7,6-1.6,9.1v36.7h-15v-70.3h11.7l2,12.4h0.7c1.4-2.5,3.2-4.8,5.2-6.9c2.1-2.1,4.5-3.8,7.1-5
              C134.6,428.7,137.6,428.1,140.6,428.1z M188.7,428.1c8.9,0,15.6,2,20.2,5.9c4.5,3.9,6.8,10.1,6.8,18.4v47.3H205l-2.9-10h-0.5
              c-1.8,2.3-3.9,4.5-6.2,6.3c-2.2,1.7-4.6,3-7.3,3.7c-3.3,0.9-6.7,1.3-10.1,1.2c-3.9,0.1-7.8-0.7-11.3-2.3c-3.3-1.5-6-4-7.8-7.1
              c-1.9-3.2-2.8-7.2-2.8-12.1c0-7.2,2.7-12.7,8.1-16.3c5.4-3.6,13.6-5.6,24.5-6l12.1-0.5v-3.7c0-4.8-1.1-8.3-3.4-10.4
              c-2.2-2.1-5.4-3.1-9.5-3.1c-3.5,0-6.9,0.5-10.2,1.5c-3.3,1-6.5,2.2-9.6,3.7l-4.9-10.5c3.7-1.9,7.7-3.4,11.8-4.4
              C179.5,428.7,184.1,428.1,188.7,428.1z M200.8,466.1l-9,0.4c-7.5,0.3-12.7,1.5-15.7,3.8c-3,2.2-4.6,5.7-4.5,9.4c0,3.5,1,6,3.1,7.6
              c2.4,1.7,5.3,2.5,8.2,2.4c4.7,0.2,9.2-1.4,12.8-4.4c3.4-2.9,5.1-7.2,5.1-12.9L200.8,466.1z M272.6,428.1c8.3,0,15,3,20.1,9.1
              s7.6,15.1,7.6,27.2c0,8-1.2,14.7-3.5,20.1c-2,5-5.4,9.3-9.8,12.3c-4.4,2.8-9.5,4.3-14.7,4.2c-3.1,0.1-6.3-0.4-9.3-1.4
              c-2.4-0.8-4.7-2-6.7-3.7c-1.8-1.4-3.3-3.1-4.7-4.9h-0.9c0.2,1.7,0.4,3.5,0.6,5.6s0.3,3.9,0.3,5.7v28.6h-15V429.4h12.3l2.1,9.7h0.7
              c1.4-2,3-3.9,4.8-5.5c2-1.8,4.3-3.1,6.9-4C266.2,428.6,269.4,428.1,272.6,428.1z M268.6,440.4c-4.1,0-7.4,0.8-9.9,2.4
              c-2.6,1.8-4.5,4.3-5.4,7.3c-1.2,4-1.8,8.1-1.8,12.2v2.1c-0.1,4.5,0.5,8.9,1.6,13.2c0.8,3.3,2.7,6.2,5.4,8.3
              c2.5,1.9,5.9,2.8,10.3,2.8c3.3,0.1,6.5-0.9,9-3c2.6-2.3,4.4-5.2,5.4-8.5c1.3-4.2,1.9-8.6,1.8-13c0-7.6-1.3-13.4-4-17.6
              S274.1,440.3,268.6,440.4L268.6,440.4z M332.4,400.9v24.8c0,2.6-0.1,5.1-0.2,7.5c-0.1,2.5-0.3,4.4-0.5,5.7h0.8
              c1.4-2.4,3.3-4.4,5.5-6.1c2.2-1.6,4.7-2.8,7.3-3.6c2.8-0.8,5.7-1.2,8.6-1.2c4.7-0.1,9.4,0.8,13.7,2.7c3.7,1.8,6.8,4.7,8.7,8.3
              c2,3.8,3,8.7,3,14.7v45.8h-14.9v-43c0-5.4-1.1-9.5-3.4-12.2c-2.3-2.7-5.7-4.1-10.4-4.1c-4.5,0-8.1,0.9-10.8,2.8
              c-2.8,2-4.8,5-5.7,8.3c-1.2,4.4-1.8,8.9-1.7,13.4v34.8h-15v-98.8H332.4z M407.6,402.5c2.1,0,4.2,0.6,5.9,1.8
              c1.7,1.2,2.5,3.4,2.5,6.4c0,3-0.8,5.1-2.5,6.4c-3.6,2.5-8.4,2.5-12,0c-1.6-1.3-2.5-3.4-2.5-6.4c0-3,0.8-5.1,2.5-6.4
              C403.3,403.1,405.4,402.5,407.6,402.5z M415,429.4v70.3h-15v-70.3H415z M464.6,501c-6,0.1-11.9-1.2-17.2-3.8
              c-4.9-2.6-8.9-6.8-11.2-11.9c-2.6-5.3-3.9-12.1-3.9-20.3c0-8.6,1.4-15.6,4.3-21c2.6-5.2,6.8-9.4,11.9-12c5.5-2.7,11.5-4,17.6-3.9
              c3.8,0,7.7,0.4,11.4,1.2c3,0.7,5.9,1.7,8.7,3l-4.4,11.9c-2.6-1-5.3-1.8-8-2.5c-2.5-0.7-5.2-1.1-7.8-1.1c-3.6-0.1-7.2,0.8-10.3,2.8
              c-2.8,2-5,4.9-6.1,8.2c-1.4,4.3-2.1,8.8-2,13.3c-0.1,4.4,0.6,8.8,2,13c1.1,3.2,3.2,6,6,8c2.9,1.9,6.4,2.8,9.8,2.7
              c3.5,0,7-0.4,10.3-1.4c3-0.9,5.9-2.1,8.6-3.6v13c-2.7,1.5-5.6,2.7-8.6,3.4C472.1,500.7,468.4,501.1,464.6,501z M611.5,452.4
              c0,10.4-1.9,19.2-5.8,26.2c-3.8,6.9-9.8,12.5-17,15.8c-7.4,3.5-16.3,5.3-26.8,5.3h-25.8v-92.8h28.6c9.6,0,17.9,1.7,24.9,5.2
              c6.8,3.3,12.5,8.7,16.2,15.3C609.6,434.1,611.5,442.5,611.5,452.4z M595.6,452.9c0-7.6-1.2-13.8-3.6-18.8c-2.2-4.7-5.9-8.6-10.5-11
              c-4.6-2.4-10.3-3.6-17-3.5h-13v67.3h10.8c11.2,0,19.5-2.9,25.1-8.6C592.8,472.7,595.6,464.2,595.6,452.9z M657.9,428.1
              c5.6-0.2,11.2,1.2,16.1,3.9c4.5,2.6,8.1,6.4,10.3,11c2.5,5.3,3.8,11.1,3.6,17v8h-46.9c0.2,6.8,2,12.1,5.5,15.7
              c3.5,3.7,8.4,5.5,14.7,5.5c4.1,0,8.1-0.4,12.1-1.3c3.8-1,7.5-2.2,11-3.9v12.1c-3.4,1.6-7,2.8-10.7,3.6c-4.3,0.8-8.7,1.2-13.1,1.2
              c-6.3,0.1-12.5-1.2-18.2-4c-5.2-2.6-9.5-6.8-12.3-12c-2.9-5.3-4.4-12-4.4-19.9c0-8,1.3-14.6,4-20.1c2.4-5.2,6.3-9.6,11.2-12.6
              C646.1,429.5,652,428,657.9,428.1z M657.9,439.3c-4.3-0.2-8.4,1.5-11.4,4.6c-2.9,3-4.6,7.5-5.1,13.4h31.9c0-3.2-0.6-6.3-1.8-9.3
              c-1-2.6-2.8-4.8-5-6.3C663.9,440,660.9,439.2,657.9,439.3z M752,479.6c0.1,4.2-1,8.2-3.4,11.7c-2.5,3.3-5.9,5.8-9.8,7.2
              c-5.1,1.8-10.4,2.6-15.8,2.5c-4.2,0.1-8.5-0.3-12.7-1c-3.5-0.7-6.8-1.8-10-3.3v-12.9c3.7,1.7,7.4,3,11.3,4.1
              c3.9,1.1,7.8,1.7,11.8,1.7c4.9,0,8.4-0.8,10.6-2.3c2.1-1.4,3.3-3.7,3.3-6.2c0-1.5-0.4-2.9-1.3-4.1c-1.3-1.6-3-2.8-4.8-3.7
              c-3.4-1.7-6.8-3.3-10.4-4.6c-3.9-1.5-7.6-3.3-11.2-5.4c-2.8-1.6-5.2-3.8-6.9-6.4c-1.7-2.9-2.5-6.3-2.4-9.7c0-6.3,2.5-11,7.4-14.3
              s11.5-4.9,19.6-4.9c4.1,0,8.2,0.4,12.2,1.3c3.9,0.9,7.6,2.1,11.3,3.8l-4.7,11.2c-3.1-1.3-6.3-2.5-9.6-3.4c-3.2-0.9-6.4-1.4-9.7-1.4
              c-3.9,0-6.8,0.6-8.8,1.8c-1.9,1-3,2.9-3,5c0,1.5,0.5,3,1.5,4.2c1.4,1.5,3.2,2.6,5,3.4c2.4,1.1,5.7,2.6,10,4.3
              c3.7,1.4,7.4,3.1,10.9,5c2.8,1.5,5.3,3.8,7.1,6.4C751.3,472.7,752.2,476.2,752,479.6z M775.6,402.5c2.1,0,4.2,0.6,5.9,1.8
              c1.7,1.2,2.5,3.4,2.5,6.4s-0.8,5.1-2.5,6.4c-3.6,2.5-8.4,2.5-12,0c-1.6-1.3-2.5-3.4-2.5-6.4c0-3,0.8-5.1,2.5-6.4
              C771.3,403.1,773.5,402.5,775.6,402.5z M783,429.4v70.3h-15v-70.3H783z M824.4,530.9c-9.5,0-16.8-1.7-21.9-5.2
              c-4.9-3.1-7.8-8.6-7.7-14.4c-0.1-4,1.4-8,4.1-11c2.7-3,6.6-5,11.7-6.1c-2-0.9-3.7-2.3-5-4c-1.3-1.7-2.1-3.8-2.1-6
              c-0.1-2.4,0.7-4.8,2.3-6.7c1.9-2.2,4.1-4,6.6-5.4c-3.7-1.6-6.8-4.2-8.8-7.7c-2.3-3.8-3.4-8.1-3.3-12.5c-0.1-4.6,1-9.1,3.3-13
              c2.2-3.6,5.6-6.5,9.5-8.2c4.7-2,9.9-3,15-2.8c1.3,0,2.7,0.1,4.2,0.2s2.9,0.3,4.2,0.5c1,0.1,2,0.3,3,0.6h24.3v8.3l-11.9,2.2
              c1.2,1.7,2.1,3.5,2.7,5.5c0.7,2.2,1,4.4,1,6.7c0,7.3-2.5,13-7.5,17.2c-5,4.2-11.9,6.3-20.7,6.3c-2.1,0-4.1-0.2-6.2-0.5
              c-1.3,0.8-2.5,1.9-3.5,3.1c-0.8,1.1-1.2,2.4-1.2,3.8c0,1.1,0.4,2.1,1.2,2.8c1,0.8,2.3,1.3,3.6,1.6c1.9,0.4,3.9,0.5,5.8,0.5H839
              c7.7,0,13.6,1.6,17.7,4.9s6.1,8,6.1,14.3c0,8-3.3,14.2-9.9,18.5C846.2,528.8,836.7,530.9,824.4,530.9z M825,520.5
              c4.4,0.1,8.8-0.4,13.1-1.5c3-0.7,5.8-2.2,8.1-4.3c1.8-1.8,2.8-4.2,2.8-6.7c0.1-1.9-0.5-3.8-1.7-5.3c-1.4-1.4-3.2-2.2-5.1-2.5
              c-2.8-0.5-5.7-0.7-8.5-0.7h-11c-2.5,0-5,0.4-7.3,1.3c-2,0.8-3.7,2.1-4.9,3.8c-1.2,1.7-1.8,3.8-1.8,5.9c-0.1,3.1,1.6,6,4.3,7.4
              C815.7,519.6,819.7,520.5,825,520.5L825,520.5z M827.9,465.7c4.3,0,7.5-1.2,9.7-3.6c2.1-2.4,3.2-5.8,3.2-10.1
              c0-4.7-1.1-8.2-3.2-10.6c-2.2-2.4-5.4-3.6-9.7-3.5c-4.2,0-7.4,1.2-9.5,3.6c-2.2,2.4-3.3,6-3.3,10.7c0,4.3,1.1,7.6,3.3,10
              C820.5,464.5,823.7,465.7,827.9,465.7z M913,428.1c7.9,0,14.1,2,18.5,6.1c4.4,4.1,6.6,10.6,6.6,19.7v45.8h-14.9v-43
              c0-5.4-1.1-9.5-3.4-12.2s-5.7-4.1-10.4-4.1c-6.8,0-11.5,2.1-14.2,6.3c-2.6,4.2-4,10.2-4,18.2v34.9h-15v-70.3h11.7l2.1,9.5h0.8
              c1.5-2.4,3.4-4.5,5.7-6.1c2.3-1.6,4.9-2.8,7.6-3.6C907,428.5,910,428.1,913,428.1z"
        />
        <path
          id="after_effects"
          d="M38.6,343.9l-5-14.3H13.9l-5,14.3H0l19.1-51.6h9.4l19.1,51.6H38.6z M31.4,322.4l-4.9-14.1l-0.8-2.6
              c-0.3-1.1-0.7-2.2-1.1-3.4l-0.9-3c-0.2,1-0.5,2-0.9,3.2s-0.7,2.3-1,3.3s-0.6,1.8-0.7,2.4l-4.9,14.2H31.4z M72.9,311.3h-9.5v32.6
              h-8.3v-32.6h-6.3v-4l6.3-2.4v-2.7c-0.1-2.7,0.4-5.4,1.6-7.9c1-1.9,2.6-3.4,4.5-4.2c2.2-0.9,4.6-1.3,7-1.3c1.6,0,3.3,0.1,4.9,0.5
              c1.3,0.2,2.5,0.6,3.7,1l-2.1,6.2c-0.9-0.3-1.8-0.5-2.7-0.7c-1-0.2-2.1-0.3-3.2-0.3c-1.6-0.1-3.1,0.5-4.1,1.8
              c-1,1.5-1.4,3.3-1.3,5.1v2.6h9.5L72.9,311.3z M94,337.9c1.1,0,2.1-0.1,3.2-0.3c1-0.2,1.9-0.4,2.9-0.7v6.3c-1.1,0.5-2.3,0.8-3.6,1
              c-1.5,0.3-3,0.4-4.5,0.4c-2,0-4.1-0.3-5.9-1.1c-1.8-0.8-3.3-2.1-4.2-3.8c-1.1-2.3-1.7-4.9-1.5-7.5v-20.9h-5.3v-3.7l5.7-3l2.7-8.3
              h5.2v8.6h11.1v6.3H88.7v20.8c-0.1,1.6,0.4,3.2,1.5,4.4C91.2,337.5,92.6,338,94,337.9z M123.5,304.2c3.1-0.1,6.2,0.7,8.9,2.1
              c2.5,1.4,4.5,3.5,5.7,6.1c1.4,2.9,2.1,6.2,2,9.4v4.4h-26c0.1,3.8,1.1,6.7,3,8.7s4.6,3.1,8.1,3.1c2.3,0,4.5-0.2,6.7-0.7
              c2.1-0.5,4.2-1.2,6.1-2.1v6.7c-1.9,0.9-3.9,1.6-5.9,2c-2.4,0.5-4.8,0.7-7.2,0.6c-3.5,0.1-6.9-0.7-10.1-2.2
              c-2.9-1.5-5.3-3.8-6.8-6.6c-1.7-3.4-2.5-7.2-2.4-11c-0.1-3.8,0.6-7.6,2.2-11.1c1.3-2.9,3.5-5.3,6.2-7
              C117,305,120.2,304.1,123.5,304.2z M123.5,310.5c-2.4-0.1-4.7,0.8-6.3,2.5c-1.6,1.7-2.6,4.2-2.8,7.4h17.7c0-1.8-0.3-3.5-1-5.1
              c-0.6-1.4-1.5-2.6-2.8-3.5C126.9,310.8,125.2,310.4,123.5,310.5z M169.2,304.2c0.7,0,1.4,0,2.1,0.1c0.7,0.1,1.3,0.1,2,0.3l-0.8,7.8
              c-0.6-0.2-1.2-0.3-1.8-0.3c-0.6-0.1-1.3-0.1-1.9-0.1c-1.4,0-2.9,0.2-4.2,0.7c-1.3,0.5-2.5,1.2-3.6,2.2c-1.1,1-1.9,2.3-2.4,3.6
              c-0.6,1.6-0.9,3.3-0.9,5.1v20.3h-8.3V305h6.5l1.1,6.9h0.4c0.8-1.4,1.7-2.7,2.9-3.8c1.2-1.2,2.5-2.1,4-2.8
              C165.9,304.6,167.6,304.2,169.2,304.2z M229.3,343.9h-29.1v-51.4h29.1v7.1h-20.7v14H228v7.1h-19.4v16h20.7L229.3,343.9z
              M258.9,311.3h-9.5v32.6h-8.3v-32.6h-6.3v-4l6.3-2.4v-2.7c-0.1-2.7,0.4-5.4,1.6-7.9c1-1.9,2.6-3.4,4.5-4.2c2.2-0.9,4.6-1.3,7-1.3
              c1.6,0,3.3,0.1,4.9,0.5c1.3,0.2,2.5,0.6,3.7,1l-2.1,6.2c-0.9-0.3-1.8-0.5-2.7-0.7c-1-0.2-2.1-0.3-3.2-0.3c-1.6-0.1-3.1,0.5-4.1,1.8
              c-1,1.5-1.4,3.3-1.3,5.1v2.6h9.5L258.9,311.3z M285,311.3h-9.5v32.6h-8.3v-32.6h-6.3v-4l6.3-2.4v-2.7c-0.1-2.7,0.4-5.4,1.6-7.9
              c1-1.9,2.6-3.4,4.5-4.2c2.2-0.9,4.6-1.3,7-1.3c1.6,0,3.3,0.1,4.9,0.5c1.3,0.2,2.5,0.6,3.7,1l-2.1,6.2c-0.9-0.3-1.8-0.5-2.7-0.7
              c-1-0.2-2.1-0.3-3.2-0.3c-1.6-0.1-3.1,0.5-4.1,1.8c-1,1.5-1.4,3.3-1.3,5.1v2.6h9.5L285,311.3z M307.1,304.2
              c3.1-0.1,6.2,0.7,8.9,2.1c2.5,1.4,4.5,3.5,5.7,6.1c1.4,2.9,2.1,6.2,2,9.4v4.4h-26c0.1,3.8,1.1,6.7,3,8.7s4.6,3.1,8.1,3.1
              c2.3,0,4.5-0.2,6.7-0.7c2.1-0.5,4.2-1.2,6.1-2.1v6.7c-1.9,0.9-3.9,1.6-5.9,2c-2.4,0.5-4.8,0.7-7.2,0.6c-3.5,0.1-6.9-0.7-10.1-2.2
              c-2.9-1.5-5.3-3.8-6.8-6.6c-1.7-3.4-2.6-7.2-2.4-11c-0.1-3.8,0.6-7.6,2.2-11.1c1.3-2.9,3.5-5.3,6.2-7
              C300.5,305,303.8,304.1,307.1,304.2z M307.1,310.5c-2.4-0.1-4.7,0.8-6.3,2.5c-1.6,1.7-2.5,4.2-2.8,7.4h17.7c0-1.8-0.3-3.5-1-5.1
              c-0.6-1.4-1.5-2.6-2.8-3.5C310.4,310.8,308.8,310.4,307.1,310.5z M348.8,344.6c-3.3,0.1-6.6-0.6-9.6-2.1c-2.7-1.5-4.9-3.8-6.2-6.6
              c-1.6-3.5-2.3-7.4-2.2-11.3c0-4.7,0.8-8.6,2.4-11.6c1.5-2.9,3.8-5.2,6.6-6.6c3-1.5,6.4-2.2,9.7-2.1c2.1,0,4.2,0.2,6.3,0.7
              c1.7,0.4,3.3,0.9,4.8,1.7l-2.5,6.6c-1.4-0.6-2.9-1.1-4.4-1.5c-1.4-0.4-2.9-0.6-4.3-0.6c-2-0.1-4,0.5-5.7,1.5
              c-1.6,1.1-2.8,2.7-3.4,4.5c-0.8,2.4-1.2,4.9-1.1,7.4c-0.1,2.5,0.3,4.9,1.1,7.2c0.6,1.8,1.8,3.3,3.3,4.4c1.6,1,3.5,1.6,5.5,1.5
              c1.9,0,3.9-0.2,5.7-0.8c1.7-0.5,3.3-1.2,4.8-2v7.2c-1.5,0.8-3.1,1.5-4.8,1.9C352.9,344.4,350.8,344.6,348.8,344.6z M383.3,337.9
              c1.1,0,2.1-0.1,3.2-0.3c1-0.2,1.9-0.4,2.9-0.7v6.3c-1.1,0.5-2.3,0.8-3.5,1c-1.5,0.3-3,0.4-4.5,0.4c-2,0-4.1-0.3-5.9-1.1
              c-1.8-0.7-3.2-2.1-4.2-3.8c-1.1-2.3-1.7-4.9-1.5-7.5v-20.9h-5.3v-3.7l5.7-3l2.7-8.3h5.2v8.6h11.1v6.3h-11.1v20.8
              c-0.1,1.6,0.4,3.2,1.5,4.4C380.5,337.5,381.9,338,383.3,337.9z M423.5,332.8c0.1,2.3-0.6,4.6-1.9,6.5c-1.4,1.9-3.3,3.3-5.4,4
              c-2.8,1-5.8,1.4-8.7,1.4c-2.3,0-4.7-0.2-7-0.6c-1.9-0.4-3.8-1-5.6-1.8v-7.1c2,0.9,4.1,1.7,6.3,2.3c2.1,0.6,4.3,0.9,6.6,1
              c2.7,0,4.6-0.4,5.9-1.3c1.2-0.8,1.8-2,1.8-3.4c0-0.8-0.3-1.6-0.7-2.3c-0.7-0.9-1.6-1.6-2.7-2.1c-1.9-1-3.8-1.8-5.8-2.5
              c-2.1-0.8-4.2-1.8-6.2-3c-1.5-0.9-2.9-2.1-3.8-3.6c-0.9-1.6-1.4-3.5-1.3-5.3c-0.2-3.2,1.4-6.2,4.1-7.9c2.7-1.8,6.4-2.7,10.9-2.7
              c2.3,0,4.5,0.2,6.8,0.7c2.1,0.5,4.2,1.2,6.2,2.1l-2.6,6.2c-1.7-0.7-3.5-1.4-5.3-1.9c-1.8-0.5-3.6-0.8-5.4-0.8
              c-1.7-0.1-3.4,0.2-4.9,1c-1,0.5-1.7,1.6-1.7,2.8c0,0.8,0.3,1.7,0.8,2.3c0.8,0.8,1.7,1.5,2.8,1.9c1.3,0.6,3.2,1.4,5.5,2.4
              c2.1,0.8,4.1,1.7,6,2.8c1.6,0.9,2.9,2.1,3.9,3.6C423.1,329,423.5,330.9,423.5,332.8z"
        />
        <path
          id="pydeck"
          d="M26.8,186.2c4.3-0.1,8.4,1.7,11.1,5.1c2.8,3.4,4.2,8.4,4.2,15c0.1,3.8-0.6,7.6-1.9,11.1c-1.1,2.8-3,5.1-5.4,6.8
              c-2.4,1.6-5.3,2.4-8.1,2.3c-1.7,0-3.5-0.2-5.1-0.8c-1.4-0.4-2.6-1.1-3.7-2c-1-0.8-1.9-1.7-2.6-2.7h-0.5c0.1,0.9,0.2,1.9,0.3,3.1
              s0.2,2.2,0.2,3.1v15.8H6.8V187h6.8l1.2,5.4h0.4c0.7-1.1,1.6-2.2,2.7-3.1c1.1-1,2.4-1.7,3.8-2.2C23.2,186.5,25,186.2,26.8,186.2z
              M24.5,193c-1.9-0.1-3.8,0.4-5.5,1.4c-1.4,1-2.5,2.4-3,4c-0.7,2.2-1,4.5-1,6.8v1.2c-0.1,2.5,0.3,4.9,0.9,7.3c0.5,1.8,1.5,3.4,3,4.6
              c1.7,1.1,3.7,1.7,5.7,1.6c1.8,0.1,3.6-0.5,5-1.6c1.4-1.3,2.5-2.9,3-4.7c0.7-2.3,1-4.8,1-7.2c0-4.2-0.7-7.4-2.2-9.8
              C30,194.1,27.3,192.8,24.5,193L24.5,193z M45.8,187h9l8,22.2c0.4,1,0.7,2,1,3c0.3,1,0.5,1.9,0.8,2.9s0.4,1.9,0.5,2.8h0.2
              c0.2-1.2,0.6-2.6,1-4.1c0.4-1.5,0.9-3,1.5-4.5l7.6-22.2h8.9l-16.7,44.3c-0.8,2.3-2,4.5-3.5,6.5c-1.3,1.7-3,3.1-4.9,4.1
              c-2,1-4.3,1.4-6.5,1.4c-1,0-2-0.1-3.1-0.2c-0.9-0.1-1.6-0.3-2.2-0.4V236c0.6,0.1,1.2,0.2,1.9,0.3c0.8,0.1,1.5,0.1,2.3,0.1
              c2.6,0.1,5.1-1.2,6.6-3.4c0.8-1.1,1.4-2.3,1.9-3.6l1.4-3.7L45.8,187z M103.2,226.6c-4.3,0.1-8.4-1.7-11.1-5.1
              c-2.8-3.4-4.2-8.4-4.2-15s1.4-11.7,4.3-15.1c2.8-3.4,6.9-5.3,11.3-5.1c1.7,0,3.5,0.2,5.1,0.8c1.4,0.5,2.7,1.2,3.8,2.1
              c1,0.9,2,1.9,2.7,3h0.4c-0.1-0.7-0.3-1.8-0.4-3.1c-0.1-1.3-0.2-2.5-0.2-3.8v-14h8.3v54.7h-6.5l-1.5-5.3h-0.4
              c-0.7,1.1-1.6,2.1-2.6,3c-1.1,1-2.4,1.7-3.8,2.2C106.7,226.3,104.9,226.6,103.2,226.6z M105.6,219.9c3.5,0,6-1,7.5-3.1
              c1.5-2,2.2-5.1,2.2-9.2v-1.1c0-4.4-0.7-7.7-2.1-10c-1.4-2.3-4-3.5-7.7-3.5c-2.7-0.1-5.4,1.2-6.8,3.6c-1.6,2.4-2.3,5.7-2.3,10
              c0,4.3,0.8,7.5,2.3,9.8C100.2,218.7,102.8,220,105.6,219.9z M150.5,186.2c3.1-0.1,6.2,0.7,8.9,2.1c2.5,1.4,4.5,3.5,5.7,6.1
              c1.4,2.9,2.1,6.2,2,9.4v4.4h-26c0.1,3.8,1.1,6.7,3,8.7s4.6,3,8.1,3c2.3,0,4.5-0.2,6.7-0.7c2.1-0.5,4.2-1.2,6.1-2.1v6.7
              c-1.9,0.9-3.9,1.6-5.9,2c-2.4,0.5-4.8,0.7-7.2,0.6c-3.5,0.1-7-0.7-10.1-2.2c-2.9-1.5-5.3-3.8-6.8-6.6c-1.7-3.4-2.5-7.2-2.4-11
              c-0.1-3.8,0.6-7.6,2.2-11.1c1.3-2.9,3.5-5.3,6.2-7C143.9,187,147.2,186.1,150.5,186.2z M150.5,192.4c-2.4-0.1-4.7,0.8-6.3,2.5
              c-1.6,1.7-2.6,4.2-2.8,7.4H159c0-1.8-0.3-3.5-1-5.1c-0.6-1.4-1.5-2.6-2.8-3.5C153.8,192.8,152.2,192.4,150.5,192.4z M192.1,226.6
              c-3.3,0.1-6.6-0.6-9.6-2.1c-2.7-1.5-4.9-3.8-6.2-6.6c-1.6-3.5-2.3-7.4-2.2-11.3c0-4.7,0.8-8.6,2.4-11.6c1.5-2.9,3.8-5.2,6.6-6.6
              c3-1.5,6.4-2.2,9.7-2.1c2.1,0,4.2,0.2,6.3,0.7c1.7,0.4,3.3,0.9,4.8,1.7l-2.5,6.6c-1.4-0.6-2.9-1.1-4.4-1.5
              c-1.4-0.4-2.9-0.6-4.3-0.6c-2-0.1-4,0.5-5.7,1.5c-1.6,1.1-2.8,2.7-3.4,4.5c-0.8,2.4-1.2,4.9-1.1,7.4c-0.1,2.5,0.3,4.9,1.1,7.2
              c0.6,1.8,1.8,3.3,3.3,4.4c1.6,1,3.5,1.6,5.4,1.5c1.9,0,3.9-0.2,5.7-0.8c1.7-0.5,3.3-1.2,4.8-2v7.2c-1.5,0.9-3.1,1.5-4.7,1.9
              C196.3,226.4,194.2,226.6,192.1,226.6z M220.5,171.2v26.4c0,1.2,0,2.5-0.1,3.9s-0.2,2.8-0.3,4h0.2c0.6-0.8,1.3-1.8,2.2-2.9
              c0.9-1.1,1.7-2.1,2.5-2.9l11.9-12.8h9.5l-15.6,16.7l16.6,22.2h-9.7l-12.5-17.1l-4.6,4v13.1h-8.3v-54.7L220.5,171.2z"
        />
      </g>
    </svg>
  );
};

export const BackgroundSkills = forwardRef(BackgroundSkillsFC);
