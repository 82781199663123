import React, { useEffect, useRef } from 'react';
import gsap from 'gsap/all';
import { useMatch } from 'react-location';
import { useRedirectHandler } from '../project-card';
import { getFeaturedProject } from '../../helpers/getFeaturedProject';
import s from './featuredProject.scss';
// import page assets
import headlinePreview from '../../assets/img/featured-project/macbook-mockup-amdb.webp';
import { buildProjectsDict } from '../../helpers/buildProjectsDict';

const PROJECTS = buildProjectsDict();

//Featured Project Component
export const FeaturedProjectSection = ({ animationFinished }) => {
  // find project with (1) a featured prop defined, and (2) a featured prop that is true
  const [featuredProjName, featuredProj] = getFeaturedProject(PROJECTS);

  // load-in animations
  const tl = useRef();
  const previewRef = useRef();
  const headlineRef = useRef();

  const curLocation = useMatch().pathname;

  const isIntroPlayed = JSON.parse(
    sessionStorage.getItem(`projects-IntroPlayed`)
  );

  React.useLayoutEffect(() => {
    if (!isIntroPlayed) {
      const SLOPE = 1.7266; // Slope of path that image travels on
      const yTrans = window.innerWidth / 4;

      // Create GSAP Timelines for load-in animations
      tl.current = gsap.timeline({
        paused: true, // disable timeline until page animation is finished
        onComplete: () => {
          if (curLocation !== '/')
            sessionStorage.setItem(`projects-IntroPlayed`, true);
        },
      });

      // Select preview image
      tl.current
        .from(previewRef.current, {
          autoAlpha: 0,
          y: -yTrans,
          x: yTrans * SLOPE,
          ease: 'power2.inOut',
          duration: 1,
        })
        // Select all headline elements
        .from(
          headlineRef.current.children,
          {
            autoAlpha: 0,
            x: 500,
            zIndex: -1,
            stagger: 0.1,
            ease: 'back',
            duration: 0.8,
            clearProps: 'all',
          },
          '-=0.4'
        );
    }
  }, [curLocation, isIntroPlayed]);

  useEffect(() => {
    if (animationFinished && !isIntroPlayed) {
      tl.current.play();
    }
  }, [animationFinished, isIntroPlayed]);

  const redirectToProject = useRedirectHandler();

  return (
    <>
      <div ref={headlineRef} className={s.headline}>
        <h5 className={s.accent}>FEATURED</h5>
        <h3 className={s.title}>{featuredProj.title}</h3>
        <p className={s.subtitle}>
          This web app marks my first deep dive into Next.js, Tailwind, and
          Server-Side Rendering.
        </p>
        <button
          onClick={() => redirectToProject(featuredProjName)}
          className={`${s.btn} btn-primary box-shadow-1dp`}
        >
          <span>Learn more</span>
          <Arrow />
        </button>
      </div>

      <div ref={previewRef} className={s.preview}>
        <img src={headlinePreview} alt={`Preview of ${featuredProj.title}`} />
      </div>
    </>
  );
};

const Arrow = () => (
  <svg className={s.icon} width="10.03" height="8.31" viewBox="0 0 10.03 8.31">
    <path
      className={s.arrow}
      d="M10,4.15,6.14,8A.91.91,0,0,1,4.85,8a.93.93,0,0,1,0-1.3L6.52,5.06H.91a.92.92,0,1,1,0-1.83H6.53L4.85,1.56a.92.92,0,0,1,0-1.29.91.91,0,0,1,1.29,0Z"
    />
  </svg>
);
