import React, { forwardRef } from 'react';

const ForegroundSkillsFC = (props, ref) => {
  return (
    <div id="fgSkills" ref={ref}>
      <div>
        <svg className="fgSkill" viewBox="0 0 53.21 53.21">
          <path
            id="JS"
            className="cls-1"
            d="M0,0V53.21H53.21V0ZM29,41.5c0,5.18-3,7.53-7.47,7.53A7.76,7.76,0,0,1,14,44.47L18.08,42c.78,1.39,1.5,2.57,3.22,2.57S24,43.93,24,41.43v-17h5ZM40.79,49c-4.64,0-7.64-2.21-9.11-5.11l4.06-2.36a5.44,5.44,0,0,0,4.93,3c2.08,0,3.4-1,3.4-2.46,0-1.72-1.36-2.32-3.64-3.32l-1.25-.54c-3.61-1.53-6-3.46-6-7.53,0-3.75,2.86-6.61,7.33-6.61a7.39,7.39,0,0,1,7.11,4l-3.9,2.5c-.85-1.54-1.78-2.15-3.21-2.15a2.18,2.18,0,0,0-2.4,2.15c0,1.5.93,2.1,3.08,3l1.25.54c4.25,1.82,6.65,3.68,6.65,7.85C49.08,46.58,45.54,49,40.79,49Z"
          />
        </svg>
      </div>
      <div>
        <svg className="fgSkill" viewBox="0 0 53.49 53.21">
          <g id="python">
            <path
              id="pyD"
              className="cls-3"
              d="M26.55,0C13,0,13.82,5.89,13.82,5.89l0,6.1h13v1.83H8.69S0,12.84,0,26.54,7.58,39.75,7.58,39.75h4.53V33.4s-.24-7.59,7.46-7.59H32.43s7.22.12,7.22-7V7.1S40.74,0,26.55,0ZM19.41,4.1a2.34,2.34,0,1,1-2.33,2.33A2.33,2.33,0,0,1,19.41,4.1Z"
            />
            <path
              id="pyL"
              className="cls-1"
              d="M26.94,53.21c13.58,0,12.73-5.89,12.73-5.89l0-6.1H26.7V39.39H44.8s8.69,1,8.69-12.72-7.58-13.21-7.58-13.21H41.38v6.36s.24,7.58-7.46,7.58H21.06s-7.22-.12-7.22,7V46.11S12.75,53.21,26.94,53.21Zm7.14-4.1a2.33,2.33,0,1,1,2.33-2.33A2.33,2.33,0,0,1,34.08,49.11Z"
            />
          </g>
        </svg>
      </div>
      <div>
        <svg className="fgSkill" viewBox="0 0 52.49 73.94">
          <g id="HTML">
            <path
              id="text"
              className="cls-1"
              d="M11.21,0V3.3H8.16V0H4.81V10H8.16V6.65h3.05V10h3.34V0ZM16,0V3.32H19V10h3.34V3.32h2.94V0ZM34.46,0,32.32,3.51,30.18,0H26.7V10H30V5l2.3,3.56h.06L34.62,5v5H38V0ZM43,6.69V0H39.61V10h8V6.69Z"
            />
            <path
              id="shield"
              className="cls-1"
              d="M17,31.91l.6,6.74h8.68V31.91Zm0,0,.6,6.74h8.68V31.91Zm0,0,.6,6.74h8.68V31.91ZM0,14.38,4.78,68l21.45,6L47.7,68l4.79-53.61Zm43.61,50.2L26.24,69.39V62.57h0L12.77,58.83l-.95-10.55h6.6l.49,5.47,7.32,2h0V45.22H11.55L9.78,25.34H26.24V18.76H47.7Zm-26-25.93h8.68V31.91H17ZM17,31.91l.6,6.74h8.68V31.91Z"
            />
            <path
              id="overlay"
              className="cls-2"
              d="M26.24,45.22V55.73l7.33-2,.77-8.53Zm0-26.46v6.58H42.71l-.59,6.57H26.24v6.74H41.52l-1.8,20.18L26.24,62.57v6.82l17.37-4.81L47.7,18.76Z"
            />
          </g>
        </svg>
      </div>
      <div>
        <svg className="fgSkill" viewBox="0 0 50.47 70.7">
          <g id="CSS">
            <path
              id="text"
              className="cls-1"
              d="M13.6,6.39v-3h4.45V0H10.26V9.44h7.79v-3Zm12.46-3h3.11V0H21.38V3.34c1,1,1.58,1.54,3.09,3.05H21.38V9.44h7.79v-3l-3.11-3Zm11,0h3.11V0H32.42V3.34c1,1,1.59,1.54,3.09,3.05H32.42V9.44h7.79v-3l-3.11-3Z"
            />
            <path
              id="shield"
              className="cls-1"
              d="M0,13.92,4.73,65.18,25.24,70.7l20.5-5.52,4.73-51.26ZM41.88,61.84l-16.6,4.47h0V60h0L12.31,56.13l-.84-9.67h6.38l.41,5,6.89,1.74h.09V43.1h0l-14.17-.05h0l-.48-6h0l14.68-6.11h0v-.34H10l-.77-6.19h16V18.07H45.71Z"
            />
            <path
              id="overlay"
              className="cls-2"
              d="M26.06,30.54l-.82.34v-.34Zm6.76,12.58-7.58,0V53.19l7.1-2Zm-7.58-25v6.28h15.7l-.63,6.18h0L25.24,37v0H39.78L38.11,56.2,25.24,60V66.3h0l16.6-4.47,3.83-43.77Z"
            />
          </g>
        </svg>
      </div>
      <div>
        <svg className="fgSkill mySqlSize" viewBox="0 0 99.37 17.83">
          <g id="mySQL">
            <path
              id="SQL"
              className="cls-3"
              d="M55.19,13.75h8.42a6.37,6.37,0,0,0,2.69-.56c1.28-.59,1.88-1.37,1.88-2.4V8.64c0-.82-.69-1.61-2.08-2.15a7.51,7.51,0,0,0-2.48-.42H60.08c-1.18,0-1.74-.35-1.9-1.14a1.18,1.18,0,0,1,0-.27V3.34a1.14,1.14,0,0,1,0-.25c.16-.6.47-.76,1.5-.88H68.3V.26H60.1a7.45,7.45,0,0,0-2.37.25C56,1,55.24,1.9,55.24,3.4V5.1c0,1.32,1.48,2.44,4,2.7a7.86,7.86,0,0,0,.85,0h3a1.42,1.42,0,0,1,.31,0c.92.09,1.33.25,1.59.59a.66.66,0,0,1,.22.54v1.7a1,1,0,0,1-.41.69,2.08,2.08,0,0,1-1.25.41c-.12,0-.18,0-.3,0H55.19ZM86.4,10.36c0,2,1.5,3.12,4.5,3.34l.86.05h7.61V11.81H91.69c-1.76,0-2.35-.43-2.35-1.46V.27H86.41v10.1ZM70,10.45V3.52C70,1.76,71.29.67,73.74.35l.78,0h5.55a7.37,7.37,0,0,1,.82,0c2.45.33,3.67,1.41,3.67,3.17v6.94a2.54,2.54,0,0,1-1.72,2.7l2.86,2.59H82.32L80,13.65l-2.35.14H74.5a7.09,7.09,0,0,1-1.76-.23c-1.85-.51-2.77-1.5-2.77-3.11Zm3.15-.15a1.43,1.43,0,0,0,.08.29c.15.81.91,1.25,2.07,1.25H78L75.57,9.65H79l2.12,1.92a1.35,1.35,0,0,0,.74-.94.92.92,0,0,0,0-.29V3.7a.89.89,0,0,0,0-.27c-.15-.76-.91-1.18-2-1.18h-4.4c-1.3,0-2.15.56-2.15,1.45Z"
            />
            <path
              id="my"
              className="cls-1"
              d="M16.33,13.76h2.91V2.22l4.54,10.06a2.48,2.48,0,0,0,2.69,1.65,2.47,2.47,0,0,0,2.67-1.65L33.66,2.22V13.76H36.6V2.22A1.76,1.76,0,0,0,35.21.28C33-.39,31.51.19,30.84,1.68l-4.46,10-4.3-10C21.43.19,19.93-.39,17.69.28a1.76,1.76,0,0,0-1.36,1.94ZM39,4.37H42v6.35c0,.35.11,1.16,1.76,1.18H50V4.33h2.94V14.69c0,2.55-3.16,3.11-4.64,3.14H39.08V15.88h9.25c1.88-.2,1.65-1.14,1.65-1.46v-.76h-6.2c-2.89,0-4.75-1.29-4.77-2.76Z"
            />
            <path
              id="dolphin"
              className="cls-1"
              d="M13.24,11.19c-.09-.2-.52-.45-.68-.56l-1-.67a6.69,6.69,0,0,0-.67-.27c-.27-.11-.85-.22-1-.42A6.81,6.81,0,0,1,9.22,8L8.55,6.52c-.11-.24-.16-.44-.27-.67A8.7,8.7,0,0,0,5.22,2.51a6.14,6.14,0,0,0-1.41-.68c-.29-.09-.64,0-1-.1H2.65a2,2,0,0,1-.46-.34,5.18,5.18,0,0,0-1-.49C1.1.85.74.71.61.82A.16.16,0,0,0,.47.94a.52.52,0,0,0,0,.42,6.93,6.93,0,0,0,.54.76,5,5,0,0,1,.49.74,14.51,14.51,0,0,1,.6,1.71,3.88,3.88,0,0,0,.35.63c.11.15.31.25.37.44s-.18.84-.25,1.05A4.21,4.21,0,0,0,2.7,9.3c.12.28.22.6.57.66a5.41,5.41,0,0,1,.28-1.48A3.64,3.64,0,0,1,4,7.81c.1.06.1.24.16.36.13.3.26.63.42.94a13.13,13.13,0,0,0,1.1,1.78,4,4,0,0,0,.51.58,1,1,0,0,1,.22.2h0v0c-.29-.11-.48-.41-.71-.59A5.69,5.69,0,0,1,4.48,9.8L4.08,9V9A1,1,0,0,0,4,9.25c-.13.48,0,1-.47,1.22s-.87-.33-1-.58a4.3,4.3,0,0,1-.29-3.28,2.79,2.79,0,0,1,.23-.74c0-.17-.17-.23-.25-.33a4.24,4.24,0,0,1-.36-.61L1.34,3.62c-.07-.17-.09-.35-.16-.51a4.8,4.8,0,0,0-.5-.74C.46,2.05-.2,1.42.06.79c.35-1,1.79-.25,2.35.1a3.79,3.79,0,0,0,.45.34l.74,0a5.23,5.23,0,0,1,1.25.4A9.8,9.8,0,0,1,8.63,5.34a8.54,8.54,0,0,1,.5,1.11l.81,1.71a3.6,3.6,0,0,0,.4.75c.07.1.26.13.38.18.32.17.71.3,1,.49a12.88,12.88,0,0,1,1.62,1.21c.18.16.31.4.49.57v.09l-.47.13c-.35.09-.65.08-1,.16a4,4,0,0,1-.68.16l0,0c.13.36.82.65,1.13.87a4.7,4.7,0,0,1,1,1l.3.29c.07.09.08.22.15.31v0c-.13-.05-.2-.18-.31-.26a4.66,4.66,0,0,0-.58-.45c-.38-.24-.81-.37-1.19-.61l-.63-.45a2.46,2.46,0,0,1-.51-.6c-.07-.1-.08-.23-.16-.31s.25-.19.4-.25a4.63,4.63,0,0,1,1.9-.27Zm-10-8.5a1.56,1.56,0,0,0-.4.05h0a3.25,3.25,0,0,0,.31.4l.22.47,0,0a.54.54,0,0,0,.21-.48,2.25,2.25,0,0,1-.12-.21c-.06-.1-.19-.14-.26-.22Z"
            />
          </g>
        </svg>
      </div>
      <div>
        <svg className="fgSkill phpSize" viewBox="0 0 50.92 25.59">
          <path
            id="php"
            className="cls-1"
            d="M11.58,5.38H4L0,25.59H3.92L5,20.23H8.37a14,14,0,0,0,3.32-.39,6.22,6.22,0,0,0,3-1.86,8.75,8.75,0,0,0,1.8-2.59,10,10,0,0,0,.88-2.93,6.45,6.45,0,0,0-1-5.16C15.4,6,13.8,5.4,11.58,5.38Zm1.72,7.05q-.62,3.52-2.42,4.11a14.65,14.65,0,0,1-4.51.54H6a1.84,1.84,0,0,1-.33,0L7.32,8.62h.57l.65,0A13,13,0,0,1,12.29,9C13.3,9.32,13.64,10.45,13.3,12.43Zm20-1.58-1.89,9.38H27.43l1.8-9a2.54,2.54,0,0,0-.17-2,2.41,2.41,0,0,0-1.94-.59L24,8.65,21.68,20.23H17.79L21.85,0h3.89l-1.1,5.38h3.49a7,7,0,0,1,4.29,1.19C33.37,7.32,33.66,8.75,33.26,10.85ZM49.81,7.3C48.81,6,47.21,5.4,45,5.38H37.44l-4,20.21h3.91l1.08-5.36h3.38a14,14,0,0,0,3.32-.39,6.26,6.26,0,0,0,3-1.86,8.75,8.75,0,0,0,1.8-2.59,9.93,9.93,0,0,0,.87-2.93A6.45,6.45,0,0,0,49.81,7.3Zm-3.1,5.13Q46.1,16,44.29,16.54a14.69,14.69,0,0,1-4.51.54h-.4l-.34,0,1.69-8.43h.57l.65,0A12.86,12.86,0,0,1,45.69,9C46.71,9.32,47.05,10.45,46.71,12.43Z"
          />
        </svg>
      </div>
    </div>
  );
};

export const ForegroundSkills = forwardRef(ForegroundSkillsFC);
