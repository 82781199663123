import { importAll } from '../helpers/utils';

// Step 1
function initProjDict() {
  const projFilesDict = importAll(
    require.context('../assets/projects', true, /\.(png|jpe?g|svg|webm|webp)$/)
  );

  let projects = {};

  let lastDir = '';

  Object.keys(projFilesDict).forEach((key) => {
    // get directory of file
    let dir = key.substring(0, key.indexOf('/'));

    // check if directory is new
    if (!(dir === lastDir)) {
      projects[dir] = {}; // add dict of dir's
    }

    lastDir = key;
  });

  return [projects, projFilesDict];
}

// Step 2
function buildSubDirLists(projects, filesDict) {
  // clone projects array
  let groupedFiles = JSON.parse(JSON.stringify(projects));

  // group files in filesDict by project
  Object.keys(projects).forEach((projDir) => {
    Object.entries(filesDict).forEach(([dir, file]) => {
      // get directory of file
      let matchingDir = dir.substring(0, dir.indexOf('/'));

      const subDir = dir.substring(dir.indexOf(projDir) + projDir.length + 1);

      if (projDir === matchingDir) {
        groupedFiles[projDir][subDir] = file;
      }
    });
  });

  // convert subdirectories into sub-objects of projects dict
  Object.keys(groupedFiles).forEach((projDir) => {
    let lastSubDir = '';

    Object.entries(groupedFiles[projDir]).forEach(([subDir, file]) => {
      // get subdirectory of file
      let subDirName = subDir.substring(0, subDir.indexOf('/'));

      // check if subdirectory is new
      if (!(subDirName === lastSubDir)) {
        projects[projDir][subDirName] = [file]; // add list for each subdirectory and input current file
      } else {
        projects[projDir][subDirName].push(file); // input current file if list already exists
      }

      lastSubDir = subDirName;
    });
  });

  return projects;
}

// Step 3
function importJSONasEntries(projects) {
  // import root-level project files for other text props
  const projJsonFiles = importAll(
    require.context('../assets/projects', true, /\.(json|md)$/)
  );

  // group files in filesDict by project
  Object.keys(projects).forEach((projDir) => {
    Object.entries(projJsonFiles).forEach(([dir, file]) => {
      // get directory of file
      let matchingDir = dir.substring(0, dir.indexOf('/'));

      const fileName = dir.substring(
        dir.indexOf(projDir) + projDir.length + 1,
        dir.indexOf('.')
      );

      // if the file's directory matches the current project directory,
      // then add the file to its project's object
      if (projDir === matchingDir) {
        if (dir.match(/\.(json)$/) !== null) {
          // if the file is a JSON file, map the JSON file's entries to projects dict:
          Object.entries(file).forEach(([key, value]) => {
            projects[projDir][key] = value;
          });
        } else {
          // for all other text files, add it without a map function
          projects[projDir][fileName] = file;
        }
      }
    });
  });

  return projects;
}

// Main program
function buildProjectsDict() {
  let [projects, filesDict] = initProjDict();

  // import all assets and sort them into lists within each project object
  projects = buildSubDirLists(projects, filesDict);

  // add overlay color prop for cards
  projects['fidelity-1']['overlayColor'] = '#368727';
  projects['fidelity-2']['overlayColor'] = '#368727';
  projects['hump-day-golf-league']['overlayColor'] = '#676934';
  projects['roche-bros-redesign']['overlayColor'] = '#DE2233';
  projects['data-services']['overlayColor'] = '#03B0D0';

  projects = importJSONasEntries(projects);

  return projects;
}

export { buildProjectsDict };
