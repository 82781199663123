import React from 'react';
import { ExperienceItem } from '../experience-item';

const sections = [
  {
    id: 1,
    company: 'KAYAK',
    position: 'Software Engineer',
    duration: 'June 2022 - Present',
    body: (
      <>
        <p>
          Helping the mWeb UI team transition the architecture of the site
          between JS frameworks (from Angular and AngularJS to React).
        </p>
        <p>
          On our mobile website, I'm currently making contributions to the
          Flights, Stays, Cars, and Booking product areas by maintaining
          existing code and building new features for our users with Angular,
          AngularJS, and React.
        </p>
      </>
    ),
  },

  {
    id: 2,
    company: 'Fidelity Investments',
    position: 'Technical Intern',
    duration: 'Summers 2017 & 2018',
    body: (
      <>
        <p>
          Developed web application to display and automatically update AMP
          project status using PHP, HTML5, CSS3, and the Handlebars.js library,
          allowing Fidelity AMP project managers to easily input and view their
          current projects' statuses.
        </p>
        <p>
          Collaborated with another intern on the Benefits Registry
          Administration UI, to build a front-end application that would fetch
          existing client data from an internal API and allow it to be updated
          by support administrators, resulting in shorter call durations between
          Fidelity WPS representatives and clients.
        </p>
      </>
    ),
  },

  {
    id: 3,
    company: 'Analog Devices, Inc.',
    position: 'Student Consultant',
    duration: 'Spring 2021',
    body: (
      <>
        <p>
          During the second semester of my junior year at Bentley University, I
          led a group of 5 consulting students to create a prototype IT solution
          to address a problem specified by our client firm. Our solution
          involved creating a responsive and dynamic intranet site for the
          client using technologies such as HTML, CSS, JS, jQuery, and
          SharePoint Designer.
        </p>
        <p>
          Through this course, I gained exposure to the agile methodology of
          software development. At the end of weekly sprints, we presented the
          client with our progress and listened to their feedback; then, we
          would assign tasks to team-members and iterate on the application.
        </p>
      </>
    ),
  },

  {
    id: 4,
    company: 'Freelance Work',
    position: 'Web Developer',
    duration: 'Summer 2020',
    body: (
      <>
        <p>
          Built Wix website to support a golf league allowing each member to
          create an account and RSVPs to play during their available weeks.
          Implemented an RSVP system to expedite the website manager's process
          of assigning members to tee times and teams.
        </p>
      </>
    ),
  },
];

export function ExperienceSection({ animationFinished }) {
  return (
    <>
      {sections.map((sec) => (
        <ExperienceItem
          key={`ExpContentSection${sec.id}`}
          {...{ sec, animationFinished }}
        />
      ))}
    </>
  );
}
