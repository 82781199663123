import { useRef } from 'react';

function randomIntFromRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function randomColor(colors) {
  return colors[Math.floor(Math.random() * colors.length)];
}

function distance(x1, y1, x2, y2) {
  const xDist = x2 - x1;
  const yDist = y2 - y1;

  return Math.sqrt(Math.pow(xDist, 2) + Math.pow(yDist, 2));
}

const looper = (fn) => {
  let cb = (time) => {
    requestAnimationFrame(cb);
    let diff = ~~(time - (cb.time || 0)),
      seconds_passed = diff / 1000;
    fn(seconds_passed);
    cb.time = time;
  };
  return cb;
};

function useArrayRef() {
  const refs = useRef([]);
  refs.current = [];
  return [refs, (ref) => ref && refs.current.push(ref)];
}

// helper function for importing files
function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

/**
 * Applies and conditionally applies scoped CSS classes.
 * @param s - object imported by CSS Modules
 * @param args - string or object with className as key and boolean as value.
 * @returns
 */
function scoped(s, ...args) {
  const classNames = args.map((arg) => {
    if (typeof arg === 'string') {
      // if arg is string, then return s.arg
      return s[arg];
    }
    // if arg is object with 'key' 'val' pairs, return each s.key if 'val' is true.
    if (Object.prototype.toString.call(arg) === '[object Object]') {
      // conditionally apply classNames from object
      const classNamesToApply = Object.entries(arg).map(([key, val]) => {
        if (val) {
          return s[key];
        }
        return '';
      });
      return classNamesToApply.join(' ');
    }
    return '';
  });
  return classNames.join(' ');
}

export {
  randomIntFromRange,
  randomColor,
  distance,
  looper,
  useArrayRef,
  importAll,
  scoped,
};
