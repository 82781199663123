import { useEffect, useRef } from 'react';
import gsap, { ScrollTrigger } from 'gsap/all';

export function useIntroAnimation(typeWriterTimeline) {
  const tl = useRef();

  useEffect(() => {
    if (!JSON.parse(sessionStorage.getItem('home-IntroPlayed'))) {
      // only play this on first session load

      tl.current = gsap.timeline({
        onComplete: () => {
          sessionStorage.setItem('home-IntroPlayed', true);
        },
      });
      tl.current
        .to('body', { overflow: 'hidden' }) // lock scrolling during animation
        .from('#headRow>h1', {
          autoAlpha: 0,
          y: -60,
          ease: 'back',
          duration: 1,
        })
        .from(
          '#headRow>h3',
          {
            autoAlpha: 0,
            transformOrigin: 'top center',
            rotateX: 180,
            ease: 'back',
            duration: 1,
          },
          '-=0.5'
        )
        .call(() => {
          typeWriterTimeline.current.play();
        }) // play typewriter animation
        .from(
          '#navRow>#logoCont>svg, #navRow>li',
          {
            autoAlpha: 0,
            y: -10,
            ease: 'power2.inOut',
            duration: 0.5,
            stagger: {
              grid: [0, 5],
              from: 'center',
              amount: 0.75,
            },
          },
          '-=1.5'
        )
        .from(
          '#landBgContainer>svg',
          {
            autoAlpha: 0,
            y: 300,
            ease: 'power2.inOut',
            duration: 1,
          },
          '-=1.5'
        )
        .from(
          '#landBgContainer>canvas',
          {
            autoAlpha: 0,
            ease: 'power2.inOut',
            duration: 1,
          },
          '-=0.5'
        )
        .from(
          '#headRow>#scrollIndicator',
          {
            autoAlpha: 0,
            scale: 0.5,
            duration: 0.5,
            ease: 'back',
            clearProps: 'all',
          },
          '-=0.5'
        )
        .from('#headRow>#clickMe', {
          autoAlpha: 0,
          scale: 0.5,
          duration: 0.5,
          ease: 'back',
          clearProps: 'all',
        })
        .to('body', { overflow: '' }, '-=1'); // unlock scrolling
    } else {
      //just play typewriter animation if this is after the first session load
      typeWriterTimeline.current.play();
    }
  }, []);

  // kill all scrolltriggers on page unmount
  useEffect(() => {
    ScrollTrigger.sort();
    return () => {
      ScrollTrigger.getAll().map((st) => st.kill());
      // This in case a scroll animation is active while the route is updated
      gsap.killTweensOf(window);
    };
  }, []);
}
