import gsap, { ScrollTrigger } from 'gsap/all';
import { useArrayRef } from '../helpers/utils';
import { useEffect } from 'react';

gsap.registerPlugin(ScrollTrigger);

export const useGsapFrom = (options) => {
  const [contentRefs, setContentRefs] = useArrayRef();

  useEffect(() => {
    // if the animated element has children, use its children for a staggered animation instead
    // otherwise, leave it alone
    const contentRefChildren = contentRefs.current.map((content) =>
      content.childElementCount > 0 ? [...content.children] : content
    );

    // play tween
    gsap.from(contentRefChildren, options);

    return () => {
      // Cleanup tweens and scrollTriggers if the component unmounts
      ScrollTrigger.getAll().map((st) => st.kill());
      gsap.killTweensOf(window);
    };
  }, []);

  return [setContentRefs];
};
