import { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export function useScrollAnimation() {
  const tl = useRef();
  const tl2 = useRef();
  const bgSkillsRef = useRef();
  const fgSkillsRef = useRef();

  useEffect(() => {
    ScrollTrigger.saveStyles([
      bgSkillsRef.current,
      bgSkillsRef.current.children[0].children,
      bgSkillsRef.current.children[1].children,
      fgSkillsRef.current,
      fgSkillsRef.current.children,
      fgSkillsRef.current.children.children,
    ]);

    ScrollTrigger.matchMedia({
      // desktop animation
      '(min-width: 1050px)': () => {
        tl.current = gsap.timeline({
          scrollTrigger: {
            trigger: '.r1',
            pin: true,
            pinSpacing: true,
            scrub: 1,
            start: 'top top',
            end: '+=3500px top',
            // markers: true,
          },
        });

        tl.current.clear();

        tl.current
          .from(bgSkillsRef.current.children[0].children, {
            x: window.innerWidth,
            opacity: 0,
            ease: 'power2.inOut',
            duration: 4,
            stagger: 0.25,
          })
          .from(
            bgSkillsRef.current.children[1].children,
            {
              x: -window.innerWidth,
              opacity: 0,
              ease: 'power2.inOut',
              duration: 4,
              stagger: 0.25,
            },
            '-=1'
          )
          .to(
            [
              bgSkillsRef.current.children[0].children,
              bgSkillsRef.current.children[1].children,
            ],
            {
              opacity: 0.03,
              duration: 1,
            }
          )
          .from(fgSkillsRef.current.children, {
            y: -10,
            opacity: 0,
            ease: 'power2.inOut',
            duration: 2,
            stagger: 0.25,
          });
      },
      // tablet/mobile animation
      '(max-width: 1049px)': () => {
        tl2.current = gsap.timeline({
          scrollTrigger: {
            trigger: '.r1',
            start: 'top+=200px bottom',
            end: 'top+=400px top',
            toggleActions: 'restart pause resume reset',
            // markers: true,
          },
        });

        tl2.current.clear();

        tl2.current
          .from(
            bgSkillsRef.current.children[0].children,
            {
              x: window.innerWidth,
              opacity: 0,
              ease: 'power2.inOut',
              duration: 0.75,
              stagger: 0.1,
            },
            '+=0.25'
          )
          .from(
            bgSkillsRef.current.children[1].children,
            {
              x: -window.innerWidth,
              opacity: 0,
              ease: 'power2.inOut',
              duration: 0.75,
              stagger: 0.1,
            },
            '-=0.75'
          )
          .to(
            [
              bgSkillsRef.current.children[0].children,
              bgSkillsRef.current.children[1].children,
            ],
            {
              opacity: 0.03,
              duration: 0.5,
            },
            '-=0.5'
          )
          .from(fgSkillsRef.current.children, {
            y: -10,
            opacity: 0,
            ease: 'power2.inOut',
            duration: 1,
            stagger: 0.1,
          });
      },
    });

    // responsive background skills section:
    // onload
    let bgSkills = document.getElementById('bgSkills');
    if (window.innerWidth <= 1080) {
      bgSkills.setAttribute('preserveAspectRatio', 'xMidYMid slice');
    }

    // same behavior on resize
    window.addEventListener('resize', () => {
      // change bgSkills' scaling method under 1080px
      if (window.innerWidth <= 1080) {
        bgSkills.setAttribute('preserveAspectRatio', 'xMidYMid slice');
      } else {
        bgSkills.removeAttribute('preserveAspectRatio');
      }
    });
  }, []);

  return {
    fgSkillsRef,
    bgSkillsRef,
  };
}
