import { useEffect, useRef, useState } from 'react';
import gsap, { ScrollTrigger } from 'gsap/all';
import { useMatch } from 'react-location';

gsap.registerPlugin(ScrollTrigger);

export function usePageLoadAnimation(page) {
  const contentHeadRef = useRef();
  const pageHeadRef = useRef();

  const tl = useRef();
  const [animationFinished, setAnimationFinished] = useState(false);

  const curLocation = useMatch().pathname;

  useEffect(() => {
    const pageIntroPlayed = JSON.parse(
      sessionStorage.getItem(`${page}-IntroPlayed`)
    );
    if (!pageIntroPlayed) {
      // Run timeline on first page load only
      tl.current = gsap.timeline({
        onComplete: () => {
          setAnimationFinished(true);
          if (page === 'work') {
            if (curLocation !== '/')
              sessionStorage.setItem(`${page}-IntroPlayed`, true);
          }
        },
      });
      // load-in navbar
      tl.current
        .from(pageHeadRef.current, {
          clipPath: 'ellipse(70% 0% at 50% 0%)',
          ease: 'power2.inOut',
          duration: 1,
        })
        .from(contentHeadRef.current, {
          opacity: 0,
          y: 40,
          ease: 'power2.inOut',
          duration: 1,
        });
    } else {
      setAnimationFinished(true);
    }
  }, [curLocation, page]);

  // GSAP Scrolltrigger cleanup
  useEffect(() => {
    ScrollTrigger.sort();
    // kill scrolltriggers on unmount
    return () => {
      ScrollTrigger.getAll().map((st) => st.kill());
      // This in case a scroll animation is active while the route is updated
      gsap.killTweensOf(window);
    };
  }, []);

  return [contentHeadRef, pageHeadRef, animationFinished];
}
