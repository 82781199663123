import { useEffect, useRef } from 'react';
import { useArrayRef } from '../../../helpers/utils';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';

export const useTransition = (sec) => {
  const [contentSecRefs, setContentSecRef] = useArrayRef();
  const tl = useRef();

  useEffect(() => {
    // animations
    if (!JSON.parse(sessionStorage.getItem('work-IntroPlayed'))) {
      let secs = contentSecRefs.current;
      // flip the transform origin if we are animating the second row
      const flipped = sec.id % 2 === 0;

      ScrollTrigger.saveStyles(secs);

      // set animations for desktop or mobile
      ScrollTrigger.matchMedia({
        '(min-width: 630px)': () => {
          // for desktop
          // Create timeline activated by scrolltrigger
          tl.current = gsap.timeline({
            scrollTrigger: {
              trigger: secs[3],
              start: 'top bottom',
              end: 'top+=20px top',
              // markers: true,
              toggleActions: 'play none none none',
            },
          });

          tl.current.clear();

          tl.current
            .from(secs[3], {
              autoAlpha: 0,
              ease: 'power4.inOut',
              duration: 0.5,
            })
            .from(
              secs[1],
              {
                transformOrigin: flipped ? 'center right' : 'center left',
                scaleX: (secs[2].offsetWidth * 12.6) / 100,
                ease: 'power4.inOut',
                duration: 0.75,
              },
              '-=0.5'
            )
            .from(
              secs[0],
              {
                transformOrigin: flipped ? 'center right' : 'center left',
                x: flipped ? -secs[0].offsetWidth : secs[0].offsetWidth,
                autoAlpha: 0,
                ease: 'power4.inOut',
                zIndex: -2,
              },
              '-=0.5'
            );
        },
        '(max-width: 629px)': () => {
          //for mobile only

          // Create timeline activated by scrolltrigger
          tl.current = gsap.timeline({
            scrollTrigger: {
              trigger: secs[3],
              start: 'top bottom',
              end: 'top+=20px top',
              // markers: true,
              toggleActions: 'play none none none',
            },
          });

          tl.current.clear();

          // fade in whole section
          tl.current
            .from(secs[3], {
              autoAlpha: 0,
              ease: 'power4.inOut',
              duration: 0.5,
            })
            // divider
            .from(
              secs[1],
              {
                transformOrigin: 'center top',
                scaleY: (secs[2].offsetHeight * 12.6) / 100,
                ease: 'power4.inOut',
                duration: 0.75,
              },
              '-=0.5'
            )
            // title
            .from(
              secs[0],
              {
                transformOrigin: 'center top',
                y: secs[0].offsetHeight,
                autoAlpha: 0,
                ease: 'power4.inOut',
                zIndex: -2,
              },
              '-=0.5'
            );
        },
      });
    }
  }, [contentSecRefs, sec.id]);

  return [setContentSecRef];
};
