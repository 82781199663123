import React, { useEffect } from 'react';
import { randomIntFromRange, distance } from '../helpers/utils';

function HeroCanvas({ propHeight }) {
  useEffect(() => {
    const [onMouseMove, onResize, onClick] = drawCanvas(propHeight);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('resize', onResize);
      window.removeEventListener('click', onClick);
    };
  }, [propHeight]);

  return (
    <>
      {/* should be the last child of landBgContainer */}
      <canvas id="heroCanvas"></canvas>
    </>
  );
}

function drawCanvas(propHeight) {
  const canvas = document.getElementById('heroCanvas');
  const c = canvas.getContext('2d');

  let innerWidth = window.innerWidth;
  let innerHeight = window.innerHeight;

  canvas.width = innerWidth;
  canvas.height = propHeight ?? innerHeight;

  const mouse = {
    x: undefined,
    y: undefined,
  };

  // Grab primary theme color from logoAccent
  const logoAccentStyles = window.getComputedStyle(
    document.getElementById('logoAccent')
  );
  const accent1 = logoAccentStyles.getPropertyValue('fill');

  const onMouseMove = (event) => {
    mouse.x = event.clientX;
    mouse.y = event.clientY;
  };

  // Event Listeners
  window.addEventListener('mousemove', onMouseMove);

  const onResize = () => {
    innerWidth = window.innerWidth;
    innerHeight = window.innerHeight;
    canvas.width = window.innerWidth;
    canvas.height = propHeight !== undefined ? propHeight : innerHeight;

    init();
  };

  window.addEventListener('resize', onResize);

  let canvClicked = false;
  let canvCount = 1;

  const onClick = () => {
    // toggle switch
    canvCount++;
    if (canvCount % 2 === 0) {
      canvClicked = true;
    } else {
      canvClicked = false;
    }
  };
  document.getElementById('clickMe')?.addEventListener('click', onClick);

  // Objects
  class Star {
    constructor() {
      let radius = Math.random() * (3 - 0) + 0;
      this.r = radius;
      this.rInit = radius;
      this.color = radius < 1 ? `${accent1}` : '#f5f5f5';
      this.colorInit = radius < 1 ? `${accent1}` : '#f5f5f5';
      this.x = Math.random() * (innerWidth * 1.2 - this.r) + this.r;
      this.y =
        Math.random() * ((innerHeight - 2 * this.r) * 0.8 - this.r) + this.r;

      // props for circular motion
      this.rads = Math.random() * 2 * Math.PI;
      this.velo = 0.0005;
      this.veloInit = 0.0005;
      this.rOrbit = randomIntFromRange(innerHeight * 0.3, innerHeight * 1.3);
    }
  }

  class Comet extends Star {
    constructor() {
      super();
      this.color = `${accent1}`;
      this.colorInit = `${accent1}`;
      let comR = Math.random() * (10 - 5) + 5;
      this.r = comR;
      this.rInit = comR;
      this.velo = 0.001;
      this.veloInit = 0.001;
      this.rOrbit = randomIntFromRange(innerHeight * 0.4, innerHeight * 1.1); // always spawn onscreen
    }
  }

  Star.prototype.draw = function (props) {
    c.beginPath();
    c.arc(this.x, this.y, this.r, 0, Math.PI * 2, false);
    c.fillStyle = this.color;
    c.shadowColor = this.color;
    c.shadowBlur = 10;
    c.shadowOffsetX = 0;
    c.shadowOffsetY = 0;
    c.fill();
    c.closePath();
  };

  Star.prototype.update = function () {
    this.rads += this.velo;
    this.x = innerWidth / 2 + Math.cos(this.rads) * this.rOrbit;
    this.y = innerHeight + Math.sin(this.rads) * this.rOrbit;

    // Mouse interactivity

    // On hover, alter star size and color
    if (distance(mouse.x, mouse.y, this.x, this.y) < 100) {
      // If a circle is near the mouse cursor:
      if (this.r < this.rInit * 2) {
        this.r += 1;
      } // grow until maxR
      this.color = `${accent1}`;
    } else {
      if (this.r > this.rInit) {
        this.r -= 1;
      } // shrink until minR7
      this.color = this.colorInit;
    }

    // On canvasClick, do the following:
    if (canvClicked) {
      // increase velo of stars until max
      if (this.velo < this.veloInit * 5) {
        this.velo += 0.0001;
      }

      // refresh colored stars
      if (this.color !== '#f5f5f5') {
        this.color = `${window
          .getComputedStyle(document.getElementById('logoAccent'))
          .getPropertyValue('fill')}`;
      }
    } else {
      // decrease velo of stars until its initial velo
      if (this.velo > this.veloInit) {
        this.velo -= 0.00001;
      }
      // refresh colored stars
      if (this.color !== '#f5f5f5') {
        this.color = `${window
          .getComputedStyle(document.getElementById('logoAccent'))
          .getPropertyValue('fill')}`;
      }
    }

    this.draw();
  };

  // Implementation
  let stars = [];
  let comets = [];
  function init() {
    stars = [];
    comets = [];

    for (let i = 0; i < innerHeight * 0.3; i++) {
      let star = new Star();
      stars.push(star);
    }

    for (let i = 0; i < 10; i++) {
      let comet = new Comet();
      comets.push(comet);
    }
  }

  // Animation Loop
  function animate() {
    requestAnimationFrame(animate);

    c.save();

    if (canvClicked) {
      // if canvClicked, add trails to moving particles.
      c.globalAlpha = 0.3;
      c.fillRect(0, 0, canvas.width, canvas.height);
      c.globalAlpha = 1;
    } else {
      c.clearRect(0, 0, canvas.width, canvas.height);
    }

    stars.forEach((star) => {
      star.update();
    });

    comets.forEach((comet) => {
      comet.update();
    });

    c.restore();
  }

  init();
  animate();

  return [onMouseMove, onResize, onClick];
}

export default HeroCanvas;
