import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useState, useEffect } from 'react';

export const useTransition = (cardRef) => {
  // state to tell card when fade in is complete
  const [fadeInDone, setFadeInDone] = useState(false);

  // fade-in scrolltrigger animation
  useEffect(() => {
    const onAnimationFinished = () => {
      gsap.set(cardRef.current, { clearProps: 'x' });
      setFadeInDone(true);
    };

    if (!JSON.parse(sessionStorage.getItem('projects-IntroPlayed'))) {
      gsap.from(cardRef.current, {
        autoAlpha: 0,
        x: cardRef.current.classList.value.includes('cardUp') ? -50 : 50,
        ease: 'power2.inOut',
        duration: 0.75,
        scrollTrigger: {
          id: cardRef.current.id,
          trigger: cardRef.current,
          start: 'top 60%',
          end: 'bottom 30%',
          // markers: true,
          once: true,
        },
        onComplete: onAnimationFinished,
      });
    } else {
      onAnimationFinished();
    }

    return () => {
      ScrollTrigger.getAll().map((st) => st.kill());
      // This in case a scroll animation is active while the route is updated
      gsap.killTweensOf(window);
    };
  }, [cardRef]);

  return fadeInDone;
};
