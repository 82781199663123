import React from 'react';

export function EmailStatusMessage({ status }) {
  const messageMap = {
    success: '✔️ Success! Your message has been sent.',
    error: '❌ Sorry, something went wrong. Please try again.',
  };
  return (
    <>
      {status !== 'idle' && (
        <div className={`formStatus formStatus--${status} box-shadow-1dp`}>
          <p>{messageMap[status]}</p>
        </div>
      )}
    </>
  );
}
