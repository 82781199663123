import React, { useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useArrayRef } from '../helpers/utils';

import ProjectsSection from './projectsSection';
import { ExperienceSection } from './experience-section';
import { ContactSection } from './contact-section';
import { SkillsSection } from './skills-section';
import { Footer } from './footer';

gsap.registerPlugin(ScrollTrigger);

function HomeContent() {
  // scrolltrigger references
  const [headerRefs, setHeaderRef] = useArrayRef();

  // state to tell sections when the they are ready to fade in
  const [loadedHeaderCount, setLoadedHeaderCount] = useState(0);

  const setAllHeaderAnimationsCompleted = () => {
    setLoadedHeaderCount(4);
  };

  useEffect(() => {
    const introPlayed = JSON.parse(sessionStorage.getItem('home-IntroPlayed'));
    if (introPlayed) {
      setAllHeaderAnimationsCompleted();
      return;
    }
    ScrollTrigger.matchMedia({
      '(max-width: 629px)': () => {
        headerRefs.current.forEach((h) => {
          // display all row headings (they disappear without a media query)
          gsap.set(h, { clearProps: 'all' });
          setAllHeaderAnimationsCompleted();
        });
      },
      '(min-width: 630px)': () => {
        // tablet/desktop header animation
        headerRefs.current.forEach((h) => {
          // Fade in all row Headers
          gsap.from(h, {
            autoAlpha: 0,
            x: -60,
            ease: 'power2.inOut',
            duration: 0.5,
            scrollTrigger: {
              trigger: h,
              start: 'top 70%',
              end: 'bottom 70%',
              once: true,
            },
            onComplete: () => {
              gsap.set(h, { clearProps: 'autoAlpha, x, transform' });
              setLoadedHeaderCount((prev) => prev + 1);
            },
          });
        });
      },
    });
  }, [headerRefs]);

  return (
    <>
      {/* Below the Fold */}
      <div className="pageContent">
        <div className="pageContent__row r1">
          <div className="contentHead" ref={setHeaderRef}>
            <h1>
              skills<span className="headAccent">_</span>
            </h1>
          </div>
          <SkillsSection />
        </div>

        <div className="pageContent__row r2">
          <div className="contentHead" ref={setHeaderRef}>
            <h1>
              experience<span className="headAccent">_</span>
            </h1>
          </div>
          {/* There are no home page animations, so this value is always true */}
          <ExperienceSection animationFinished={loadedHeaderCount >= 2} />
        </div>

        <div className="pageContent__row r3">
          <div className="contentHead" ref={setHeaderRef}>
            <h1>
              projects<span className="headAccent">_</span>
            </h1>
          </div>
          <ProjectsSection animationFinished={loadedHeaderCount >= 3} />
        </div>

        <div className="pageContent__row r4">
          <div className="contentHead" ref={setHeaderRef}>
            <h1>
              contact me<span className="headAccent">_</span>
            </h1>
          </div>
          <ContactSection animationFinished={loadedHeaderCount >= 4} />
        </div>

        <Footer />
      </div>
    </>
  );
}

export default HomeContent;
