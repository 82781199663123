import React, { useState, useEffect } from 'react';
import NavBar from './navbar';
import MobileNavBar from './mobileNavbar';
import HeroCanvas from './heroCanvas';
import { useEventListener } from '../hooks';

import gsap from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
gsap.registerPlugin(TextPlugin);

function Hero({ mobileMenuOpened, setMobileMenuOpened }) {
  // mobile states
  const [useMobileNav, setUseMobileNav] = useState(window.innerWidth <= 630);

  // Site themes list
  const themes = ['default', 'chargers', 'emerald', 'pink-gold', 'cyan'];
  const [currentTheme, setCurrentTheme] = useState(themes[0]);

  const handleClickMe = () => {
    // get pos of currentTheme in themes list
    const themePos = themes.indexOf(currentTheme);

    // add 1 to current theme pos unless we've reached the end of the list,
    // else return to beginning of list
    const nextTheme =
      themePos <= themes.length - 2 ? themes[themePos + 1] : themes[0];

    // update currentTheme
    setCurrentTheme(nextTheme);
  };

  useEffect(() => {
    // update site with new theme
    document
      .getElementsByTagName('html')[0]
      .setAttribute('class', `theme-${currentTheme}`);
  }, [currentTheme]);

  const onWindowResize = () => {
    if (window.innerWidth <= 630) {
      setUseMobileNav(true);
    } else {
      setUseMobileNav(false);
    }
  };

  useEventListener('resize', onWindowResize);

  return (
    <>
      {/* Above the Fold */}
      <div id="landBgContainer">
        <div id="landContentContainer">
          {useMobileNav ? (
            <MobileNavBar
              mobileMenuOpened={mobileMenuOpened}
              setMobileMenuOpened={setMobileMenuOpened}
            />
          ) : (
            <NavBar />
          )}

          <div id="spacingRow"></div>

          {!mobileMenuOpened && (
            <div id="headRow">
              <h1>Hi, I'm {useMobileNav && <br />} Anthony Medugno.</h1>
              <h3>
                <span className="subtitleText"></span>
                <span className="subtitleCursor">|</span>
              </h3>

              <div id="scrollIndicator">
                <svg className="scroll_indicator" viewBox="0 0 74.82 207.86">
                  <text
                    className="scroll_indicator__text"
                    transform="translate(4.52 96.44) rotate(-5.44)"
                  >
                    click a link{' '}
                    <tspan x="49.08" y="15.63">
                      or{' '}
                    </tspan>
                    <tspan x="-3.41" y="31.27">
                      scroll down{' '}
                    </tspan>
                    <tspan x="16.22" y="46.9">
                      to begin
                    </tspan>
                  </text>
                  <path
                    className="scroll_indicator__upArrow"
                    transform={
                      useMobileNav ? 'scale(-1, 1) translate(-90, 0)' : ''
                    }
                    d="M33,75.43a110.15,110.15,0,0,1,.89-35.59A103.55,103.55,0,0,1,46.74,6.56a.49.49,0,0,1,.68-.18.51.51,0,0,1,.18.69h0a103,103,0,0,0-13.2,32.87A110.09,110.09,0,0,0,33,75.43ZM51,0l-1,1.05a36.38,36.38,0,0,1-4.08,3.56c-.73.54-1.47,1.07-2.24,1.56s-1.55,1-2.35,1.42a22.31,22.31,0,0,0,8.39-4.9,35.38,35.38,0,0,0-.14,4.12,25,25,0,0,0,.19,2.73,24.46,24.46,0,0,0,.49,2.69,72.85,72.85,0,0,1,.59-10.77Z"
                  />
                  <path
                    className="scroll_indicator__downArrow"
                    transform={
                      useMobileNav ? 'scale(-1, 1) translate(-70, 0)' : ''
                    }
                    d="M27,201.73a75.16,75.16,0,0,1-9.86-13.39,57.5,57.5,0,0,1-6.1-15.44,54.78,54.78,0,0,1-1.22-16.6,69.5,69.5,0,0,1,3.33-16.37,69.85,69.85,0,0,0-3.58,16.35,55.25,55.25,0,0,0,1,16.72,57.9,57.9,0,0,0,5.94,15.71,74.92,74.92,0,0,0,9.77,13.69h0a.5.5,0,0,0,.7,0A.5.5,0,0,0,27,201.73Zm3.76,6.13L29.6,207A37.6,37.6,0,0,0,25,204c-.8-.43-1.61-.84-2.44-1.21s-1.68-.72-2.54-1.07a22.47,22.47,0,0,1,9,3.63,36.94,36.94,0,0,1-.73-4,25.08,25.08,0,0,1-.21-2.73,22.51,22.51,0,0,1,.09-2.73,70.86,70.86,0,0,0,2.16,10.56Z"
                  />
                </svg>
              </div>

              <div id="clickMe" onClick={handleClickMe}>
                <svg className="click_me" viewBox="0 0 77.1 55.25">
                  <text
                    className="click_me__text"
                    transform="translate(13 22) rotate(12.77) scale(1.1)"
                  >
                    click me!
                  </text>
                  <path
                    className="click_me__squiggle"
                    transform=""
                    d="M12.6,25.7c0,2.15-.25,4.3-.25,6.44a14.69,14.69,0,0,0,1.12,6.2,18.55,18.55,0,0,0,3.59,5.19,27.64,27.64,0,0,0,4.9,4,41.77,41.77,0,0,0,11.65,5.09,49,49,0,0,0,12.62,1.75A38.08,38.08,0,0,0,58.8,52.5a32.72,32.72,0,0,0,5.8-2.67,22.27,22.27,0,0,0,5-4,26.85,26.85,0,0,0,6-11.19A28.28,28.28,0,0,0,76.21,22a22,22,0,0,0-5.45-11.28,26.13,26.13,0,0,0-4.9-4,32.66,32.66,0,0,0-5.7-2.78A49,49,0,0,0,47.68,1.38a99.57,99.57,0,0,0-12.81-.22,59,59,0,0,0-12.6,2A36.47,36.47,0,0,0,10.76,8.47,24.09,24.09,0,0,0,6.13,12.8a21,21,0,0,0-3.19,5.48C1.36,22.25.8,26.56.1,30.79a.05.05,0,0,1-.06,0s0,0,0-.06c.58-4.24.93-8.58,2.48-12.67a21.73,21.73,0,0,1,3.17-5.68,24.8,24.8,0,0,1,4.68-4.53A37,37,0,0,1,22,2.24,55.67,55.67,0,0,1,34.82.13C37,0,39.14,0,41.3,0s4.32.11,6.47.32A49.49,49.49,0,0,1,60.48,3a32,32,0,0,1,5.85,2.92,27.16,27.16,0,0,1,5,4.21,22.74,22.74,0,0,1,5.43,11.71,29.71,29.71,0,0,1-.85,12.86,28.38,28.38,0,0,1-6.09,11.33A27.53,27.53,0,0,1,59,53a38.49,38.49,0,0,1-12.76,2.22,47,47,0,0,1-12.86-1.74,42.39,42.39,0,0,1-11.84-5.29,27.07,27.07,0,0,1-5-4.24A19.05,19.05,0,0,1,13,38.54a14.5,14.5,0,0,1-1-6.41c.08-2.16.35-4.29.48-6.43a.05.05,0,0,1,.05-.05A0,0,0,0,1,12.6,25.7Z"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>

        <svg
          id="landSVG"
          viewBox="0 0 1920 1080"
          preserveAspectRatio="xMidYMid slice"
        >
          <g id="mtn">
            <path
              id="mtn-dark"
              d="M1920,792.4c-5.3,3.8-10.7,7.5-16.2,10.9c-26.7,16.2-41.7,44.1-65,63.4
                                c-5,4.2-12.9,5.9-18.8,8.5c-7.6,3.4-16.2,5.9-24.2,9.6c-19.7,9-41.2,17.5-61.3,7.2c-12.7-6.5-37-5.1-41.2,12
                                c-2.4,9.8-2.6,11.5-14.3,11c-8.7-0.4-17.9-3-26.5-4.5c-17.9-3.2-38.7-5.5-54.9-12.9c-8.1-3.7-13.8-9.6-20-16.3
                                c-4.7-5-9.2-9.8-12-16c-3-6.6-2.5-13.6-11.1-12.7c-2.7,0.3-9.6,5-11.9,6.6c-5.4,3.7-8.7,6.2-15.1,8.8c-13.2,5.3-15.7,14.4-31.5,7.8
                                c-4.3-1.8-11-2.5-14.6-4.5c-7-3.9-7.9-10.4-11.8-17.5c-4.1-7.6-5-12.4-13.8-9.2c-4.5,1.6-9.7,5.3-14.5,7.4
                                c-17.4,7.4-27.6,23.3-42.8,33.9c-7.3,5.1-15.3,10.4-22.8,14.5c-5.6,3.1-12.2,5.6-16.7,10.9c-7.3,8.7-10.8,21.4-23.7,22.2
                                c-21.6,1.5-40.6-23.8-60.6-28.8c-8.7-2.2-14.5-2.9-22.4-7.8c-10.7-6.6-22.3-11.9-34.3-15.6c-17.2-5.6-30.3-22.2-44.8-32.2
                                c-10.8-7.4-18.3-17.8-28.4-25.8c-4.6-3.7-8.3-5.7-11.6-10.5c-3.5-5.1-5.7-13.1-13.8-11.4c-4.5,0.9-3.9,5-7,7.9
                                c-3.8,3.5-6.5,2.9-11.1,4.3c-10.5,3.2-16,11.1-24.9,16.8c-13.7,8.8-26.8,18.2-40.4,27.3c-20.1,13.4-41.8,24.1-64.6,32
                                c-12.3,4.3-25.2,6.5-37.5,10.9c-25.2,9-49.8,22-73.9,33.8c-6.2,3-12.2,4.5-18.2,7c-2.7,1.1-6.1,5.5-8.1,6.8
                                c-6.9,4.5-16.3,8.3-24.6,7.4c-18.4-2-36.9-8.7-55-11.1c-16.2-2.4-32.1-6-47.7-10.9c-6.3-1.9-6.8-3.4-14.4-3.7s-16.4-2.3-23.8-4.1
                                c-14.1-3.3-25-12-39.3-14.5c-15.7-2.7-30.3,5.7-40.4-10.8c-7.7-12.5-15.3-24.8-24.5-36.5c-8.8-11.1-17.7-22.6-33.8-17.8
                                c-10.4,3.1-21.2,15.5-29.8,22c-13.5,10.3-28.7,21.9-43.9,29.7c-26.7,13.8-55.8,22.6-84.2,32.4c-17.2,6-28.2,21.1-44.9,27.9
                                c-15.3,6.2-31.5,9.7-47,15c-32.9,11.2-61.1,30.4-96.4,33.3c-53.2,4.3-103.3,22.5-157.1,22c-8.9-0.1-17.8,0.1-26.8,0.2v51.5h1920
                                V792.4z"
            />
            <g id="mtn-light">
              <path
                id="mtn-l-3"
                d="M1798.4,1021.6c-3.1,2.6-4.7,6.4-7.8,9c-1.9,1.6-4,2.4-5.7,4.3c-2,2.1-3.7,4.5-5.1,7.1
                                    c-0.8,1.8-0.4,3.7-1.2,5.4c-2.9,6.4-10.6,10.3-16.7,12.7c-9.3,3.6-18.8,4.5-28.6,5.3c-8.4,0.7-15.9,4.1-23.5,7.3
                                    c-5.6,2.4-11.1,4.9-16.7,7.4H1920V792.4c-1.4,1-2.8,2-4.2,2.9c-0.9,1.6-1.9,3.1-3,4.6c-2.7,3.6-6.1,7.3-8.3,11.3
                                    c-1.1,1.9-1.7,4.2-2.9,6.1s-2.6,2.6-3.9,4.2c-5.3,6.4-14.4,16.3-12.4,25.3c0.9,4.3,2.7,7,0.4,11.3c-2.2,4.1-7.3,6.8-9.1,11.4
                                    c-1.2,3.3-1.2,7.4-2.3,10.8c-0.6,1.8-2.2,2.2-1.3,4.8c0.8,2.3,3.7,3.6,6,4.2c2.8,0.7,11.8,0.1,11.3,4.3c-0.2,1.3-2.2,2.4-3.1,3.1
                                    c-4.8,3.6-8.9,6.5-12.7,11.3c-4.8,5.9-9.2,12-13.3,18.4c-3.3,5.2-7.5,10.7-10,16.2c-1.7,3.8-0.8,8.8-0.8,13c0,3.9,1.5,5.8,1.8,9.5
                                    c0.4,3.8-0.4,7.6-2.2,10.9c-1.7,3.5-3.2,8.2-6.8,10.5c-7.2,4.6-17.6,3.7-25.6,3.8c-6.3,0.1-10.5,2.2-16.2,4.5
                                    c-6.9,2.8-14.4,2.5-21.3,5.2c-8.7,3.4-17.9,7.5-26.4,11.5c-5.1,2.4-12.9,5.1-16.1,10.1c4.3,0.3,9.8-2.3,14.1-3.3
                                    c10.6-2.4,21.7-4.7,32.5-5.2c6-0.3,11.7,0.1,17.6-1c2.7-0.5,5.8-1.9,8.5-0.5C1808.8,1015.3,1801.6,1018.9,1798.4,1021.6z"
              />
              <path
                id="mtn-l-2"
                d="M1354,1042.4c-10.8-7.7-14.6-16.2-17.2-29.4c-3.3-16.4,0.9-28.1,6.8-43.1
                                    c3.4-8.4,3.9-16.1,3.8-24.9c0.1-5.5-0.4-11-1.3-16.4c-2.9,2.9-6.8,4.6-10.9,4.8c-21.6,1.5-40.6-23.8-60.6-28.8
                                    c-8.7-2.2-14.5-2.9-22.4-7.8c-10.7-6.6-22.3-11.9-34.3-15.6c-17.2-5.6-30.3-22.2-44.8-32.2c-10.8-7.4-18.3-17.8-28.4-25.8
                                    c-4.6-3.7-8.3-5.7-11.6-10.5c-2.2-3.2-3.9-7.5-6.8-9.9c-1.6,3.3-3.9,6.2-4.8,9.9c-0.8,3.1-2.1,6.1-3.8,8.9
                                    c-1.4,2.7-2.6,5.6-3.4,8.6c-1.4,4.3-4,8-5.8,12.3c-2,4.9,2.4,7.4,6.1,10.4s7.5,5.8,3.1,10.5c-5.7,6.1-16.1,10.3-13.8,20.5
                                    c7.8,1.2,9.9,8.5,13.3,14.3c2.7,4.7,5.3,9,3.1,14.7c-1.5,3.9-5,7.4-7.6,10.6c-6.4,7.8-14.1,15-21.5,21.8
                                    c-8.5,7.7-16.8,15.4-25.2,23.1c-11.9,11.1-21.9,24.3-35.1,33.9c-10.6,7.7-21.1,15.2-33,20.9c-10.1,4.9-20.1,10.5-29.7,16.3
                                    c-4.4,2.7-9.6,5.4-13.4,8.9c-3.5,3.3-1.7,3.4,2.7,4.9c4,1.4,10.8,3.2,8.4,8.5c-1.2,2.6-4.8,4.8-6.7,6.8c-3.2,3.6-6.3,7.4-9.1,11.3
                                    h413C1366.4,1066.3,1366.6,1051.4,1354,1042.4z"
              />
              <path
                id="mtn-l-1"
                d="M725.3,1080c-0.7-0.8-1.3-1.7-2-2.5c-7.1-7.9-17.1-12.4-25.4-18.9c-15-11.8-23-27.1-32.2-43.4
                                    c-5.5-9.7-13.8-17.6-22.4-24.6c-6.3-5.1-13.5-9.8-18.1-16.7c-7.4-11.1-9.4-26.5-14.6-38.8c-3-7.2-6-14.7-9.3-21.5
                                    c-0.6-1.3-1.3-2.6-1.9-4c-4-1.3-7.7-4.1-10.9-9.3c-7.7-12.5-15.3-24.8-24.5-36.5c-8.8-11.1-17.7-22.6-33.8-17.8
                                    c-1,0.3-2.1,0.7-3.1,1.2c-0.8,3-1.5,6.6-0.8,9.3c0.4,1.5,1.6,1.8,1.9,3.3c1,4.3-0.8,7.4,1.5,11.3c3.7,6.1,7.8,4.8,1.8,11
                                    c-1.7,1.8-3.3,3.8-5.1,5.7c-1.6,1.6-3.9,2.4-5.4,3.8c-4.5,4.4,1.7,6.5,5.3,8.7c4.6,2.8,11,5.3,7.2,10.4c-4.5,6.1-14,3.8-15.8,12.5
                                    c-0.8,4,1.3,8.8-0.9,12.3c-1.7,2.7-6.3,5.3-8.9,7.3c-6,4.8-13.7,7.2-20,11.8c-6,4.3-12.5,8.1-17.8,13.3c-6,6-10.2,13.5-15.9,19.7
                                    c-5.9,6.4-12.5,9.8-19.9,14.2c-9.2,5.3-18,11.4-26.3,18.1c-5.7,4.7-11.6,12.1-18.5,15.1c-3.1,1.3-6.8,1.3-10,2.1
                                    c-6.4,1.6-11.4,5.1-17.3,7.9c-8.7,4.3-17.6,7.3-26,12.3c-12.4,7.5-25,14.8-37.4,22.6L725.3,1080z"
              />
            </g>
          </g>
        </svg>
        <HeroCanvas />
      </div>
    </>
  );
}

export default Hero;
