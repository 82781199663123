import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { useMatch } from 'react-location';
import { sendEmail } from './utils';
import { EmailStatusMessage } from './components';

export function ContactSection({ animationFinished }) {
  //init refereneces
  const tl = useRef();
  const bodyRef = useRef();
  const formRef = useRef();

  // useState for formData
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  // useState to display feedback to user -- whether email was successfully sent or not
  const [emailStatusFeedback, setEmailStatusFeedback] = useState('idle'); // always either 'idle', 'success', or 'error'

  const handleFormDataChange = (e) => {
    let currentState = formData;
    let nextState = Object.assign({}, currentState); // clone state variable
    nextState[e.target.name] = e.target.value;
    setFormData(nextState);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    sendEmail(formData).then(
      (result) => {
        return setEmailStatusFeedback('success');
      },
      (error) => {
        return setEmailStatusFeedback('error');
      }
    );
  };

  const curLocation = useMatch().pathname;

  useEffect(() => {
    const introPlayed = JSON.parse(
      sessionStorage.getItem(`contact-IntroPlayed`)
    );
    if (introPlayed) {
      return;
    }
    // create animation timeline
    tl.current = gsap.timeline({
      scrollTrigger: {
        trigger: bodyRef.current,
        start: 'top 70%',
        end: 'top 70%',
        once: true,
        // markers: true
      },
      onComplete: () => {
        if (curLocation !== '/') {
          sessionStorage.setItem(`contact-IntroPlayed`, true);
        }
      },
    });

    tl.current.scrollTrigger.disable();

    // load-in animations
    tl.current
      .from(
        bodyRef.current,
        {
          opacity: 0,
          y: 40,
          ease: 'power2.inOut',
          duration: 1,
        },
        '-=0.5'
      )
      .from(
        formRef.current.children,
        {
          opacity: 0,
          y: 40,
          ease: 'power2.inOut',
          duration: 1,
          stagger: 0.1,
        },
        '-=0.5'
      );
  }, [curLocation]);

  useEffect(() => {
    const introPlayed = JSON.parse(
      sessionStorage.getItem(`contact-IntroPlayed`)
    );
    if (animationFinished && !introPlayed) {
      tl.current.scrollTrigger.enable();
    }
  }, [animationFinished]);

  return (
    <>
      <div className="body" ref={bodyRef}>
        <p>
          I'm currently employed full-time as a Software Engineer at{' '}
          <a href="https://www.kayak.com" target="_blank" rel="noreferrer">
            KAYAK
          </a>
          , so I am not seeking new opportunities at this time. Regardless, if
          you'd like to chat, you can email me by submitting the form below or
          by shooting me a message on{' '}
          <a
            href="https://www.linkedin.com/in/anthony-medugno/"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
          </a>
          . I will try to respond as quickly as I can. Thank you!
        </p>
      </div>

      <div className="formCont">
        <EmailStatusMessage status={emailStatusFeedback} />
        <form className="formGrid" onSubmit={handleFormSubmit} ref={formRef}>
          <div className="formGrid__name">
            <label htmlFor="name">
              <span>name</span>
              <strong title="required">*</strong>
            </label>
            <input
              className="textfield box-shadow-1dp"
              type="text"
              id="name"
              name="name"
              placeholder="John Doe"
              minLength="5"
              maxLength="50"
              required
              value={formData.name}
              onChange={handleFormDataChange}
            />
          </div>

          <div className="formGrid__email">
            <label htmlFor="email">
              <span>email</span>
              <strong title="required">*</strong>
            </label>
            <input
              className="textfield box-shadow-1dp"
              type="email"
              id="email"
              name="email"
              placeholder="jdoe@example.com"
              minLength="5"
              maxLength="50"
              required
              value={formData.email}
              onChange={handleFormDataChange}
            />
          </div>

          <div className="formGrid__phone">
            <label htmlFor="phone">
              <span>phone</span>
            </label>
            <input
              className="textfield box-shadow-1dp"
              type="tel"
              id="phone"
              name="phone"
              placeholder="123&ndash;456&ndash;6789"
              minLength="12"
              maxLength="12"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              value={formData.phone}
              onChange={handleFormDataChange}
            />
          </div>

          <div className="formGrid__subject">
            <label htmlFor="subject">
              <span>subject</span>
              <strong title="required">*</strong>
            </label>
            <input
              className="textfield box-shadow-1dp"
              type="text"
              id="subject"
              name="subject"
              placeholder="Briefly let me know why you are reaching out."
              minLength="10"
              maxLength="200"
              required
              value={formData.subject}
              onChange={handleFormDataChange}
            />
          </div>

          <div className="formGrid__message">
            <label htmlFor="message">
              <span>message</span>
              <strong title="required">*</strong>
            </label>
            <textarea
              className="textfield box-shadow-1dp"
              type="text"
              id="message"
              name="message"
              placeholder="Start your message here..."
              minLength="50"
              maxLength="2000"
              required
              value={formData.message}
              onChange={handleFormDataChange}
            ></textarea>
          </div>

          <div className="formGrid__submit">
            <input
              className="btn-primary box-shadow-1dp"
              type="submit"
              id="subject"
              value="Submit"
            />
          </div>
        </form>
      </div>
    </>
  );
}
