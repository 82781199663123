import React, { useEffect, useState } from 'react';
import PageHead from '../components/nonHomeHead';
import { Footer } from '../components/footer';
import { useMatch } from 'react-location';
import Carousel from '../components/projectCarousel';
import Markdown from 'markdown-to-jsx';
import { useGsapFrom } from '../helpers/gsapTransition';

// Projects Page

function Project() {
  const { data: projObj } = useMatch();
  const {
    title,
    tags,
    description,
    dateCreated,
    demoLink,
    sourceCodeLink,
    screenshots,
  } = projObj;

  const { projID } = useMatch().params;

  // scroll to top when page loads
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Request markdown file for output to projPage__aside:
  const [output] = useMarkdown(description);
  const dateCreatedObj = new Date(`${dateCreated} 00:00:00`);

  // fade-in elements
  const [fadeInRef] = useGsapFrom({
    autoAlpha: 0,
    y: 40,
    ease: 'power2.inOut',
    stagger: 0.1,
    duration: 0.75,
    delay: 0.25,
    clearProps: 'y, autoAlpha',
  });

  return (
    <>
      <PageHead />

      <div className="pageContent" style={{ marginTop: '0' }}>
        <div className="pageContent__row r5" style={{ marginTop: '7vh' }}>
          <div className="contentHead" ref={fadeInRef}>
            <h1>
              {title}
              <span className="headAccent">_</span>
            </h1>

            <ul className="projPage__headSec__tagList">
              {tags.map((tag) => (
                <li key={`li_${projID}_${tag}`}>{tag}</li>
              ))}
            </ul>
          </div>

          <div className="projPage__body" ref={fadeInRef}>
            {
              // only show carousel if screenshots exist,
              // or if user is not using a small mobile device (handled by media query)
              typeof screenshots !== 'undefined' && (
                <div className="projPage__carouselCont">
                  <Carousel {...{ screenshots }} />
                </div>
              )
            }

            <p
              className={'projPage__Date'}
              style={{ margin: '1rem 0', textIndent: '0' }}
            >
              <i>
                Created on{' '}
                {dateCreatedObj.toLocaleDateString('en-us', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </i>
            </p>

            {/* dummy div wraps markdown so that loading animation works */}
            <div>
              <Markdown>{output}</Markdown>
            </div>

            <ProjPageBtns
              {...{ demoLink, sourceCodeLink }}
              style={{ marginTop: '70px' }}
            />
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Project;

// custom hooks

const useMarkdown = (description) => {
  const [output, setOutput] = useState('');

  useEffect(() => {
    // request markdown file
    fetch(description)
      .then((res) => res.text())
      .then((res) => setOutput(res))
      .catch((err) => setOutput(err));
  }, [description]);

  return [output, setOutput];
};

const ProjPageBtns = ({ demoLink, sourceCodeLink, style }) => {
  return (
    <>
      {
        // only show if at least one button exists
        !(
          typeof demoLink === 'undefined' &&
          typeof sourceCodeLink === 'undefined'
        ) && (
          <div className="projPage__btns" style={style}>
            {
              // only show if live demo link exists
              typeof demoLink !== 'undefined' && (
                <a
                  className="projPage__btns__demoBtn btn-primary box-shadow-1dp box-shadow-4dp"
                  href={demoLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  See live site
                </a>
              )
            }

            {
              // only show link button if source code link exists
              typeof sourceCodeLink !== 'undefined' && (
                <a
                  className="projPage__btns__sourceBtn"
                  href={sourceCodeLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg className="svg-shadow-1dp" viewBox="0 30 448 448">
                    <path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM277.3 415.7c-8.4 1.5-11.5-3.7-11.5-8 0-5.4.2-33 .2-55.3 0-15.6-5.2-25.5-11.3-30.7 37-4.1 76-9.2 76-73.1 0-18.2-6.5-27.3-17.1-39 1.7-4.3 7.4-22-1.7-45-13.9-4.3-45.7 17.9-45.7 17.9-13.2-3.7-27.5-5.6-41.6-5.6-14.1 0-28.4 1.9-41.6 5.6 0 0-31.8-22.2-45.7-17.9-9.1 22.9-3.5 40.6-1.7 45-10.6 11.7-15.6 20.8-15.6 39 0 63.6 37.3 69 74.3 73.1-4.8 4.3-9.1 11.7-10.6 22.3-9.5 4.3-33.8 11.7-48.3-13.9-9.1-15.8-25.5-17.1-25.5-17.1-16.2-.2-1.1 10.2-1.1 10.2 10.8 5 18.4 24.2 18.4 24.2 9.7 29.7 56.1 19.7 56.1 19.7 0 13.9.2 36.5.2 40.6 0 4.3-3 9.5-11.5 8-66-22.1-112.2-84.9-112.2-158.3 0-91.8 70.2-161.5 162-161.5S388 165.6 388 257.4c.1 73.4-44.7 136.3-110.7 158.3zm-98.1-61.1c-1.9.4-3.7-.4-3.9-1.7-.2-1.5 1.1-2.8 3-3.2 1.9-.2 3.7.6 3.9 1.9.3 1.3-1 2.6-3 3zm-9.5-.9c0 1.3-1.5 2.4-3.5 2.4-2.2.2-3.7-.9-3.7-2.4 0-1.3 1.5-2.4 3.5-2.4 1.9-.2 3.7.9 3.7 2.4zm-13.7-1.1c-.4 1.3-2.4 1.9-4.1 1.3-1.9-.4-3.2-1.9-2.8-3.2.4-1.3 2.4-1.9 4.1-1.5 2 .6 3.3 2.1 2.8 3.4zm-12.3-5.4c-.9 1.1-2.8.9-4.3-.6-1.5-1.3-1.9-3.2-.9-4.1.9-1.1 2.8-.9 4.3.6 1.3 1.3 1.8 3.3.9 4.1zm-9.1-9.1c-.9.6-2.6 0-3.7-1.5s-1.1-3.2 0-3.9c1.1-.9 2.8-.2 3.7 1.3 1.1 1.5 1.1 3.3 0 4.1zm-6.5-9.7c-.9.9-2.4.4-3.5-.6-1.1-1.3-1.3-2.8-.4-3.5.9-.9 2.4-.4 3.5.6 1.1 1.3 1.3 2.8.4 3.5zm-6.7-7.4c-.4.9-1.7 1.1-2.8.4-1.3-.6-1.9-1.7-1.5-2.6.4-.6 1.5-.9 2.8-.4 1.3.7 1.9 1.8 1.5 2.6z" />
                  </svg>
                </a>
              )
            }
          </div>
        )
      }
    </>
  );
};
