import emailjs from '@emailjs/browser';

export const sendEmail = (formData) => {
  // Try to Send Email
  return emailjs.send(
    'azthechamp_gmail_qlsga3w',
    'portfolio-site_1dcna1q',
    formData,
    'QqwqKSsE844ecj0JM'
  );
};
