import { ProjectCardsList } from './project-cards-list';
import { FeaturedProjectSection } from './project-featured';
import { ProjectsSectionBackground } from './project-section-background';

function ProjectsSection({ animationFinished }) {
  return (
    <>
      <ProjectsSectionBackground />
      <FeaturedProjectSection {...{ animationFinished }} />
      <ProjectCardsList />
    </>
  );
}

export default ProjectsSection;
