import React from 'react';
import { BackgroundSkills, ForegroundSkills } from './components';
import { useScrollAnimation } from './hooks/useScrollAnimation';

export function SkillsSection() {
  const { bgSkillsRef, fgSkillsRef } = useScrollAnimation();
  return (
    <>
      <BackgroundSkills ref={bgSkillsRef} />
      <ForegroundSkills ref={fgSkillsRef} />
    </>
  );
}
